import React from "react";
import { connect } from "react-redux";
import { Popup, Button, Input, Datepicker, Select } from "@mobiscroll/react";

import UserList from "../UserList/UserList";

const responsivePopup = {
  medium: {
    display: "anchored",
    width: 400,
    fullScreen: false,
    touchUi: false,
  },
};

const selectResponsive = {
  xsmall: {
    touchUi: true,
  },
  small: {
    touchUi: false,
  },
};

const repeatData = [
  {
    value: "norepeat",
    text: "No Repeat",
  },
  {
    value: "daily",
    text: "Daily",
  },
  {
    value: "weekly",
    text: "Weekly",
  },
  {
    value: "monthly",
    text: "Monthly",
  },
];

const respSetting = {
  medium: {
    controls: ["calendar", "time"],
    touchUi: false,
  },
};

// datepicker options
const controls = ["datetime"];

const NewEventPopup = ({
  anchor,
  isOpen,
  onClose,
  isEdit,
  popupEventTitle,
  titleChange,
  popupEventDescription,
  descriptionChange,
  popupEventDate,
  dateChange,
  user,
  startRef,
  endRef,
  start,
  end,
  boardIdChange,
  selectBoardId,
  selectedRepeat,
  repeatChange,
  boards,
  saveEvent,
  onDeleteClick,
  eventUsers,
  boardUsers,
  selectUserChange,
  isloading,
  isDeleting,
}) => {
  const boardsData = boards?.map((boardItem) => ({
    text: boardItem.title,
    value: boardItem.id,
  }));

  // popup options
  const headerText = React.useMemo(
    () => (isEdit ? "Edit event" : "New Event"),
    [isEdit]
  );

  return (
    <Popup
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      headerText={headerText}
      anchor={anchor}
      isOpen={isOpen}
      onClose={onClose}
      responsive={responsivePopup}
      theme="material"
      height={500}
    >
      <input
        className="title-input"
        placeholder="title"
        value={popupEventTitle}
        onChange={titleChange}
      />
      <textarea
        className="description-input"
        placeholder="description"
        value={popupEventDescription}
        onChange={descriptionChange}
      />
      <div className="date-picker-container">
        <Input ref={startRef} label="Start" />
        <Input ref={endRef} label="End" />
        <Datepicker
          select="range"
          controls={controls}
          touchUi={true}
          startInput={start}
          endInput={end}
          showRangeLabels={false}
          responsive={respSetting}
          onChange={dateChange}
          value={popupEventDate}
          className="border-remover"
        />
      </div>

      <Select
        data={boardsData}
        label="Select Project"
        value={selectBoardId}
        responsive={selectResponsive}
        onChange={boardIdChange}
        disabled={isEdit && true}
      />

      <Select
        data={repeatData}
        label="Repeats"
        value={selectedRepeat}
        responsive={selectResponsive}
        onChange={repeatChange}
      />

      {selectBoardId && (
        <UserList
          userList={boardUsers}
          selectUsers={eventUsers}
          selectUserChange={selectUserChange}
          isEdit={isEdit}
          user={user}
        />
      )}
      <div className="mbsc-button-group">
        {!isEdit && (
          <Button
            className="kevvlar-button-style"
            variant="outline"
            onClick={saveEvent}
          >
            {isloading ? "Saving" : "Save"}
          </Button>
        )}

        {isEdit && (
          <Button
            className="kevvlar-button-style"
            variant="outline"
            onClick={saveEvent}
          >
            {isloading ? "Updating" : "Update"}
          </Button>
        )}
        {isEdit && (
          <Button
            className="kevvlar-button-style-danger"
            color="danger"
            variant="outline"
            onClick={onDeleteClick}
          >
            {isDeleting ? "Deleting" : "Delete"}
          </Button>
        )}
      </div>
    </Popup>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    boards: state.board.boards,
    isloading: state.calendar.loading,
    isDeleting: state.calendar.isDeleting,
  };
};

export default connect(mapStateToProps, null)(NewEventPopup);
