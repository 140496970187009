export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";

export const FETCH_TEAM_REQUEST = "FETCH_TEAM_REQUEST";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_TEAM_FAILURE = "FETCH_TEAMS_FAILURE";

export const ADD_NEW_TEAM_SERVER_REQUEST = "ADD_NEW_TEAM_SERVER_REQUEST";
export const ADD_NEW_TEAM_SERVER_SUCCESS = "ADD_NEW_TEAM_SERVER_SUCCESS";
export const ADD_NEW_TEAM_SERVER_FAILURE = "ADD_NEW_TEAM_SERVER_FAILURE";

export const EDIT_TEAM_SERVER_REQUEST = "EDIT_TEAM_SERVER_REQUEST";
export const EDIT_TEAM_SERVER_SUCCESS = "EDIT_TEAM_SERVER_SUCCESS";
export const EDIT_TEAM_SERVER_FAILURE = "EDIT_TEAM_SERVER_FAILURE";

export const DELETE_TEAM_SERVER_REQUEST = "DELETE_TEAM_SERVER_REQUEST";
export const DELETE_TEAM_SERVER_SUCCESS = "DELETE_TEAM_SERVER_SUCCESS";
export const DELETE_TEAM_SERVER_FAILURE = "DELETE_TEAM_SERVER_FAILURE";

export const INVITE_MEMBER_TO_TEAM_REQUEST = "INVITE_MEMBER_TO_TEAM_REQUEST";
export const INVITE_MEMBER_TO_TEAM_SUCCESS = "INVITE_MEMBER_TO_TEAM_SUCCESS";
export const INVITE_MEMBER_TO_TEAM_FAILURE = "ADD_MEMBER_TO_TEAM_FAILURE";

export const ACCEPT_TEAM_INVITE_REQUEST = "ACCEPT_TEAM_INVITE_REQUEST";
export const ACCEPT_TEAM_INVITE_SUCCESS = "ACCEPT_TEAM_INVITE_SUCCESS";
export const ACCEPT_TEAM_INVITE_FAILURE = "ACCEPT_TEAM_INVITE_FAILURE";

export const DECLINE_TEAM_INVITE_REQUEST = "DECLINE_TEAM_INVITE_REQUEST";
export const DECLINE_TEAM_INVITE_SUCCESS = "DECLINE_TEAM_INVITE_SUCCESS";
export const DECLINE_TEAM_INVITE_FAILURE = "DECLINE_TEAM_INVITE_FAILURE";

export const REMOVE_MEMBER_FROM_TEAM_REQUEST =
  "REMOVE_MEMBER_FROM_TEAM_REQUEST";
export const REMOVE_MEMBER_FROM_TEAM_SUCCESS =
  "REMOVE_MEMBER_FROM_TEAM_SUCCESS";
export const REMOVE_MEMBER_FROM_TEAM_FAILURE =
  "REMOVE_MEMBER_FROM_TEAM_FAILURE";

export const SET_SELECT_TEAM = "SET_SELECT_TEAM";

export const CLEAR_TEAMS = "CLEAR_TEAMS";
