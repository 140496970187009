import React from 'react';

import ChatPage from '../../../pages/ChatPage/ChatPage';

import './chatModal.css';

const ChatModal = ({ client }) => {
  return (
    <div className="chat-page-modal">
      {/* <div className="chat-modal-overlay"></div> */}
      <ChatPage client={client} />
    </div>
  );
};

export default ChatModal;
