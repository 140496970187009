import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StreamChat } from 'stream-chat';

import './profileImage.css';

import {
  handleLogOutUser,
  handleSudoSignOut,
  setUserProfileModal,
  setPricingModal,
} from '../../redux/index.js';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY);

const UserProfile = ({
  isSudo,
  user,
  adminUser,
  logUserOut,
  sudoLogUserOut,
  history,
  showUserProfile,
  showPricingModal,
  showCancelSubscriptionModal,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleUserMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  const handleSignOut = () => {
    if (isSudo) {
      sudoLogUserOut(adminUser, history);
      handleUserMenu();
    } else {
      logUserOut(history);
      handleUserMenu();
    }
  };

  const UserMenu = () => (
    <div>
      <div className="close-wrapper" onClick={handleUserMenu}></div>
      <div className="user-profile-menu">
        <img className="user-profile-menu-image" alt="img" src={user.photo} />
        <div className="user-profile-menu-name">
          {user.firstName} {user.lastName}
        </div>
        <div className="user-profile-email">{user.email}</div>
        <div
          onClick={() => {
            handleUserMenu();
            showUserProfile();
          }}
          className="user-profile-logout"
        >
          <div className="user-profile-logout-text">My Profile</div>
        </div>
        <div
          onClick={() => {
            handleUserMenu();
            showPricingModal();
          }}
          className="user-profile-logout"
        >
          <div className="user-profile-logout-text">Subscription</div>
        </div>
        <div className="user-profile-logout-email">
          <a href='mailto:info@kevvlar.com' className="user-profile-logout-text email-support">Contact Support</a>
        </div>
        <div
          onClick={() => {
            handleSignOut();
            if (client) {
              client.disconnectUser();
            }
          }}
          className="user-profile-logout"
        >
          <div className="user-profile-logout-text">
            {isSudo ? 'Sudo Logout' : 'Log out'}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="user-profile-container">
      <img
        className="user-profile-image"
        alt="img"
        src={user.photo}
        onClick={handleUserMenu}
      />
      <div className="user-profile-name" onClick={handleUserMenu}>
        {user.firstName} {user.lastName}
      </div>
      {user.plan?.title === 'Basic' && (
        <div onClick={showPricingModal} className="trial-account-title">
          Trial Account
        </div>
      )}
      {showMenu ? <UserMenu /> : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    isSudo: state._user.isSudo,
    adminUser: state._user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logUserOut: (history) => dispatch(handleLogOutUser(history)),
    showUserProfile: () => dispatch(setUserProfileModal()),
    showPricingModal: () => dispatch(setPricingModal()),
    sudoLogUserOut: (adminData, history) =>
      dispatch(handleSudoSignOut(adminData, history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withRouter(UserProfile));
