import React, { useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import moment from 'moment';
// import ScrollContainer from "react-indiana-drag-scroll";

import Column from '../column/Column';

import socket from '../../Socket';

import './columnHolder.css';

import {
  setColumnModal,
  changeColumnsOrderLocal,
  addInUseCard,
  removeInUseCard,
  refreshColumnsLocal,
  fetchBoard,
  toggleSocketModal,
} from '../../redux/index';

const ColumnHolder = ({
  columns,
  columnsOrder,
  board,
  user,
  addNewColumnModal,
  refreshColumns,
  setInUseCard,
  unsetInUseCard,
  cardsInUse,
  updateColumnsOrderLocal,
  selectCardTitle,
  showSocketModal,
}) => {
  useEffect(() => {
    socket.on('drag-began', (data) => {
      setInUseCard(data);
    });

    socket.on('drag-end', (data) => {
      unsetInUseCard(data);
    });

    return () => {
      socket.off('drag-began');
      socket.off('drag-end');
    };
  }, [setInUseCard, unsetInUseCard]);

  const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    if (array.length <= 0) {
      return [];
    } else {
      return array;
    }
  };

  const onDragStart = (result) => {
    const { draggableId } = result;
    socket.emit('drag-started', { id: draggableId, socketId: socket.id });
  };

  const onDragEnd = (result) => {
    if (!socket || !socket.connected) {
      showSocketModal(true);
      return;
    }

    const { destination, draggableId, source, type } = result;
    socket.emit('drag-ended', { id: draggableId, socketId: socket.id });
    if (
      !result.destination ||
      cardsInUse.some((dragInfo) => dragInfo.id === draggableId)
    )
      return;

    // move column
    if (type === 'column' && destination.index !== source.index) {
      // get columns order
      let columnsOrderCopy = JSON.parse(JSON.stringify(columnsOrder));

      // remove currently dragged column and place it at new index
      let parsedColumnsOrder = JSON.parse(
        JSON.stringify(columnsOrderCopy.filter((id) => id !== draggableId))
      );

      // add currently dragged column to columns order at new index
      parsedColumnsOrder.splice(destination.index, 0, draggableId);

      const dragData = {
        boardId: board.id,
        columnId: draggableId,
        newIndex: destination.index,
      };

      socket.emit('change-columns-order', dragData);
      updateColumnsOrderLocal(parsedColumnsOrder);
    }

    // move card within column
    if (
      source.droppableId === destination.droppableId &&
      source.index !== destination.index &&
      type === 'card'
    ) {
      let columnsCopy = JSON.parse(JSON.stringify(columns));

      // 1) get destination column
      let destinationColumn = JSON.parse(
        JSON.stringify(
          columnsCopy.find((column) => column.id === destination.droppableId)
        )
      );

      // 2) get column cards order
      let destinationCardsOrder = JSON.parse(
        JSON.stringify(destinationColumn.cardsOrder)
      );

      // 3) remove card id from current cards order index
      const cardId = destinationCardsOrder.splice(source.index, 1)[0];

      // 4) insert card id into new index of cards order
      destinationCardsOrder.splice(destination.index, 0, cardId);

      // 5) set destination column cards order
      destinationColumn.cardsOrder = destinationCardsOrder;

      // 6) add new destionation column back into columns copy
      columnsCopy.forEach((column, index) => {
        if (column.id === destination.droppableId) {
          columnsCopy[index] = destinationColumn;
        }
      });

      const dragData = {
        newOrder: destinationCardsOrder,
        columnId: destination.droppableId,
        cardId: draggableId,
        cardTitle: selectCardTitle,
        columnTitle: destinationColumn.title,
        boardId: board.id,
        userId: user._id,
        newIndex: destination.index,
        time: moment(),
      };

      // 7) update redux
      socket.emit('change-cards-order', dragData);
      refreshColumns(columnsCopy);
    }

    // move card into another column
    if (source.droppableId !== destination.droppableId && type === 'card') {
      if (draggableId) {
        let columnsCopy = JSON.parse(JSON.stringify(columns));

        // 1) Find and  Change card column id
        let cardCopy;

        columnsCopy.forEach((column) => {
          column.cards.forEach((card) => {
            if (card.id === draggableId) {
              cardCopy = JSON.parse(JSON.stringify(card));
            }
          });
        });

        cardCopy.columnId = destination.droppableId;

        // 2) find and update source column removing card id from cards order and cards array.
        let sourceColumn = JSON.parse(
          JSON.stringify(
            columnsCopy.find((column) => column.id === source.droppableId)
          )
        );

        sourceColumn = {
          ...sourceColumn,
          cardsOrder: sourceColumn.cardsOrder.filter(
            (id) => id !== draggableId
          ),
          cards: sourceColumn.cards.filter((card) => card.id !== draggableId),
        };

        // 3) update copy columnss
        columnsCopy.forEach((column, index) => {
          if (column.id === source.droppableId) {
            columnsCopy[index] = sourceColumn;
          }
        });

        // 4) find and update destination column by adding card id to cards order
        let destinationColumn = JSON.parse(
          JSON.stringify(
            columnsCopy.find((column) => column.id === destination.droppableId)
          )
        );

        destinationColumn.cardsOrder.splice(destination.index, 0, cardCopy.id);
        destinationColumn.cards.push(cardCopy);

        // 5) update copy columns
        columnsCopy.forEach((column, index) => {
          if (column.id === destination.droppableId) {
            columnsCopy[index] = destinationColumn;
          }
        });

        const dragData = {
          sourceCol: source.droppableId,
          destCol: destination.droppableId,
          destIndex: destination.index,
          cardId: draggableId,
          sourceColArr: sourceColumn.cardsOrder,
          destColArr: destinationColumn.cardsOrder,
          cardTitle: cardCopy.title,
          columnTitle: destinationColumn.title,
          boardId: board.id,
          userId: user._id,
          time: moment(),
        };

        // 6) update redux
        socket.emit('change-card-column', dragData);
        refreshColumns(columnsCopy);
      }
    }
  };

  return (
    <>
      {columnsOrder && (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                className="column-holder"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {mapOrder(columns, columnsOrder, 'id')?.map((column, index) => (
                  <Column key={column.id} column={column} index={index} />
                ))}
                {provided.placeholder}

                <button
                  onClick={() => {
                    if (!socket || !socket.connected) {
                      showSocketModal();
                      return;
                    }
                    addNewColumnModal();
                  }}
                  className="new-column-button"
                >
                  + Add New Column
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    columnsOrder: state.board.selectBoard?.columnsOrder,
    columns: state.column.columns,
    columnsState: state.column.loading,
    board: state.board.selectBoard,
    columnId: state.column.selectColumn.id,
    user: state.user.userData,
    isMe: state.column.isMe,
    searchKeyWord: state.column.cardSearchKeyWord,
    cardsInUse: state.column.cardsInUse,
    selectCardTitle: state.column.selectCard.title,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewColumnModal: () => dispatch(setColumnModal()),
    updateColumnsOrderLocal: (newOrder) =>
      dispatch(changeColumnsOrderLocal(newOrder)),
    setInUseCard: (cardData) => dispatch(addInUseCard(cardData)),
    unsetInUseCard: (cardData) => dispatch(removeInUseCard(cardData)),
    refreshColumns: (columns) => dispatch(refreshColumnsLocal(columns)),
    getBoard: (token, boardId, params, isMeeting, routing) =>
      dispatch(fetchBoard(token, boardId, params, isMeeting, routing)),
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnHolder);
