import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  FullscreenIcon,
  Minimize,
  LeftArrowIcon,
  RightArrowIcon,
  CloseIcon,
} from '../../assets/svg/iconlibrary';

import {
  closeConferenceModal,
  toggleMinimize,
  createTimeout,
} from '../../redux';

import './conferencePage.css';

const ConferencePage = ({
  boardId,
  user,
  boardName,
  closeConferenceModal,
  minimize,
  miniMizeState,
  setAnchor,
  anchor,
  owner,
  timeBoardOut,
}) => {
  const [isStart, setIsStart] = useState(false);

  const handleSetAnchor = () => {
    if (anchor === 'left') {
      setAnchor('right');
    } else if (anchor === 'right') {
      setAnchor('left');
    }
  };

  const handleMessage = (e) => {
    if (e.origin === 'https://video-app-5904-3108-dev.twil.io') {
      switch (e.data) {
        case 'START':
          if (owner.plan.title === 'Basic') {
            timeBoardOut(user.token, boardId);
          }
          setIsStart(true);
          break;

        case 'END':
          setIsStart(false);
          if (owner.plan.title === 'Basic') {
            closeConferenceModal();
          }
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    // removes previous event listener
    window.removeEventListener('message', handleMessage);
    // adds new event listener
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={`conference-page`} style={{ width: '90%', height: '90%' }}>
      <div className="conference-buttons">
        <button
          className="chat-minimize-btn"
          onClick={() => {
            minimize();
          }}
        >
          {miniMizeState ? <FullscreenIcon /> : <Minimize />}
        </button>
        {miniMizeState && (
          <button className="chat-minimize-btn" onClick={handleSetAnchor}>
            {anchor === 'left' ? <RightArrowIcon /> : <LeftArrowIcon />}
          </button>
        )}
        {!isStart && (
          <button
            className="chat-minimize-btn"
            onClick={() => {
              closeConferenceModal();
            }}
          >
            <CloseIcon />
          </button>
        )}
      </div>

      <iframe
        id="meeting-iframe"
        src="https://video-app-3604-4511-dev.twil.io?passcode=74614136044511"
        title="Kevvlar Video Conference"
        allow="camera;microphone;display-capture;"
        width="100%"
        height="100%"
        className="meeting-iframe"
        name={`${boardId}+${user.firstName}+${boardName}+${
          user.email
        }+${JSON.stringify({
          userId: user._id,
          userPlan: user.plan.title,
          ownerId: owner?._id,
          ownerPlan: owner?.plan?.title,
        })}`}
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boardId: state.board.selectBoard.id,
    boardName: state.board.selectBoard.title,
    user: state.user.userData,
    owner: state.board.selectBoard.owner,
    miniMizeState: state.modal.minimize,
    boardOwnerId: state.board.selectBoard?.owner,
    modalType: state.modal.conference,
    boardMembers: state.board.selectBoard?.members,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeConferenceModal: () => dispatch(closeConferenceModal()),
    minimize: () => dispatch(toggleMinimize()),
    timeBoardOut: (token, boardId) => dispatch(createTimeout(token, boardId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferencePage);
