import {
  TOGGLE_ACTIVITY,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  CLEAR_ACTIVITIES,
} from "./activityTypes";
import axios from "axios";
import SERVER_URL from "../URL";

export const toggleActivity = () => {
  return {
    type: TOGGLE_ACTIVITY,
  };
};

export const fetchActivitiesRequest = () => {
  return {
    type: FETCH_ACTIVITIES_REQUEST,
  };
};

export const fetchActivitiesSuccess = (activities) => {
  return {
    type: FETCH_ACTIVITIES_SUCCESS,
    payLoad: activities,
  };
};

export const fetchActivitiesFailure = (error) => {
  return {
    type: FETCH_ACTIVITIES_FAILURE,
    payLoad: error,
  };
};

export const clearActivities = () => {
  return {
    type: CLEAR_ACTIVITIES,
  };
};

export const fetchActivities = (token, params) => {
  return (dispatch) => {
    dispatch(fetchActivitiesRequest());
    axios
      .get(`${SERVER_URL}/api/v1/activities`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const activities = response.data.data.activities;
        dispatch(fetchActivitiesSuccess(activities));
      })
      .catch((error) => {
        dispatch(fetchActivitiesFailure(error.message));
      });
  };
};

export const createActivity = (token, params, data) => {
  return () => {
    axios
      .post(`${SERVER_URL}/api/v1/activities`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
