import React, { useState } from "react";
import { connect } from "react-redux";

import { FaSearch } from "react-icons/fa";
import { handleSearchUsers } from "../../redux";

const SearchFilter = ({
  searchForUsers,
  user,
  setIsSearching,
  keyWord,
  setKeyWord,
}) => {
  const [field, setField] = useState("firstName");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSearching(true);
    if (field === "createdAt") {
      searchForUsers(user.token, field, null, startAt, endAt);
    } else {
      searchForUsers(user.token, field, keyWord, null, null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="filter-nav">
      {field !== "createdAt" && (
        <div className="admin-search-bar-container">
          <FaSearch className="cardsearch-icon" />
          <input
            type="text"
            placeholder="Search"
            className="cards-search"
            value={keyWord}
            onChange={(e) => setKeyWord(e.target.value)}
            required
          />
        </div>
      )}
      <select
        name="data"
        id="admin-users-options"
        onChange={(e) => setField(e.target.value)}
      >
        <option value="firstName">First Name</option>
        <option value="lastName">Last Name</option>
        <option value="email">Email</option>
        <option value="isVerified">Verified</option>
        <option value="createdAt">Account Created At</option>
        <option value="status">Account Status</option>
        {/* <option value="websiteEntry">Website Entry</option>
        <option value="plan">Plan</option> */}
      </select>
      {field === "createdAt" && (
        <div>
          <label className="admin-page-date-query-label">Start At:</label>
          <input
            type="date"
            value={startAt}
            placeholder="start at"
            onChange={(e) => setStartAt(e.target.value)}
            className="admin-page-date-query-input"
            required
          />
          <label className="admin-page-date-query-label">End At:</label>
          <input
            type="date"
            value={endAt}
            placeholder="start at"
            onChange={(e) => setEndAt(e.target.value)}
            className="admin-page-date-query-input"
            required
          />
          <button className="admin-page-submit-button" type="submit">
            Search
          </button>
        </div>
      )}
    </form>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchForUsers: (token, field, keyWord, startAt, endAt) =>
      dispatch(handleSearchUsers(token, field, keyWord, startAt, endAt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
