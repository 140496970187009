import {
  TOGGLE_PIN_MODAL,
  ADD_PIN_REQUEST,
  ADD_PIN_SUCCESS,
  ADD_PIN_FAILURE,
  FETCH_PINS_REQUEST,
  FETCH_PINS_SUCCESS,
  FETCH_PINS_FAILURE,
  DELETE_PIN_REQUEST,
  DELETE_PIN_SUCCESS,
  DELETE_PIN_FAILURE,
  CLEAR_PINS,
} from "./pinTypes";

const initialState = {
  showPins: false,
  pins: [],
  loading: false,
  error: "",
};

const pinReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PIN_MODAL:
      return {
        ...state,
        showPins: !state.showPins,
      };

    case ADD_PIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case ADD_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ADD_PIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case FETCH_PINS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        pins: [],
      };

    case FETCH_PINS_SUCCESS:
      return {
        ...state,
        loading: false,
        pins: action.payLoad,
      };

    case FETCH_PINS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case DELETE_PIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case DELETE_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
        pins: state.pins.filter((pin) => pin._id !== action.payLoad),
      };

    case DELETE_PIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case CLEAR_PINS:
      return {
        ...state,
        pins: [],
        showPins: false,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
};

export default pinReducer;
