import {
  CLEAR_USERS,
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SHOW_LESS_USERS,
  GET_USERS_SUCCESS_SEARCH,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_ERROR,
} from './usersTypes';

const initialState = {
  loading: false,
  error: '',
  usersList: [],
  isUpating: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        error: '',
        isUpating: true,
      };

    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        error: '',
        isUpating: false,
        usersList: state.usersList.filter(
          (user) => user._id !== action.payLoad._id
        ),
      };

    case DEACTIVATE_USER_ERROR:
      return {
        ...state,
        error: action.payLoad,
        isUpating: false,
      };

    case GET_USERS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: action.payLoad,
      };

    case GET_USERS_SUCCESS_SEARCH:
      return {
        ...state,
        loading: false,
        usersList: action.payLoad,
      };

    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case SHOW_LESS_USERS:
      return {
        ...state,
        usersList: [],
      };

    case CLEAR_USERS:
      return {
        ...state,
        usersList: [],
      };

    default:
      return state;
  }
};

export default usersReducer;
