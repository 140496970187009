export const CLOSE_MODAL = 'CLOSE_MODAL';

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';

export const BOARD_MODAL = 'BOARD_MODAL';
export const TEAM_MODAL = 'TEAM_MODAL';
export const COLUMN_MODAL = 'COLUMN_MODAL';
export const CARD_MODAL = 'CARD';
export const USER_MODAL = 'ADD_USER_MODAL';
export const TEAM_USER_MODAL = 'TEAM_USER_MODAL';
export const CHAT_MODAL = 'CHAT_MODAL';
export const USER_PROFILE = 'USER_PROFILE';
export const PIN_MODAL = 'PIN_MODAL';
export const PRICING_MODAL = 'PRICING_MODAL';
export const SUBSCRIBE_PLAN_MODAL = 'SUBSCRIBE_PLAN_MODAL';
export const CANCEL_SUBSCRIPTION_MODAL = 'CANCEL_SUBSCRIPTION_MODAL';

export const TEAM_SETTINGS_MODAL = 'TEAM_SETTINGS_MODAL';
export const CLOSE_TEAM_SETTINGS_MODAL = 'CLOSE_TEAM_SETTINGS_MODAL';
export const CONFERENCE_MODAL = 'CONFERENCE_MODAL';
export const CLOSE_CONFERENCE_MODAL = 'CLOSE_CONFERENCE_MODAL';
export const TOGGLE_MINIMIZE = 'MINIMIZE_CONFERENCE_MODAL';
export const CALENDAR_MODAL = 'CALENDAR_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';
export const INFO_MODAL = 'INFO_MODAL';
export const NOTIFICATION_MODAL = 'NOTIFICATION_MODAL';
export const CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL';

export const USERBOARDS_MODAL = 'USERBOARDS_MODAL';
export const TOGGLE_SOCKET_MODAL = 'TOGGLE_SOCKET_MODAL';

export const TOGGLE_CARD_MODAL_SAVE_BUTTON = 'TOGGLE_CARD_MODAL_SAVE_BUTTON';
