import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, useChatContext } from 'stream-chat-react';
import ChannelUsers from './ChannelUsers';

import { InviteIcon } from '../../assets/InviteIcon';

const ListContainer = ({ children }) => {
  return (
    <div className="user-list__container">
      <div className="user-list__header">
        <p>Add User</p>
      </div>

      {children}
    </div>
  );
};

const UserItem = ({ user, setSelectedUsers }) => {
  const [selected, setSelected] = useState(false);

  const handleSelect = () => {
    if (selected) {
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((prevUser) => prevUser !== user.id)
      );
    } else {
      setSelectedUsers((prevUsers) => [...prevUsers, user.id]);
    }
    setSelected((prevSelected) => !prevSelected);
  };

  return (
    <div className="user-item__wrapper" onClick={handleSelect}>
      <div className="user-item__name-wrapper">
        <Avatar image={user.image} name={user.name} size={32} />
        <p className="user-item__name">{user.name}</p>
      </div>

      {selected ? <InviteIcon /> : <div className="user-item__invite-empty" />}
    </div>
  );
};

const UserList = ({ isCreating, setSelectedUsers, user }) => {
  const { client } = useChatContext();
  const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchName, setSearchName] = useState('');

  const handleSearch = (e) => {
    setSearchName(e.target.value);
    getUsers();
  };

  function hasCommonValue(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) {
        return true;
      }
    }
    return false;
  }

  const getUsers = async () => {
    try {
      const response = await client.queryUsers({
        id: { $ne: client.userID },
        name: { $autocomplete: searchName },
      });

      if (response.users.length) {
        const responseUsers = response.users.filter((userItem) => {
          const meBoards = client.user.boards || [];
          const userItemBoards = userItem.boards || [];

          if (
            userItem.email !== user.email &&
            hasCommonValue(meBoards, userItemBoards)
          ) {
            return true;
          } else {
            return false;
          }
        });

        setUsers(responseUsers);
      }
    } catch (error) {
      if (!error.response?.data?.message.includes('autocomplete')) {
        setError(true);
      }
    }
  };

  return (
    <ListContainer>
      <div className="user-list__header">
        <input
          type="text"
          placeholder="Enter user name"
          value={searchName}
          onChange={handleSearch}
          className="search-user-chat-input"
        />
      </div>
      {error && <div className="user-list__message">No user found</div>}
      {users?.map((user, i) => (
        <UserItem
          index={i}
          key={user.id}
          user={user}
          setSelectedUsers={setSelectedUsers}
        />
      ))}
      {isCreating === false && <ChannelUsers />}
    </ListContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

export default connect(mapStateToProps, null)(UserList);
