import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { toggleFileModal, uploadFile, deleteFile } from "../../redux";

import { DownloadIcon, DeleteIcon } from "../../assets/svg/iconlibrary";

import "./fileModal.css";

const FileModal = ({
  showFiles,
  createFile,
  user,
  board,
  loading,
  files,
  removeFile,
  deleting,
  teamId,
}) => {
  const [fileData, setFileData] = useState(null);
  const fileRef = useRef(null);

  const formData = new FormData();

  const isFound = board.admins.some((admin) =>
    admin._id === user._id ? true : false
  );

  const checkBasicPlanRestrictions = () => {
    if (user.plan.title === "Basic" && files.length >= 2) {
      return false;
    } else {
      return true;
    }
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const FileItem = ({ file }) => {
    return (
      <div className="file-item-container">
        <div className="file-user-name" title={file.name}>
          {file.name}
        </div>
        <div className="file-button-container">
          <button
            type="button"
            className="file-download-button"
            onClick={() => {
              axios
                .get(file.url, {
                  responseType: "blob",
                })
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");

                  link.href = url;

                  link.setAttribute("download", file.name);

                  document.body.appendChild(link);
                  link.click();
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            <DownloadIcon />
          </button>
          {isFound || user._id === file.owner._id ? (
            <button
              className="file-delete-button"
              onClick={() => {
                removeFile(
                  user.token,
                  { boardId: board.id, publicId: file.publicId, teamId },
                  file._id
                );
              }}
            >
              <DeleteIcon />
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  const getFileSize = () => {
    if (!fileData) return false;

    return fileData.size > 10000000 ? false : true;
  };

  const handleUploadFile = () => {
    if (getFileSize()) {
      document.getElementById("fileUpload").value = "";
      formData.append("file", fileData);
      formData.append("upload_preset", "ml_default");
      formData.append("folder", `kevvlar/files/board-${board.id}`);
      createFile(
        user.token,
        { boardId: board.id, teamId },
        formData,
        fileData?.name,
        setFileData
      );
    }
  };

  return (
    <div>
      <div
        className="file-close-wrapper"
        onClick={() => {
          showFiles();
        }}
      ></div>
      <div className="file-profile-menu">
        <div className="file-main-title">Files</div>
        <div className="file-item-wrapper">
          <div className="file-container">
            <div className="files-holder">
              {files.map((file) => (
                <FileItem key={file._id} file={file} />
              ))}
              {loading ? (
                <div
                  className="file-item-container"
                  style={{
                    backgroundColor: "#333d46",
                  }}
                >
                  <div className="file-user-name">
                    {fileData.name} in progress
                  </div>
                </div>
              ) : null}
            </div>
            {/* If you are a trial account and you have 2 files uploaded already, hide upload button */}
            {user?.plan?.title === "Basic" && files.length >= 5 ? null : (
              <div className="file-button-holder">
                <center className="file-input-holder">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    ref={fileRef}
                    id="fileUpload"
                    className="file-input"
                    onChange={(e) => {
                      setFileData(e.target.files[0]);
                    }}
                  />
                  {checkBasicPlanRestrictions() && (
                    <div className="new-file-text" onClick={handleClick}>
                      {fileData ? fileData.name : "New file"}
                    </div>
                  )}

                  {fileData && (
                    <button
                      onClick={handleUploadFile}
                      className="upload-file-button"
                    >
                      {loading && "Uploading"}
                      {!loading && getFileSize() && "Upload"}
                      {!getFileSize() && "File exceeds 10MB"}
                    </button>
                  )}
                </center>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    board: state.board.selectBoard,
    loading: state.file.loading,
    deleting: state.file.deleting,
    files: state.file.files,
    teamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showFiles: () => dispatch(toggleFileModal()),
    createFile: (token, params, fileData, fileName, setFileData) =>
      dispatch(uploadFile(token, params, fileData, fileName, setFileData)),
    removeFile: (token, params, fileId) =>
      dispatch(deleteFile(token, params, fileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileModal);
