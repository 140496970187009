let SERVER_URL;

if (process.env.NODE_ENV === 'production') {
  SERVER_URL = process.env.REACT_APP_HTTPS;
} else if (process.env.NODE_ENV === 'development') {
  SERVER_URL = process.env.REACT_APP_HTTPS;
}

export default SERVER_URL;

// REACT_APP_LOCAL

// REACT_APP_HTTPS
