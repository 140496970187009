import axios from 'axios';
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  SHOW_LESS_USERS,
  CLEAR_USERS,
  GET_USERS_SUCCESS_SEARCH,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_ERROR,
} from './usersTypes';

import SERVER_URL from '../../URL';
import { setInfoModal } from '../../index';

export const getUsersRequest = () => {
  return {
    type: GET_USERS_REQUEST,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    payLoad: users,
  };
};

export const searchUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS_SEARCH,
    payLoad: users,
  };
};

export const getUsersError = (error) => {
  return {
    type: GET_USERS_ERROR,
    payLoad: error,
  };
};

export const handleGetUsers = (token, page = 0, size = 5) => {
  return (dispatch) => {
    dispatch(getUsersRequest());
    axios
      .get(`${SERVER_URL}/api/v1/users`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          size: size,
        },
      })
      .then((response) => {
        const users = response.data.data.users;
        dispatch(getUsersSuccess(users));
      })
      .catch((error) => {
        dispatch(setInfoModal('Could not get users data'));
        dispatch(getUsersError(error.response.data.message));
      });
  };
};

export const handleSearchUsers = (
  token,
  field = null,
  keyWord = null,
  startAt = null,
  endAt = null
) => {
  return (dispatch) => {
    dispatch(getUsersRequest());
    axios
      .get(`${SERVER_URL}/api/v1/users/search-users`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          field,
          keyWord,
          startAt,
          endAt,
        },
      })
      .then((response) => {
        const users = response.data.data;
        dispatch(searchUsersSuccess(users));
      })
      .catch((error) => {
        dispatch(setInfoModal('Could not get users data'));
        dispatch(getUsersError(error.response.data.message));
      });
  };
};

export const getUsersPag = (page, size) => {};

export const handleShowLess = () => {
  return {
    type: SHOW_LESS_USERS,
  };
};

export const clearUsers = () => {
  return {
    type: CLEAR_USERS,
  };
};

export const deActiveUserRequest = () => {
  return {
    type: DEACTIVATE_USER_REQUEST,
  };
};

export const deActiveUserSuccess = (user) => {
  return {
    type: DEACTIVATE_USER_SUCCESS,
    payLoad: user,
  };
};

export const deActiveUserError = (error) => {
  return {
    type: DEACTIVATE_USER_ERROR,
    payLoad: error,
  };
};

export const handleActivateUserState = (token, userId, type) => {
  return (dispatch) => {
    dispatch(deActiveUserRequest());
    axios
      .patch(
        `${SERVER_URL}/api/v1/users/${
          type === 'active' ? 'activate' : 'deactivate'
        }`,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            id: userId,
          },
        }
      )
      .then((response) => {
        const user = response.data.data.user;
        dispatch(
          setInfoModal(
            `User ${
              type === 'active' ? 'activated' : 'deactivated'
            } successfully`
          )
        );
        dispatch(deActiveUserSuccess(user));
      })
      .catch((error) => {
        dispatch(
          setInfoModal(
            `Could not ${
              type === 'active' ? 'activate' : 'deactivated'
            } successfully`
          )
        );
        dispatch(deActiveUserError(error.message));
      });
  };
};
