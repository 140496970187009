import axios from 'axios';
import {
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  ADD_NEW_TEAM_SERVER_REQUEST,
  ADD_NEW_TEAM_SERVER_SUCCESS,
  ADD_NEW_TEAM_SERVER_FAILURE,
  EDIT_TEAM_SERVER_REQUEST,
  EDIT_TEAM_SERVER_SUCCESS,
  EDIT_TEAM_SERVER_FAILURE,
  DELETE_TEAM_SERVER_REQUEST,
  DELETE_TEAM_SERVER_SUCCESS,
  DELETE_TEAM_SERVER_FAILURE,
  SET_SELECT_TEAM,
  REMOVE_MEMBER_FROM_TEAM_REQUEST,
  REMOVE_MEMBER_FROM_TEAM_SUCCESS,
  REMOVE_MEMBER_FROM_TEAM_FAILURE,
  INVITE_MEMBER_TO_TEAM_REQUEST,
  INVITE_MEMBER_TO_TEAM_SUCCESS,
  INVITE_MEMBER_TO_TEAM_FAILURE,
  ACCEPT_TEAM_INVITE_REQUEST,
  ACCEPT_TEAM_INVITE_SUCCESS,
  ACCEPT_TEAM_INVITE_FAILURE,
  DECLINE_TEAM_INVITE_REQUEST,
  DECLINE_TEAM_INVITE_SUCCESS,
  DECLINE_TEAM_INVITE_FAILURE,
} from './teamTypes';

import SERVER_URL from '../URL';
import {
  clearNotifications,
  closeModal,
  closeTeamSettingsModal,
  fetchBoards,
  setErrorModal,
  setInfoModal,
  setTeamSettingsModal,
  toggleRightSideNav,
} from '../index';

export const fetchTeamsRequest = () => {
  return {
    type: FETCH_TEAMS_REQUEST,
  };
};

export const fetchTeamsSuccess = (teams) => {
  return {
    type: FETCH_TEAMS_SUCCESS,
    payLoad: teams,
  };
};

export const fetchTeamsFailure = (error) => {
  return {
    type: FETCH_TEAMS_FAILURE,
    payLoad: error,
  };
};

export const fetchTeams = (token) => {
  return (dispatch) => {
    dispatch(fetchTeamsRequest());
    axios
      .get(`${SERVER_URL}/api/v1/teams`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const teams = response.data.data.teams;
        dispatch(fetchTeamsSuccess(teams));
      })
      .catch((error) => {
        dispatch(fetchTeamsFailure(error.message));
      });
  };
};

export const addNewTeamRequest = () => {
  return {
    type: ADD_NEW_TEAM_SERVER_REQUEST,
  };
};

export const addNewTeamSuccess = (team) => {
  return {
    type: ADD_NEW_TEAM_SERVER_SUCCESS,
    payLoad: team,
  };
};

export const addNewTeamFailure = (error) => {
  return {
    type: ADD_NEW_TEAM_SERVER_FAILURE,
    payLoad: error,
  };
};

export const addNewTeam = (token, teamObj) => {
  return (dispatch) => {
    dispatch(addNewTeamRequest());
    axios
      .post(`${SERVER_URL}/api/v1/teams`, teamObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const team = response.data.data.team;
        dispatch(addNewTeamSuccess(team));
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(addNewTeamFailure(error.message));
        dispatch(setInfoModal('Could create new team'));
      });
  };
};

export const editTeamRequest = () => {
  return {
    type: EDIT_TEAM_SERVER_REQUEST,
  };
};

export const editTeamSuccess = (team) => {
  return {
    type: EDIT_TEAM_SERVER_SUCCESS,
    payLoad: team,
  };
};

export const editTeamFailure = (error) => {
  return {
    type: EDIT_TEAM_SERVER_FAILURE,
    payLoad: error,
  };
};

export const editTeam = (token, teamObj, params) => {
  return (dispatch) => {
    dispatch(editTeamRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/teams/${teamObj.id}`, teamObj, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const team = response.data.data.team;
        dispatch(editTeamSuccess(team));
        dispatch(setSelectTeam(team));
        dispatch(closeModal());
        dispatch(setTeamSettingsModal());
      })
      .catch((error) => {
        dispatch(setInfoModal('Could not update team info'));
        dispatch(editTeamFailure(error.message));
      });
  };
};

export const setSelectTeam = (team) => {
  return {
    type: SET_SELECT_TEAM,
    payLoad: team,
  };
};

export const deleteTeamRequest = () => {
  return {
    type: DELETE_TEAM_SERVER_REQUEST,
  };
};

export const deleteTeamSuccess = (id) => {
  return {
    type: DELETE_TEAM_SERVER_SUCCESS,
    payLoad: id,
  };
};

export const deleteTeamFailure = (error) => {
  return {
    type: DELETE_TEAM_SERVER_FAILURE,
    payLoad: error,
  };
};

export const deleteTeam = (token, teamObj, params) => {
  return (dispatch) => {
    dispatch(deleteTeamRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/teams/${teamObj.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const team = response.data.data.team;
        dispatch(deleteTeamSuccess(team._id));
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(setInfoModal('Could not delete team'));
        dispatch(deleteTeamFailure(error.message));
      });
  };
};

export const fetchTeamRequest = () => {
  return {
    type: FETCH_TEAM_REQUEST,
  };
};

export const fetchTeamSuccess = (team) => {
  return {
    type: FETCH_TEAM_SUCCESS,
    payLoad: team,
  };
};

export const fetchTeamFailure = (error) => {
  return {
    type: FETCH_TEAM_FAILURE,
    payLoad: error,
  };
};

export const fetchTeam = (token, teamId, params) => {
  return (dispatch) => {
    dispatch(fetchTeamRequest());
    axios
      .get(`${SERVER_URL}/api/v1/teams/${teamId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const team = response.data.data.team;
        dispatch(fetchTeamSuccess(team));
      })
      .catch((error) => {
        dispatch(fetchTeamFailure(error.message));
      });
  };
};

export const removeUserFromTeamRequest = () => {
  return {
    type: REMOVE_MEMBER_FROM_TEAM_REQUEST,
  };
};

export const removeUserFromTeamSuccess = () => {
  return {
    type: REMOVE_MEMBER_FROM_TEAM_SUCCESS,
  };
};

export const removeUserFromTeamFailure = (error) => {
  return {
    type: REMOVE_MEMBER_FROM_TEAM_FAILURE,
    payLoad: error,
  };
};

export const removeUserFromTeam = (token, userObj, params) => {
  return (dispatch) => {
    dispatch(removeUserFromTeamRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/teams/remove-member`, userObj, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        dispatch(removeUserFromTeamSuccess());
        dispatch(closeTeamSettingsModal());
        dispatch(setInfoModal('User removed successfully'));
      })
      .catch((error) => {
        dispatch(closeTeamSettingsModal());
        dispatch(setErrorModal('Could not remove user from team'));
        dispatch(removeUserFromTeamFailure(error.message));
      });
  };
};

export const inviteMemberToTeamRequest = () => {
  return {
    type: INVITE_MEMBER_TO_TEAM_REQUEST,
  };
};

export const inviteMemberToTeamSuccess = () => {
  return {
    type: INVITE_MEMBER_TO_TEAM_SUCCESS,
  };
};

export const inviteMemberToTeamFailure = (error) => {
  return {
    type: INVITE_MEMBER_TO_TEAM_FAILURE,
    payLoad: error,
  };
};

export const inviteMemberToTeam = (token, userObj, params) => {
  return (dispatch) => {
    dispatch(inviteMemberToTeamRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/teams/invite-member`, userObj, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        dispatch(inviteMemberToTeamSuccess());
        dispatch(setInfoModal('User invited successfully'));
      })
      .catch((error) => {
        dispatch(setErrorModal('Could not invite user to team'));
        dispatch(inviteMemberToTeamFailure(error.message));
      });
  };
};

export const acceptTeamInviteRequest = () => {
  return {
    type: ACCEPT_TEAM_INVITE_REQUEST,
  };
};

export const acceptTeamInviteSuccess = () => {
  return {
    type: ACCEPT_TEAM_INVITE_SUCCESS,
  };
};

export const acceptTeamInviteFailure = (error) => {
  return {
    type: ACCEPT_TEAM_INVITE_FAILURE,
    payLoad: error,
  };
};

export const acceptTeamInvitation = (token, answer, history) => {
  return (dispatch) => {
    dispatch(acceptTeamInviteRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/teams/accept-member-invite`, answer, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(acceptTeamInviteSuccess());
        dispatch(fetchBoards(token));
        dispatch(toggleRightSideNav());
        dispatch(clearNotifications());
        if (history.location.pathname.includes('/boards')) {
          dispatch(fetchBoards(token));
          dispatch(fetchTeams(token));
        } else {
          history.push('/boards');
        }
      })
      .catch((error) => {
        dispatch(acceptTeamInviteFailure(error.response?.data?.message));
        dispatch(setErrorModal(error?.response?.data?.message));
      });
  };
};

export const declineTeamInviteRequest = () => {
  return {
    type: DECLINE_TEAM_INVITE_REQUEST,
  };
};

export const declineTeamInviteSuccess = () => {
  return {
    type: DECLINE_TEAM_INVITE_SUCCESS,
  };
};

export const declineTeamInviteFailure = (error) => {
  return {
    type: DECLINE_TEAM_INVITE_FAILURE,
    payLoad: error,
  };
};

export const declineTeamInvitation = (token, answer) => {
  return (dispatch) => {
    dispatch(declineTeamInviteRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/teams/decline-member-invite`, answer, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(declineTeamInviteSuccess());
        dispatch(toggleRightSideNav());
        dispatch(clearNotifications());
      })
      .catch((error) => {
        if (error.response.data.message) {
          dispatch(declineTeamInviteFailure(error.response.data.message));
          dispatch(setErrorModal(error.response.data.message));
        }
      });
  };
};
