import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { AiFillCalendar } from 'react-icons/ai';
import { withRouter } from 'react-router-dom';
import audio from '../../../assets/sound/meeting-notification.wav';

import {
  closeNotificationModal,
  fetchEvents,
  clearColumns,
  fetchColumns,
  setConferenceModal,
  fetchBoard,
  setSelectTeam,
} from '../../../redux';

import socket from '../../../Socket';

import './notificationModal.css';

const notifySound = new Audio(audio);

const NotificationModal = ({
  user,
  currBoardId,
  closeNotification,
  isNotify,
  notificationData,
  location,
  history,
  startMeeting,
  emptyColumns,
  getBoard,
  getColumns,
}) => {
  useEffect(() => {
    if (notificationData.duration === 0) {
      notifySound.play();
    }
  }, [notificationData.duration]);

  const handleRedirectUser = () => {
    const { boardId } = notificationData;

    if (!currBoardId) {
      emptyColumns();
      closeNotification();
      history.push(`/board/${boardId}`);
      startMeeting();
    } else if (currBoardId === boardId) {
      closeNotification();
      startMeeting();
    } else if (currBoardId !== boardId) {
      socket.disconnect();
      emptyColumns();
      closeNotification();
      getBoard(user.token, boardId, { boardId }, true);
      getColumns(user.token, { boardId });
      history.push(`/board/${boardId}`);
      socket.connect();
      socket.emit('newUser', user._id);
      socket.emit('join-board', { boardId, user: user._id });
    }
  };

  return (
    <>
      {isNotify && (
        <div className="notification-modal-container">
          <div className="notification">
            <div
              className="notification-container"
              onClick={handleRedirectUser}
            >
              <div className="notification-icon-container">
                <div>
                  <AiFillCalendar className="notification-calendar-icon" />
                </div>
              </div>
              <div className="notification-body">
                <span className="notification-event-title">
                  {notificationData.title}
                </span>
                <br />
                <span className="notification-event-timing">
                  {notificationData.duration === 0
                    ? 'Starts now.'
                    : `Starts in ${notificationData.duration} minutes.`}
                </span>
              </div>
            </div>
            <div className="notification-close-container">
              <FaTimes
                onClick={() => {
                  closeNotification();
                }}
                className="notification-close-icon"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeNotification: () => dispatch(closeNotificationModal()),
    getEvents: (token) => dispatch(fetchEvents(token)),
    getBoard: (token, boardId, params, bool, history) =>
      dispatch(fetchBoard(token, boardId, params, bool, history)),
    emptyColumns: () => dispatch(clearColumns()),
    getColumns: (token, params) => dispatch(fetchColumns(token, params)),
    startMeeting: () => dispatch(setConferenceModal()),
    updateSelectTeam: (team) => dispatch(setSelectTeam(team)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    currBoardId: state.board.selectBoard?.id,
    isNotify: state.modal.notify,
    notificationData: state.modal.notificationEvent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationModal));
