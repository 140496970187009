import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { handleActivateUserState, handleSudoSignIn } from "../../redux";

const UserDataDropdown = ({
  userId,
  user,
  updateActiveState,
  sudoSignin,
  history,
  status,
}) => {
  const handleActiveStateUpdate = (type) => {
    if (user.token && userId) {
      updateActiveState(user.token, userId, type);
    }
  };

  const handleSudoSignin = () => {
    sudoSignin(user.token, user, userId, history);
  };

  const handleChange = (e) => {
    switch (e.target.value) {
      case "deactivate":
        handleActiveStateUpdate("deactive");
        break;
      case "activate":
        handleActiveStateUpdate("active");
        break;

      case "make-me":
        handleSudoSignin();
        break;

      default:
        return;
    }
  };

  return (
    <div>
      <select className="user-action-select" onChange={handleChange}>
        <option value="">Select action</option>
        {status === "active" ? (
          <>
            <option value="deactivate">Deactivate user</option>
            <option value="make-me">Make me</option>
          </>
        ) : (
          <option value="activate">Activate User</option>
        )}
      </select>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveState: (token, userId, type) =>
      dispatch(handleActivateUserState(token, userId, type)),
    sudoSignin: (token, adminData, userId, history) =>
      dispatch(handleSudoSignIn(token, adminData, userId, history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDataDropdown));
