import {
  ADD_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  CLEAR_EVENTS,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  ADD_NEW_EVENT_SERVER_REQUEST,
  ADD_NEW_EVENT_SERVER_SUCCESS,
  ADD_NEW_EVENT_SERVER_FAILURE,
  EDIT_EVENT_SERVER_REQUEST,
  EDIT_EVENT_SERVER_SUCCESS,
  EDIT_EVENT_SERVER_FAILURE,
  DELETE_EVENT_SERVER_REQUEST,
  DELETE_EVENT_SERVER_SUCCESS,
  DELETE_EVENT_SERVER_FAILURE,
  REFRESH_EVENT_LIST,
} from "./calendarTypes";

const initialState = {
  loading: false,
  error: "",
  eventList: [],
  isDeleting: false,
  isFetching: false,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
        eventList: [],
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        eventList: action.payLoad,
      };

    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payLoad,
      };

    case ADD_NEW_EVENT_SERVER_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_NEW_EVENT_SERVER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ADD_NEW_EVENT_SERVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case ADD_EVENT:
      return {
        ...state,
        eventList: [...state.eventList, action.payLoad],
      };

    case EDIT_EVENT_SERVER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case EDIT_EVENT_SERVER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case EDIT_EVENT_SERVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case EDIT_EVENT:
      return {
        ...state,
        eventList: state.eventList.map((event) =>
          event.id === action.payLoad.eventId
            ? {
                ...event,
                title: action.payLoad.event?.title,
                start: action.payLoad.event?.start,
                end: action.payLoad.event?.end,
                allDay: action.payLoad.event?.allDay,
                users: action.payLoad.event?.users,
                originUser: action.payLoad.event?.originUser,
                description: action.payLoad.event?.description,
                recurring: action.payLoad.event?.recurring,
              }
            : event
        ),
      };

    case DELETE_EVENT:
      return {
        ...state,
        eventList: state.eventList.filter((item) => item.id !== action.payLoad),
      };

    case DELETE_EVENT_SERVER_REQUEST:
      return {
        ...state,
        isDeleting: true,
        error: "",
      };

    case DELETE_EVENT_SERVER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };

    case DELETE_EVENT_SERVER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payLoad,
      };

    case CLEAR_EVENTS:
      return {
        ...state,
        loading: false,
        error: "",
        eventList: [],
        isDeleting: false,
        isFetching: false,
      };

    case REFRESH_EVENT_LIST:
      return {
        ...state,
        eventList: [...state.eventList],
      };

    default:
      return state;
  }
};

export default calendarReducer;
