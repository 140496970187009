import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';

import {
  closeModal,
  addNewTeam,
  editTeam,
  deleteTeam,
  setTeamSettingsModal,
} from '../../../redux';
import { ADD, EDIT, DELETE } from '../../../redux/modal/modalTypes';

import './teamModal.css';

const TeamModal = ({
  type,
  closeModal,
  user,
  createTeam,
  updateTeam,
  removeTeam,
  team,
  updating,
  showTeamSetting,
}) => {
  const [editTeamName, setEditTeamName] = useState(team.title);
  const [teamName, setTeamName] = useState('');

  const AddTeam = () => {
    const handleSubmit = () => {
      const teamObj = { title: teamName };

      createTeam(user.token, teamObj);
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Add New Team</h2>
        <input
          className="modal-board-text"
          type="text"
          name="teamName"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          placeholder="New Team"
          required
          autoFocus
        />
        <button
          className="modal-board-button"
          type="submit"
          disabled={updating}
        >
          {updating ? 'Saving' : ' Save'}
        </button>
      </form>
    );
  };

  const EditTeam = () => {
    const handleSubmit = () => {
      updateTeam(
        user.token,
        { title: editTeamName, id: team._id },
        { teamId: team._id }
      );
      setEditTeamName('');
    };
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Edit Team</h2>
        <input
          className="modal-board-text"
          type="text"
          name="editTeamName"
          value={editTeamName}
          onChange={(e) => setEditTeamName(e.target.value)}
          autoFocus
          required
        />
        <button
          className="modal-board-button"
          type="submit"
          disabled={updating}
        >
          {updating ? 'Saving' : 'save'}
        </button>
      </form>
    );
  };

  const DeleteTeam = () => {
    return (
      <div className="modal-body">
        <h2 className="modal-title">Are You Sure?</h2>
        <span className="modal-sub-text">
          All information contained in this team will be deleted
        </span>
        <div className="modal-button-container">
          <button
            className="delete-button"
            onClick={() => {
              removeTeam(user.token, { id: team._id }, { teamId: team._id });
            }}
          >
            Delete
          </button>
          <button
            className="modal-cancel-button"
            onClick={() => {
              closeModal();
              showTeamSetting();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-team">
      <div className="close-icon-container">
        <FaTimes
          onClick={() => {
            closeModal();
            if (type === EDIT || type === DELETE) {
              showTeamSetting();
            }
          }}
          className="close-icon"
        />
        {type === ADD ? <AddTeam /> : null}
        {type === EDIT ? <EditTeam /> : null}
        {type === DELETE ? <DeleteTeam /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    user: state.user.userData,
    team: state.team.selectTeam,
    updating: state.team.updating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    createTeam: (token, teamObj) => dispatch(addNewTeam(token, teamObj)),
    updateTeam: (token, teamObj, params) =>
      dispatch(editTeam(token, teamObj, params)),
    removeTeam: (token, teamObj, params) =>
      dispatch(deleteTeam(token, teamObj, params)),
    showTeamSetting: () => dispatch(setTeamSettingsModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamModal);
