import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import socket from '../../Socket';

import {
  setCardModal,
  setColumnModal,
  setCurrentColumnData,
  toggleSocketModal,
} from '../../redux';

import Card from '../card/Card';

import { DELETE, EDIT } from '../../redux/modal/modalTypes';

import { EditIcon, TrashIcon } from '../../assets/svg/iconlibrary';
import './column.css';

const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};

const Column = ({
  column,
  index,
  addNewCardModal,
  editColumnModal,
  deleteColumnModal,
  getColumnData,
  isMe,
  searchKeyWord,
  cardsInUse,
  showSocketModal,
}) => {
  const [addFinishedClass, setAddFinishedClass] = useState(false);
  const [isInUse, setIsInUse] = useState(false);

  useEffect(() => {
    if (cardsInUse.some((dragData) => dragData.id === column.id)) {
      setIsInUse(true);
    } else {
      setIsInUse(false);
    }
  }, [cardsInUse, column.id]);

  useEffect(() => {
    if (column.title.toLowerCase().includes('finished')) {
      setAddFinishedClass(true);
    } else {
      setAddFinishedClass(false);
    }
  }, [setAddFinishedClass, column]);

  // needs to fire on render and after editing/creating a column
  const checkFinished = () => {
    if (column.title.toLowerCase().includes('finished')) {
      setAddFinishedClass(true);
    } else {
      setAddFinishedClass(false);
    }
  };

  return (
    <Draggable
      draggableId={column?.id}
      index={index}
      isDragDisabled={isInUse && true}
    >
      {(provided) => (
        <div
          className={
            addFinishedClass
              ? 'column finished-column'
              : `column ${isInUse && 'in-use'} `
          }
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="column-header-container">
            <div className="column-handle" {...provided.dragHandleProps}>
              <p className="column-header-title" title={column.title}>
                {column?.title}
              </p>
            </div>
            {!isMe && !searchKeyWord && (
              <div className="column-header-icon-container">
                <TrashIcon
                  handleDelete={() => {
                    if (!socket || !socket.connected) {
                      showSocketModal(true);
                      return;
                    }
                    if (isInUse) return;
                    getColumnData(column);
                    deleteColumnModal();
                  }}
                  className="column-header-trash-icon"
                />
                <EditIcon
                  handleEdit={() => {
                    if (!socket || !socket.connected) {
                      showSocketModal(true);
                      return;
                    }
                    if (isInUse) return;
                    getColumnData(column);
                    editColumnModal();
                    checkFinished();
                  }}
                  className="column-header-edit-icon"
                />
              </div>
            )}
          </div>
          <Droppable droppableId={column?.id} type="card">
            {(provided) => (
              <div
                className="card-container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {mapOrder(column?.cards, column?.cardsOrder, 'id').map(
                  (card, index) => (
                    <Card
                      key={card.id}
                      card={card}
                      index={index}
                      column={column}
                    />
                  )
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {!isInUse && !isMe && !searchKeyWord && (
            <button
              onClick={() => {
                if (!socket || !socket.connected) {
                  showSocketModal(true);
                  return;
                }
                if (isInUse) return;
                getColumnData(column);
                addNewCardModal();
              }}
              className="new-card-button"
            >
              + Add New Card
            </button>
          )}
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => {
  return {
    isMe: state.column.isMe,
    searchKeyWord: state.column.cardSearchKeyWord,
    cardsInUse: state.column.cardsInUse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewCardModal: () => dispatch(setCardModal()),
    editColumnModal: () => dispatch(setColumnModal(EDIT)),
    deleteColumnModal: () => dispatch(setColumnModal(DELETE)),
    getColumnData: (columnObj) => dispatch(setCurrentColumnData(columnObj)),
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Column);
