import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';

import {
  closeModal,
  inviteMemberToTeam,
  setTeamSettingsModal,
} from '../../../redux';

import './userModal.css';

const TeamUserModal = ({
  closeModal,
  type,
  user,
  updating,
  inviteUser,
  teamId,
  showTeamSetting,
}) => {
  const [userEmail, setUserEmail] = useState('');

  const handleInviteUserToTeam = () => {
    inviteUser(
      user.token,
      {
        teamId,
        userEmail,
      },
      { teamId }
    );
  };

  const AddUserToTeamModal = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleInviteUserToTeam();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Invite User To Team</h2>

        <input
          className="modal-board-text"
          type="email"
          name="userEmail"
          placeholder="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          autoFocus
          required
        />
        <div className="modal-button-container">
          <button className="modal-board-button" type="submit">
            Add User
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="modal-board">
      <div className="close-icon-container">
        <FaTimes
          onClick={() => {
            closeModal();
            if (teamId) {
              showTeamSetting();
            }
          }}
          className="close-icon"
        />
        {type === 'ADD' ? <AddUserToTeamModal /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    user: state.user.userData,
    updating: state.team.updating,
    teamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    inviteUser: (token, userObj, params) =>
      dispatch(inviteMemberToTeam(token, userObj, params)),
    showTeamSetting: () => dispatch(setTeamSettingsModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserModal);
