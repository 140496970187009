import {
  CLOSE_MODAL,
  BOARD_MODAL,
  COLUMN_MODAL,
  CARD_MODAL,
  USER_MODAL,
  CHAT_MODAL,
  PIN_MODAL,
  ADD,
  CONFERENCE_MODAL,
  TOGGLE_MINIMIZE,
  CLOSE_CONFERENCE_MODAL,
  ERROR_MODAL,
  INFO_MODAL,
  CALENDAR_MODAL,
  USERBOARDS_MODAL,
  NOTIFICATION_MODAL,
  CLOSE_NOTIFICATION_MODAL,
  USER_PROFILE,
  PRICING_MODAL,
  TEAM_MODAL,
  TEAM_SETTINGS_MODAL,
  CLOSE_TEAM_SETTINGS_MODAL,
  TEAM_USER_MODAL,
  SUBSCRIBE_PLAN_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
  TOGGLE_SOCKET_MODAL,
  TOGGLE_CARD_MODAL_SAVE_BUTTON,
} from './modalTypes';

export const toggleCardSaveBtn = (state) => {
  return {
    type: TOGGLE_CARD_MODAL_SAVE_BUTTON,
    payLoad: state,
  };
};

export const toggleSocketModal = (state) => {
  return {
    type: TOGGLE_SOCKET_MODAL,
    payLoad: state,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};

export const setBoardModal = (actionType = ADD) => {
  return {
    type: BOARD_MODAL,
    payLoad: actionType,
  };
};

export const setTeamUserModal = () => {
  return {
    type: TEAM_USER_MODAL,
  };
};

export const setCancelSubscriptionModal = () => {
  return {
    type: CANCEL_SUBSCRIPTION_MODAL,
  };
};

export const setTeamModal = (actionType = ADD) => {
  return {
    type: TEAM_MODAL,
    payLoad: actionType,
  };
};

export const setUserModal = (actionType = ADD) => {
  return {
    type: USER_MODAL,
    payLoad: actionType,
  };
};

export const setPricingModal = () => {
  return {
    type: PRICING_MODAL,
  };
};

export const setCalendarModal = (actionType = ADD) => {
  return {
    type: CALENDAR_MODAL,
  };
};

export const setUserProfileModal = (actionType = ADD) => {
  return {
    type: USER_PROFILE,
  };
};

export const setBoardUsersModal = (actionType = ADD) => {
  return {
    type: USERBOARDS_MODAL,
    payLoad: actionType,
  };
};

export const setChatModal = (actionType = ADD) => {
  return {
    type: CHAT_MODAL,
    payLoad: actionType,
  };
};

export const setPinModal = (actionType = ADD) => {
  return {
    type: PIN_MODAL,
    payLoad: actionType,
  };
};

export const setSubscribeToPlanModal = (actionType = ADD) => {
  return {
    type: SUBSCRIBE_PLAN_MODAL,
    payLoad: actionType,
  };
};

export const setErrorModal = (message) => {
  return {
    type: ERROR_MODAL,
    payLoad: {
      actionType: ADD,
      message: message,
    },
  };
};

export const setInfoModal = (message) => {
  return {
    type: INFO_MODAL,
    payLoad: {
      actionType: ADD,
      message: message,
    },
  };
};

export const setColumnModal = (actionType = ADD) => {
  return {
    type: COLUMN_MODAL,
    payLoad: actionType,
  };
};

export const setCardModal = (actionType = ADD) => {
  return {
    type: CARD_MODAL,
    payLoad: actionType,
  };
};

export const setConferenceModal = () => {
  return {
    type: CONFERENCE_MODAL,
  };
};

export const setTeamSettingsModal = () => {
  return {
    type: TEAM_SETTINGS_MODAL,
  };
};

export const closeTeamSettingsModal = () => {
  return {
    type: CLOSE_TEAM_SETTINGS_MODAL,
  };
};

export const toggleMinimize = () => {
  return {
    type: TOGGLE_MINIMIZE,
  };
};

export const setNotificationModal = (eventData) => {
  return {
    type: NOTIFICATION_MODAL,
    payLoad: eventData,
  };
};

export const closeNotificationModal = () => {
  return {
    type: CLOSE_NOTIFICATION_MODAL,
  };
};

export const closeConferenceModal = () => {
  return {
    type: CLOSE_CONFERENCE_MODAL,
  };
};
