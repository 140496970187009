import axios from "axios";
import { StreamChat } from "stream-chat";
import {
  SUDO_SIGNIN_ERROR,
  SUDO_SIGNIN_REQUEST,
  SUDO_SIGNIN_SUCCESS,
  SUDO_SIGNOUT,
} from "./userTypes";
import {
  signInUserSuccess,
  handleLogOutUserSudo,
  clearUsers,
  setUserChatData,
} from "../..";
import SERVER_URL from "../../URL";

export const sudoSigninRequest = () => {
  return {
    type: SUDO_SIGNIN_REQUEST,
  };
};

export const sudoSigninSuccess = (sudoObj) => {
  return {
    type: SUDO_SIGNIN_SUCCESS,
    payLoad: sudoObj,
  };
};

export const sudoSigninError = (error) => {
  return {
    type: SUDO_SIGNIN_ERROR,
    payLoad: error,
  };
};

export const sudoSignout = () => {
  return {
    type: SUDO_SIGNOUT,
  };
};

export const handleSudoSignIn = (token, adminData, userId, history) => {
  const client = StreamChat.getInstance(
    process.env.REACT_APP_STREAM_API_KEY,
    process.env.REACT_APP_STREAM_API_SECRET
  );

  return async (dispatch) => {
    await client.disconnectUser();
    dispatch(sudoSigninRequest());
    dispatch(handleLogOutUserSudo());
    axios
      .post(
        `${SERVER_URL}/api/v1/users/sudo-signin`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            userId,
            password: process.env.REACT_APP_ADMIN_SUDO_PASSWORD,
          },
        }
      )
      .then((response) => {
        const user = response.data.data.user;

        client
          .connectUser(
            {
              id: user._id,
              firstName: user.firstName,
              lastName: user.lastName,
              name: `${user.firstName} ${user.lastName}`,
              image: user.photo,
              email: user.email,
            },
            user.chatToken
          )
          .then((response) => {
            dispatch(setUserChatData(response.me));
          })
          .catch((error) => {
            console.log("error connecting chat user");
          });

        dispatch(
          sudoSigninSuccess({
            userData: adminData,
            isSudo: true,
          })
        );
        dispatch(signInUserSuccess(user));
        history.push("/boards");
      })
      .catch((error) => {
        console.log(error, error.response);
        dispatch(sudoSigninError(error.response?.data.message));
      });
  };
};

export const handleSudoSignOut = (adminData, history) => {
  const client = StreamChat.getInstance(
    process.env.REACT_APP_STREAM_API_KEY,
    process.env.REACT_APP_STREAM_API_SECRET
  );

  return async (dispatch) => {
    await client.disconnectUser();
    dispatch(handleLogOutUserSudo());
    dispatch(clearUsers());

    const user = adminData;
    dispatch(signInUserSuccess(user));
    dispatch(sudoSignout());
    history.push("/admin/users");
  };
};
