import React from 'react';
import { connect } from 'react-redux';

import { setBoardModal, setSelectTeam } from '../../redux';

import BoardItem from '../BoardItem/BoardItem';

import './boardList.css';

const BoardList = ({
  boards,
  searchKeyWord,
  showBoardModal,
  user,
  setCurrentTeam,
}) => {
  const checkFreePlanRestrictions = () => {
    let boardsOwnedCounter = 0;

    if (user.plan.title === 'Basic') {
      for (let board of boards) {
        if (board.owner._id === user._id) {
          ++boardsOwnedCounter;
        }
      }
    }

    if (boardsOwnedCounter >= 2) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="boards-list">
      {boards
        .filter((board) =>
          board?.title
            ?.toLowerCase()
            ?.replace(/\s/g, '')
            ?.includes(searchKeyWord.toLowerCase().replace(/\s/g, ''))
        )
        .map(
          (boardItem) =>
            !boardItem.team && (
              <BoardItem key={boardItem.id} board={boardItem} />
            )
        )}

      {checkFreePlanRestrictions() && (
        <div
          className="add-board-button sub-color"
          onClick={() => {
            setCurrentTeam({});
            showBoardModal();
          }}
        >
          + Add New Board
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boards: state.board.boards,
    user: state.user.userData,
    searchKeyWord: state.board.searchKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showBoardModal: () => dispatch(setBoardModal()),
    setCurrentTeam: (team) => dispatch(setSelectTeam(team)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardList);
