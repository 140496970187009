import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import AppBar from '../../components/appbar/AppBar';
import ColumnHolder from '../../components/column-holder/ColumnHolder';
import BoardNavBar from '../../components/board-nav-bar/BoardNavBar';
import ActivityModal from '../../components/ActivityModal/ActivityModal';
import FileModal from '../../components/FileModal/FileModal';
import PinDropdown from '../../components/PinDropdown/PinDropdown';
import ChatButton from '../../components/ChatButton/ChatButton';
import NotificationModal from '../../components/modal/NotificationModal/NotificationModal';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import SocketInfoModal from '../../components/modal/InfoModal/SocketInfoModal';

import socket from '../../Socket';

import {
  changeColumnsOrderLocal,
  changeCardsOderIo,
  handleAddNewColumnLocal,
  editColumnLocal,
  handleDeleteColumnLocal,
  addNewCardLocal,
  editCardLocal,
  deleteCardLocal,
  fetchColumns,
  clearColumns,
  closeModal,
  fetchBoard,
  setCurrentColumnData,
  fetchCard,
  fetchEvents,
  setNotificationModal,
  updateBoardMembers,
  refreshColumnsLocal,
  setCurrentBoardData,
  removeInUseCardByUser,
  getTimeoutStatus,
  clearBoards,
} from '../../redux';

import './activityPage.css';

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSocketModal: false,
    };
    this.disconnectSocketWithData = this.disconnectSocketWithData.bind(this);
  }

  disconnectSocketWithData() {
    this.props.closeAllModal();
  }

  hhmmssToSeconds = (timestr) => {
    const time = timestr.split(':');
    time[0] = parseInt(time[0]) * 3600;
    time[1] = parseInt(time[1]) * 60;
    time[2] = parseInt(time[2]);
    return time.reduce((acc, val) => acc + val);
  };

  timeDifference = (timeA, timeB) => {
    const difference =
      this.hhmmssToSeconds(timeA) - this.hhmmssToSeconds(timeB);
    return difference;
  };

  isFifteenMinutes = (timeA, timeB, duration) => {
    return this.timeDifference(timeA, timeB) === duration; // 600
  };

  isNow = (timeA, timeB, duration) => {
    return this.timeDifference(timeA, timeB) === duration; // 0
  };

  checkTiming = (event) => {
    if (
      this.isFifteenMinutes(
        moment(event.start).format('HH:mm:ss'),
        moment().format('HH:mm:ss'),
        900
      )
    ) {
      return this.props.showEventNotification({ ...event, duration: 15 });
    } else if (
      this.isNow(
        moment(event.start).format('HH:mm:ss'),
        moment().format('HH:mm:ss'),
        1
      )
    ) {
      return this.props.showEventNotification({ ...event, duration: 0 });
    }
  };

  showEventNotifications = () => {
    this.props.eventList.forEach((event) => {
      if (
        event.recurring &&
        event.recurring.repeat === 'daily' &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring &&
        event.recurring.repeat === 'weekly' &&
        event.recurring.weekDays === moment().format('dd') &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring &&
        event.recurring.repeat === 'monthly' &&
        event.recurring.day === moment().format('D') &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring === null &&
        moment().isBefore(event.start) &&
        moment().isSame(event.start, 'day')
      ) {
        this.checkTiming(event);
      }
    });
  };

  handleWindowFocus = () => {
    window.location.reload(false);
    console.log('focuseddddd');
  };

  handleSetupCard = (boardId, cardId) => {
    this.props.getColumns(
      this.props.user.token,
      { boardId },
      this.props.history
    );
    this.props.getBoard(
      this.props.user.token,
      boardId,
      { boardId },
      null,
      this.props.history
    );
    this.props.getCard(this.props.user.token, { boardId }, cardId);
    this.props.getEvents(this.props.user.token);
    socket.connect();
    socket.emit('newUser', this.props.user._id);
    socket.emit('join-board', { boardId, user: this.props.user._id });
  };

  handleSetupBoard = (boardId) => {
    this.props.getBoard(
      this.props.user.token,
      boardId,
      { boardId },
      null,
      this.props.history
    );
    this.props.getColumns(
      this.props.user.token,
      { boardId },
      this.props.history
    );

    this.props.getEvents(this.props.user.token);
    socket.connect();
    socket.emit('newUser', this.props.user._id);
    socket.emit('join-board', { boardId, user: this.props.user._id });
  };

  componentDidMount() {
    if (this.isMobileDevice()) {
      window.addEventListener('focus', this.handleWindowFocus);
    }
    const url = window.location.href;
    const urlParts = url
      .replace('http://localhost:3000/board/', '')
      .replace('https://kevvlar.com/board/', '')
      .split('/');
    const boardId = urlParts[0];
    const cardId = urlParts[1];

    window.addEventListener('beforeunload', this.disconnectSocketWithData);

    // show card by url
    if (boardId && cardId) {
      socket.disconnect();
      this.handleSetupCard(boardId, cardId);
    }

    // Setup board view
    if (boardId && cardId === undefined) {
      socket.disconnect();
      this.handleSetupBoard(boardId);
    }

    this.showEventInterval = setInterval(() => {
      this.showEventNotifications();
    }, 1000);

    window.onoffline = () => {
      this.props.history.push('/error');
    };

    this.hearbeatInterval = setInterval(() => {
      socket.emit('ping');
    }, 5000);

    this.socketDisconnectInterval = setInterval(() => {
      if (socket.disconnected) {
        this.props.getBoard(
          this.props.user.token,
          boardId,
          { boardId },
          null,
          this.props.history
        );
        this.props.getColumns(
          this.props.user.token,
          { boardId },
          this.props.history
        );

        socket.connect();
        socket.emit('newUser', this.props.user._id);
        socket.emit('join-board', { boardId, user: this.props.user._id });
      }
    }, 5000);

    socket.on('pong', () => {});

    socket.on('user-joined-board', (data) => {
      const { user } = data;
      if (user !== this.props.user._id) {
        this.props.getBoard(
          this.props.user.token,
          this.props.boardId,
          {
            boardId: this.props.boardId,
          },
          null,
          this.props.history
        );
      }
    });

    socket.on('receive-column-order', (data) => {
      this.props.setBoardData(data.board);
      this.props.refreshColumns(data.columns);
    });

    socket.on('receive-cards-order', (data) => {
      this.props.setBoardData(data.board);
      this.props.refreshColumns(data.columns);
    });

    socket.on('receive-card-column', (data) => {
      this.props.setBoardData(data.board);
      this.props.refreshColumns(data.columns);
    });

    socket.on('receive-new-column', (data) => {
      this.props.addNewColumn(data);
    });

    socket.on('receive-edit-column', (data) => {
      this.props.updateColumn(data);
    });

    socket.on('receive-delete-column', (data) => {
      this.props.removeColumn(data);
    });

    socket.on('receive-new-card', (data) => {
      this.props.createCard(data);
    });

    socket.on('receive-edit-card', (data) => {
      this.props.updateCard(data);
    });

    socket.on('receive-delete-card', (data) => {
      this.props.handleDeleteCard(data);
    });

    socket.on('removed-member-email', (email) => {
      if (this.props.user.email === email) {
        this.props.history.push('/boards');
      }
    });

    socket.on('receive-members', (members) => {
      this.props.updateMembers(members);
    });

    socket.on('user-left', (userId) => {
      this.props.clearDisconnectedUserData(userId);
    });

    this.props.getUserTimeoutStatus({ boardId });
  }

  componentWillUnmount() {
    socket.off('user-joined-board');

    socket.off('user-left');

    socket.off('receive-new-column');

    socket.off('receive-members');

    socket.off('receive-column-order');

    socket.off('receive-cards-order');

    socket.off('receive-card-column');

    socket.off('receive-edit-column');

    socket.off('receive-delete-column');

    socket.off('receive-new-card');

    socket.off('receive-edit-card');

    socket.off('receive-delete-card');

    socket.off('removed-member-email');

    this.props.closeAllModal();

    clearInterval(this.showEventInterval);
    clearInterval(this.hearbeatInterval);
    clearInterval(this.socketDisconnectInterval);

    window.removeEventListener('beforeunload', this.disconnectSocketWithData);

    if (this.isMobileDevice()) {
      window.removeEventListener('focus', this.handleWindowFocus);
    }

    socket.disconnect();
  }

  isMobileDevice = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  render() {
    return (
      <div>
        {this.props.isFetching ? (
          <LoadingScreen message={'Loading'} />
        ) : (
          <div className="todopage">
            <AppBar />
            <BoardNavBar />
            <ColumnHolder />
            <NotificationModal />
            {this.props.activity && <ActivityModal />}
            {this.props.showFiles && <FileModal />}
            {this.props.showPins && <PinDropdown />}
            {this.props.socketModalState && (
              <SocketInfoModal info="Oops! It seems you're not connected to Kevvlar servers. We're reconnecting you." />
            )}
            <ChatButton />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateColumnsOrderLocal: (changeObj) =>
      dispatch(changeColumnsOrderLocal(changeObj)),
    updateCardsOrder: (changeObj) => dispatch(changeCardsOderIo(changeObj)),
    addNewColumn: (columnObj) => dispatch(handleAddNewColumnLocal(columnObj)),
    updateColumn: (columnObj) => dispatch(editColumnLocal(columnObj)),
    removeColumn: (columnId) => dispatch(handleDeleteColumnLocal(columnId)),
    createCard: (cardObj) => dispatch(addNewCardLocal(cardObj)),
    updateCard: (cardObj) => dispatch(editCardLocal(cardObj)),
    handleDeleteCard: (deleteObj) => dispatch(deleteCardLocal(deleteObj)),
    getColumns: (token, params, history) =>
      dispatch(fetchColumns(token, params, history)),
    emptyColumns: () => dispatch(clearColumns()),
    closeAllModal: () => dispatch(closeModal()),
    getColumnData: (columnObj) => dispatch(setCurrentColumnData(columnObj)),
    getBoard: (token, boardId, params, isMeeting, routing) =>
      dispatch(fetchBoard(token, boardId, params, isMeeting, routing)),
    getCard: (token, boardId, cardId) =>
      dispatch(fetchCard(token, boardId, cardId)),
    getEvents: (token) => dispatch(fetchEvents(token)),
    showEventNotification: (eventData) =>
      dispatch(setNotificationModal(eventData)),
    updateMembers: (members) => dispatch(updateBoardMembers(members)),
    refreshColumns: (columns) => dispatch(refreshColumnsLocal(columns)),
    setBoardData: (board) => dispatch(setCurrentBoardData(board)),
    clearDisconnectedUserData: (userData) =>
      dispatch(removeInUseCardByUser(userData)),
    emptyBoards: () => dispatch(clearBoards()),
    getUserTimeoutStatus: (params) => dispatch(getTimeoutStatus(params)),
  };
};

const mapStateToProps = (state) => {
  return {
    boardId: state.board.selectBoard?.id,
    user: state.user.userData,
    leftSideNav: state.sideNavLeft.leftSideNav,
    rightSideNav: state.sideNavRight.rightSideNav,
    showModal: state.modal.showModal,
    eventList: state.calendar.eventList,
    isFetching: state.column.isFetchingColumn,
    isChatModalOpen: state.chat.chatModalOpen,
    modalType: state.modal.conference,
    showFiles: state.file.showFiles,
    showPins: state.pin.showPins,
    activity: state.activity.showActivity,
    cardId: state.column.selectCard.id,
    socketModalState: state.modal.showSocketModal,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainPage));
