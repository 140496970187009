import {
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  ADD_NEW_TEAM_SERVER_REQUEST,
  ADD_NEW_TEAM_SERVER_SUCCESS,
  ADD_NEW_TEAM_SERVER_FAILURE,
  EDIT_TEAM_SERVER_REQUEST,
  EDIT_TEAM_SERVER_SUCCESS,
  EDIT_TEAM_SERVER_FAILURE,
  DELETE_TEAM_SERVER_REQUEST,
  DELETE_TEAM_SERVER_SUCCESS,
  DELETE_TEAM_SERVER_FAILURE,
  SET_SELECT_TEAM,
  INVITE_MEMBER_TO_TEAM_REQUEST,
  INVITE_MEMBER_TO_TEAM_SUCCESS,
  INVITE_MEMBER_TO_TEAM_FAILURE,
  REMOVE_MEMBER_FROM_TEAM_REQUEST,
  REMOVE_MEMBER_FROM_TEAM_SUCCESS,
  REMOVE_MEMBER_FROM_TEAM_FAILURE,
} from "./teamTypes";

const initialState = {
  teams: [],
  loading: false,
  updating: false,
  error: "",
  selectTeam: {},
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payLoad,
      };
    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case FETCH_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectTeam: action.payLoad,
      };

    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case ADD_NEW_TEAM_SERVER_REQUEST:
      return {
        ...state,
        updating: true,
        error: "",
      };

    case ADD_NEW_TEAM_SERVER_SUCCESS:
      return {
        ...state,
        updating: false,
        teams: [...state.teams, action.payLoad],
      };

    case ADD_NEW_TEAM_SERVER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payLoad,
      };

    case EDIT_TEAM_SERVER_REQUEST:
      return {
        ...state,
        updating: true,
        error: "",
      };

    case EDIT_TEAM_SERVER_SUCCESS:
      return {
        ...state,
        updating: false,
        selectTeam: {},
        teams: state.teams.map((team) =>
          team._id === action.payLoad._id ? { ...action.payLoad } : team
        ),
      };

    case EDIT_TEAM_SERVER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payLoad.error,
      };

    case SET_SELECT_TEAM:
      return {
        ...state,
        selectTeam: action.payLoad,
      };

    case DELETE_TEAM_SERVER_REQUEST:
      return {
        ...state,
        updating: true,
        error: "",
      };

    case DELETE_TEAM_SERVER_SUCCESS:
      return {
        ...state,
        updating: false,
        selectTeam: {},
        teams: state.teams.filter((team) => team._id !== action.payLoad),
      };

    case DELETE_TEAM_SERVER_FAILURE:
      return {
        updating: false,
        error: action.payLoad,
      };

    case REMOVE_MEMBER_FROM_TEAM_REQUEST:
      return {
        ...state,
        updating: true,
        error: "",
      };

    case REMOVE_MEMBER_FROM_TEAM_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case REMOVE_MEMBER_FROM_TEAM_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payLoad,
      };

    case INVITE_MEMBER_TO_TEAM_REQUEST:
      return {
        ...state,
        updating: true,
        error: "",
      };

    case INVITE_MEMBER_TO_TEAM_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case INVITE_MEMBER_TO_TEAM_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payLoad,
      };

    default:
      return state;
  }
};

export default teamsReducer;
