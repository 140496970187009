import React from "react";
import moment from "moment";

import "./GraphSquare.css";

const GraphSquare = ({ columnData }) => {
  return (
    <div className="board-graph">
      {columnData?.map((row, i) => (
        <div
          key={i}
          className="graph-square"
          title={`${moment(row).format("Do MMM")} ${
            columnData.length
          } activities`}
        />
      ))}
    </div>
  );
};

export default GraphSquare;

// style={{ height: graphdata.length * 3 }}
// title={graphdata.length + " activities"}
