import React from 'react';
import { connect } from 'react-redux';

import { closeModal, setSelectPlan } from '../../../redux';

const SubscriptionInfo = ({ planData, closeModal, clearSelectPlan }) => {
  return (
    <div className="modal-error">
      <div className="close-icon-container">
        <div className="modal-body">
          <h2 className="modal-title">Success</h2>
          <p>{`Subscription to the '${planData.title.toUpperCase()}' plan successful`}</p>
          <button
            className="modal-error-button"
            onClick={() => {
              clearSelectPlan({});
              closeModal();
              window.location.reload();
            }}
          >
            close
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    planData: state.planSubscription.selectPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    clearSelectPlan: (planData) => dispatch(setSelectPlan(planData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionInfo);
