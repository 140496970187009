import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Eventcalendar,
  setOptions,
  momentTimezone,
  alert,
} from '@mobiscroll/react';

import NewEventPopup from '../../components/Popups/NewEventPopup';
import ViewEventPopup from '../../components/Popups/ViewEventPopup';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import './calendarPage.css';

import {
  addEventServer,
  editEventServer,
  deleteEventServer,
  refreshEventList,
  fetchEvents,
} from '../../redux';
import { useState } from 'react';
import { useCallback } from 'react';

// setup Mobiscroll plugin
momentTimezone.moment = moment;

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

const CalendarPage = ({
  user,
  eventList,
  createEventServer,
  updateEvent,
  updateEventServer,
  removeEventServer,
  boards,
  isFetching,
  getEvents,
  refreshEvents,
}) => {
  // Handles Mobiscroll ui actions
  const [isOpen, setOpen] = React.useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [cellDate, setCellDate] = React.useState();

  // handles inviting and removing user to an event
  const [eventUsers, setEventUsers] = React.useState([]);
  const [newEventUsers, setNewEventUsers] = React.useState([]);

  // handles selection of board for event
  const [selectBoardId, setSelectBoardId] = React.useState();
  const [boardUsers, setBoardUsers] = React.useState();

  // handles event form data
  const [popupEventTitle, setTitle] = React.useState('');
  const [popupEventDescription, setDescription] = React.useState('');
  const [popupEventDate, setDate] = React.useState([]);
  const [mySelectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedRepeat, setSelectedRepeat] = React.useState();
  const [tempEvent, setTempEvent] = React.useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents(user.token);
  }, [user.token, getEvents]);

  // parsing recurring selection
  const getRecurringRule = React.useCallback(() => {
    switch (selectedRepeat) {
      case 'daily':
        return {
          repeat: 'daily',
          until: moment(cellDate).add(1, 'year').format('YYYY-MM-DD'),
          interval: 1,
        };
      case 'weekly':
        return {
          repeat: 'weekly',
          until: moment(cellDate).add(1, 'year').format('YYYY-MM-DD'),
          weekDays: moment(cellDate).format('dd'),
          interval: 1,
        };
      case 'monthly':
        return {
          repeat: 'monthly',
          until: moment(cellDate).add(1, 'year').format('YYYY-MM-DD'),
          day: moment(cellDate).format('D'),
          interval: 1,
        };

      default:
        return null;
    }
  }, [selectedRepeat, cellDate]);

  // handle popup form changes
  const titleChange = React.useCallback((ev) => {
    setTitle(ev.target.value);
  }, []);

  const descriptionChange = React.useCallback((ev) => {
    setDescription(ev.target.value);
  }, []);

  const dateChange = React.useCallback((args) => {
    setDate(args.value);
  }, []);

  const onSelectedDateChange = React.useCallback((event) => {
    setSelectedDate(new Date(event.date));
  }, []);

  const repeatChange = React.useCallback((e) => {
    setSelectedRepeat(e.value);
  }, []);

  // Handle get all users on a board by it's id when an event is selected.
  const getAllUsers = React.useCallback(
    (id) => {
      if (id) {
        let board = boards.find((boardItem) => boardItem.id === id);
        setBoardUsers([...board?.admins, ...board?.members]);
      } else {
        setBoardUsers([]);
      }
    },
    [boards]
  );

  // Handles selecting of board and get all users on a board
  const boardIdChange = React.useCallback(
    (e) => {
      let board = boards.find((boardItem) => boardItem.id === e.value);
      setSelectBoardId(e.value);
      setBoardUsers([...board?.admins, ...board?.members]);
    },
    [boards]
  );

  const selectUserChange = (user) => {
    const isUserNotPresentEvent = !eventUsers.includes(user._id);
    const isUserNotPresentNewEvent = !newEventUsers.includes(user._id);

    if (isUserNotPresentEvent && isUserNotPresentNewEvent) {
      setNewEventUsers([...newEventUsers, user._id]);
      setEventUsers([...eventUsers, user._id]);
      return;
    }

    if (!isUserNotPresentEvent && !isUserNotPresentNewEvent) {
      setNewEventUsers(newEventUsers.filter((userId) => userId !== user._id));
      setEventUsers(eventUsers.filter((userId) => userId !== user._id));
      return;
    }

    if (isUserNotPresentNewEvent && !isUserNotPresentEvent) {
      setNewEventUsers(newEventUsers.filter((userId) => userId !== user._id));
      setEventUsers(eventUsers.filter((userId) => userId !== user._id));
      return;
    }
  };

  const loadPopupForm = React.useCallback(
    (event) => {
      setNewEventUsers([]);
      setTitle(event.title);
      setDescription(event?.description || '');
      setDate([event.start, event.end]);
      setSelectedRepeat(event?.recurring?.repeat);
      setEventUsers(event?.users?.map((userObj) => userObj._id) || []);
      setSelectBoardId(event?.boardId);
      getAllUsers(event?.boardId);
    },
    [getAllUsers]
  );

  const onEventClick = React.useCallback(
    (args) => {
      if (args.event.originUser._id === user._id) {
        setEdit(true);
        setCellDate(args.date);
        setTempEvent({ ...args.event });
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);
        setOpen(true);
      } else {
        setTempEvent({ ...args.event });
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);
        setIsPopOverOpen(true);
      }
    },
    [loadPopupForm, user]
  );

  const onCellDoubleClick = React.useCallback(
    (args) => {
      setCellDate(args.date);
    },
    [setCellDate]
  );

  const onEventCreated = React.useCallback(
    (args) => {
      setEdit(false);
      setTempEvent({ ...args.event });
      loadPopupForm({ ...args.event });
      setAnchor(args.target);
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventUpdated = React.useCallback(
    (args) => {
      updateEvent(args.event.id, args.event);
    },
    [updateEvent]
  );

  const saveEvent = React.useCallback(() => {
    if (!selectBoardId) {
      return alert({
        title: 'Oops!!!',
        message: "Seems you haven't selected a project for your event.",
      });
    }

    if (!popupEventTitle) {
      return alert({
        title: 'Oops!!!',
        message: "Seems you haven't given your event a title.",
      });
    }

    if (isEdit) {
      const updatedEvent = {
        id: tempEvent.id,
        title: popupEventTitle,
        description: popupEventDescription,
        start: popupEventDate[0],
        end: popupEventDate[1],
        users: eventUsers,
        originUser: user._id,
        boardId: selectBoardId,
        recurring: getRecurringRule(),
      };

      updateEventServer(
        user.token,
        tempEvent.id,
        { boardId: selectBoardId },
        updatedEvent,
        newEventUsers,
        setOpen
      );
    } else {
      const newEvent = {
        id: nanoid(7),
        title: popupEventTitle,
        description: popupEventDescription,
        start: popupEventDate[0],
        end: popupEventDate[1],
        users: newEventUsers,
        originUser: user._id,
        boardId: selectBoardId,
        recurring: getRecurringRule(),
      };

      createEventServer(
        user.token,
        { boardId: selectBoardId },
        newEvent,
        setOpen
      );
    }
  }, [
    isEdit,
    updateEventServer,
    popupEventDate,
    popupEventDescription,
    popupEventTitle,
    newEventUsers,
    eventUsers,
    user,
    getRecurringRule,
    selectBoardId,
    createEventServer,
    tempEvent,
  ]);

  const onDeleteClick = React.useCallback(() => {
    removeEventServer(
      user.token,
      { boardId: tempEvent.boardId },
      tempEvent,
      setOpen
    );
  }, [tempEvent, removeEventServer, user]);

  const onClose = React.useCallback(() => {
    refreshEvents();
    setTempEvent(null);
    setCellDate();
    setIsPopOverOpen(false);
    setTitle('');
    setDescription('');
    setDate([]);
    setSelectedRepeat('norepeat');
    setSelectedDate();
    setOpen(false);
    setSelectBoardId();
    setBoardUsers([]);
    setEventUsers([]);
    setNewEventUsers([]);
  }, [refreshEvents]);

  const displayEventsByTimezone = useCallback(() => {
    return eventList.map((event) => ({
      ...event,
      start: moment(event.start).tz(moment.tz.guess()).toDate(), // Convert start time to user's timezone
      end: moment(event.end).tz(moment.tz.guess()).toDate(), // Convert end time to user's timezone
    }));
  }, [eventList]);

  useEffect(() => {
    const parsedEvents = displayEventsByTimezone();
    setEvents(parsedEvents);
  }, [displayEventsByTimezone]);

  return (
    <div>
      {isFetching ? (
        <LoadingScreen message={'Loading'} />
      ) : (
        <div>
          {/* {console.log(eventUsers)} */}
          <Eventcalendar
            view={{
              schedule: {
                type: 'week',
                allDay: true,
              },
            }}
            timezonePlugin={momentTimezone}
            data={events}
            clickToCreate="double"
            selectedDate={mySelectedDate}
            onSelectedDateChange={onSelectedDateChange}
            onEventClick={onEventClick}
            onEventCreated={onEventCreated}
            onEventUpdated={onEventUpdated}
            onCellDoubleClick={onCellDoubleClick}
            cssClass="md-switching-view-cont"
            theme="ios"
          />
          <NewEventPopup
            anchor={anchor}
            isOpen={isOpen}
            onClose={onClose}
            isEdit={isEdit}
            popupEventTitle={popupEventTitle}
            titleChange={titleChange}
            popupEventDescription={popupEventDescription}
            descriptionChange={descriptionChange}
            popupEventDate={popupEventDate}
            dateChange={dateChange}
            startRef={startRef}
            endRef={endRef}
            start={start}
            end={end}
            boardIdChange={boardIdChange}
            selectBoardId={selectBoardId}
            selectedRepeat={selectedRepeat}
            repeatChange={repeatChange}
            saveEvent={saveEvent}
            onDeleteClick={onDeleteClick}
            eventUsers={eventUsers}
            boardUsers={boardUsers}
            selectUserChange={selectUserChange}
          />
          <ViewEventPopup
            event={tempEvent}
            anchor={anchor}
            isPopOverOpen={isPopOverOpen}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    eventList: state.calendar.eventList,
    boards: state.board.boards,
    isFetching: state.calendar.isFetching,
    board: state.board.selectBoard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEventServer: (token, params, eventObj, setOpen) =>
      dispatch(addEventServer(token, params, eventObj, setOpen)),
    updateEventServer: (
      token,
      eventId,
      params,
      eventObj,
      newEventUsers,
      setOpen
    ) =>
      dispatch(
        editEventServer(
          token,
          eventId,
          params,
          eventObj,
          newEventUsers,
          setOpen
        )
      ),
    removeEventServer: (token, params, event, setOpen) =>
      dispatch(deleteEventServer(token, params, event, setOpen)),
    refreshEvents: () => dispatch(refreshEventList()),
    getEvents: (token) => dispatch(fetchEvents(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
