import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  toggleRightSideNav,
  clearColumns,
  isReadServer,
  setCalendarModal,
  acceptInvitation,
  declineInvitation,
  fetchCard,
  fetchEvents,
  fetchBoard,
  fetchColumns,
  setSelectTeam,
  acceptTeamInvitation,
  declineTeamInvitation,
} from '../../redux';

import socket from '../../Socket';

import './notificationItem.css';

const NotificationItem = ({
  notification,
  toggleRightSideNav,
  history,
  user,
  toggleRead,
  showCalendar,
  accept,
  acceptTeam,
  decline,
  declineTeam,
  loading,
  resetColumns,
  getColumns,
  curBoardId,
  getCard,
  getBoard,
  getEvents,
  updateSelectTeam,
}) => {
  const [isAccept, setIsAccept] = useState(false);
  const [isDecline, setIsDecline] = useState(false);

  // handles read or unread notifications
  const tickIsRead = () => {
    toggleRead(user.token, notification._id);
  };

  const handleDirectToBoard = (boardId, cardId) => {
    if (history.location.pathname.includes('/boards')) {
      updateSelectTeam({});
      getBoard(user.token, boardId, { boardId, teamId: null }, null, history);
      toggleRightSideNav();
      history.push(`/board/${boardId}/${cardId}`);
      return;
    }
    resetColumns();
    updateSelectTeam({});
    getColumns(user.token, { boardId, teamId: null }, history);
    getBoard(user.token, boardId, { boardId, teamId: null }, null, history);
    getCard(user.token, { boardId, teamId: null }, cardId);
    getEvents(user.token);
    socket.connect();
    socket.emit('newUser', user._id);
    socket.emit('join-board', { boardId, user: user._id });
    history.push(`/board/${boardId}/${cardId}`);
    toggleRightSideNav();
  };

  const handleNotificationClick = () => {
    tickIsRead();
    if (notification && notification.card !== null && notification.cardId) {
      const cardData = notification.card;

      if (cardData.boardId === curBoardId) {
        getCard(
          user.token,
          { boardId: cardData.boardId, teamId: notification.teamId },
          notification.cardId
        );
        history.push(`${history.location.pathname}/${notification.cardId}`);
      } else {
        const { boardId } = cardData;
        const { cardId } = notification;

        socket.emit('exit', curBoardId);
        socket.disconnect();
        handleDirectToBoard(boardId, cardId);
      }
    } else if (
      notification &&
      notification.event !== null &&
      notification.eventId
    ) {
      // handles showing calendar
      showCalendar();
      toggleRightSideNav();
    }
  };

  const showText = () => {
    const notifyData = notification;
    if (notifyData && notifyData.cardId && notifyData.card) {
      return notification.card.title;
    } else if (notifyData && notifyData.eventId && notifyData.event) {
      return notification.event.title;
    } else if (notifyData && notifyData.cardId && notifyData.card === null) {
      return 'Card for this notification no longer exists.';
    } else if (notifyData && notifyData.eventId && notifyData.event === null) {
      return 'Event for this notification no longer exists.';
    }
  };

  const handleAcceptBoardInvite = () => {
    setIsAccept(true);
    accept(
      user.token,
      {
        boardId: notification?.board?.id,
        notifyId: notification._id,
      },
      history
    );
  };

  const handleDeclineBoardInvite = () => {
    setIsDecline(true);
    decline(user.token, {
      boardId: notification?.board?.id,
      notifyId: notification._id,
    });
  };

  const BoardInviteButtons = () => (
    <div className="board-invite-button">
      <button
        className="invite-btn accept"
        onClick={handleAcceptBoardInvite}
        disabled={loading}
      >
        {loading && isAccept ? 'Accepting' : 'Accept Board'}
      </button>

      <button
        className="invite-btn decline"
        onClick={handleDeclineBoardInvite}
        disabled={loading}
      >
        {loading && isDecline ? 'Declining' : 'Decline Board'}
      </button>
    </div>
  );

  const handleAcceptTeamInvite = () => {
    setIsAccept(true);
    acceptTeam(
      user.token,
      {
        notifyId: notification._id,
        teamId: notification.teamId,
      },
      history
    );
  };

  const handleDeclineTeamInvite = () => {
    setIsDecline(true);
    declineTeam(user.token, {
      notifyId: notification._id,
      teamId: notification.teamId,
    });
  };

  const TeamInviteButtons = () => (
    <div className="board-invite-button">
      <button
        className="invite-btn accept"
        onClick={handleAcceptTeamInvite}
        disabled={loading}
      >
        {loading && isAccept ? 'Accepting' : 'Accept Team'}
      </button>

      <button
        className="invite-btn decline"
        onClick={handleDeclineTeamInvite}
        disabled={loading}
      >
        {loading && isDecline ? 'Declining' : 'Decline Team'}
      </button>
    </div>
  );

  const ShowBoardNotificationTitle = () => (
    <span>
      {notification?.board?.title} {notification.title}
    </span>
  );

  const ShowTeamNotificationTitle = () => (
    <span>
      {notification?.team?.title} {notification.title}
    </span>
  );

  const ShowNotificationTitle = () => <span>{notification.title}</span>;

  return (
    <div className="task-item-holder">
      <div className="task-item">
        {notification.title !== 'Board Invite' &&
          notification.title !== 'Team Invite' && <ShowNotificationTitle />}
        {notification?.title === 'Board Invite' && (
          <ShowBoardNotificationTitle />
        )}
        {notification?.title === 'Team Invite' && <ShowTeamNotificationTitle />}
        <span className="board-title-link" onClick={handleNotificationClick}>
          {showText()}
        </span>
        {notification.title === 'Board Invite' && <BoardInviteButtons />}
        {notification.title === 'Team Invite' && <TeamInviteButtons />}
        <div className="task-item-board">
          {moment(notification.timeCreated).format('DD MMM, YYYY')} at{' '}
          {moment(notification.timeCreated).format('h:MM a')}
        </div>
        <div
          onClick={tickIsRead}
          className={
            notification.isRead === false
              ? 'task-item-notification'
              : 'hide-icon'
          }
        ></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    curBoardId: state.board.selectBoard?.id,
    isCardNull: state.column.isCardIsNull,
    selectCard: state.column.selectCard,
    loading: state.board.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSideNav: () => dispatch(toggleRightSideNav()),
    resetColumns: () => dispatch(clearColumns()),
    toggleRead: (token, id) => dispatch(isReadServer(token, id)),
    showCalendar: () => dispatch(setCalendarModal()),
    accept: (token, answer, history) =>
      dispatch(acceptInvitation(token, answer, history)),
    decline: (token, answer) => dispatch(declineInvitation(token, answer)),
    getCard: (token, params, cardId) =>
      dispatch(fetchCard(token, params, cardId)),
    getBoard: (token, boardId, params, isMeeting, routing) =>
      dispatch(fetchBoard(token, boardId, params, isMeeting, routing)),
    getEvents: (token) => dispatch(fetchEvents(token)),
    getColumns: (token, params, history) =>
      dispatch(fetchColumns(token, params, history)),
    updateSelectTeam: (team) => dispatch(setSelectTeam(team)),
    acceptTeam: (token, answer, history) =>
      dispatch(acceptTeamInvitation(token, answer, history)),
    declineTeam: (token, answer) =>
      dispatch(declineTeamInvitation(token, answer)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationItem));
