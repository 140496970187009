import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  CLEAR_AUTH,
  SET_AUTH_EMAIL,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_VERIFICATION_REQUEST,
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./authTypes";

const initialState = {
  loading: false,
  authEmail: null,
  error: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        authEmail: null,
        error: "",
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        authEmail: action.payLoad,
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        authEmail: null,
      };

    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case RESEND_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case RESEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case RESEND_VERIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        authEmail: null,
      };

    case SET_AUTH_EMAIL:
      return {
        ...state,
        authEmail: action.payLoad,
      };

    default:
      return state;
  }
};

export default authReducer;
