import axios from 'axios';
import {
  SIGN_UP_USER_REQUEST,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_USER_FAILURE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_USER_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  UPDATE_PROFILE_WITH_IMG_REQUEST,
  UPDATE_PROFILE_WITH_IMG_SUCCESS,
  UPDATE_PROFILE_WITH_IMG_FAILIURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  LOG_USER_OUT,
  CLEAR_ERROR_MESSAGE,
  TURN_OFF_NOTIFY,
  SET_NOTIFY_STATUS,
  IS_READ,
  CLEAR_NOTIFICATIONS,
  SET_USER_PLAN,
} from './userTypes';

import {
  fetchColumns,
  fetchBoard,
  fetchBoards,
  clearActivities,
  clearBoards,
  clearChatState,
  clearEvents,
  clearColumns,
  clearFiles,
  clearPins,
  setAuthMail,
} from '../index';

import SERVER_URL from '../URL';
import { clearAuth } from '../auth/authActions';
import socket from '../../Socket';

export const signUpUserRequest = () => {
  return {
    type: SIGN_UP_USER_REQUEST,
  };
};

export const setUserPlan = (user) => {
  return {
    type: SET_USER_PLAN,
    payLoad: { plan: user.plan, stripeCancelAt: user.stripeCancelAt },
  };
};

export const signUpUserSuccess = (user) => {
  return {
    type: SIGN_UP_USER_SUCCESS,
    payLoad: user,
  };
};

export const signUpUserFailure = (error) => {
  return {
    type: SIGN_UP_USER_FAILURE,
    payLoad: error,
  };
};

export const signInUserRequest = () => {
  return {
    type: SIGN_IN_USER_REQUEST,
  };
};

export const signInUserSuccess = (user) => {
  return {
    type: SIGN_IN_USER_SUCCESS,
    payLoad: user,
  };
};

export const signInUserFailure = (error) => {
  return {
    type: SIGN_IN_USER_FAILURE,
    payLoad: error,
  };
};

export const logUserOut = () => {
  return {
    type: LOG_USER_OUT,
  };
};

export const clearNotifications = () => {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
};

export const handleLogOutUser = (history) => {
  return (dispatch) => {
    dispatch(logUserOut());
    dispatch(clearColumns());
    dispatch(clearBoards());
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearEvents());
    dispatch(clearChatState());
    dispatch(clearPins());
    history.push('/signin');
  };
};

export const handleLogOutUserSudo = () => {
  return (dispatch) => {
    dispatch(clearColumns());
    dispatch(clearBoards());
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearEvents());
    dispatch(clearChatState());
    dispatch(clearPins());
  };
};

export const updateUserProfilewithImgRequest = () => {
  return {
    type: UPDATE_PROFILE_WITH_IMG_REQUEST,
  };
};

export const updateUserProfileWithImgSuccess = (profile) => {
  return {
    type: UPDATE_PROFILE_WITH_IMG_SUCCESS,
    payLoad: profile,
  };
};

export const updateUserProfileWithImgFailure = (error) => {
  return {
    type: UPDATE_PROFILE_WITH_IMG_FAILIURE,
    payLoad: error,
  };
};

export const updateUserProfileRequest = () => {
  return {
    type: UPDATE_PROFILE_REQUEST,
  };
};

export const updateUserProfileSuccess = (profile) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payLoad: profile,
  };
};

export const updateUserProfileFailure = (error) => {
  return {
    type: UPDATE_PROFILE_FAILURE,
    payLoad: error,
  };
};

export const updateUserProfile = (profileData, token, boardId) => {
  return (dispatch) => {
    dispatch(updateUserProfileRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/users/profile`, profileData, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const user = response.data.data.user;
        dispatch(updateUserProfileSuccess(user));
        if (boardId) {
          dispatch(fetchBoard(token, boardId));
          dispatch(fetchColumns(token, boardId));
        } else {
          dispatch(fetchBoards(token));
        }
      })
      .catch((error) => {
        dispatch(updateUserProfileFailure(error.response.data.message));
      });
  };
};

export const fetchUserPlan = (token) => {
  return (dispatch) => {
    axios
      .get(`${SERVER_URL}/api/v1/users/get-user-plan`, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const user = response.data.user;
        dispatch(setUserPlan(user));
      })
      .catch((error) => {
        console.log('could not fetch user');
      });
  };
};

export const updateUserProfileWithImg = (profileData, token, boardId) => {
  return (dispatch) => {
    dispatch(updateUserProfilewithImgRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/users/profile/img`, profileData, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        socket.disconnect();
        const user = response.data.data.user;
        dispatch(updateUserProfileWithImgSuccess(user));
        socket.connect();
        socket.emit('newUser', user._id);
        socket.emit('join-board', { boardId, user: user._id });
        if (boardId) {
          dispatch(fetchBoard(token, boardId, { boardId }));
          dispatch(fetchColumns(token, { boardId }));
        } else {
          dispatch(fetchBoards(token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateUserProfileWithImgFailure(error.response.data.message));
      });
  };
};

export const signUserUp = (userData, history, entry = 'login-form-cta') => {
  return (dispatch) => {
    dispatch(signUpUserRequest());
    axios
      .post(
        `${SERVER_URL}/api/v1/users/signup`,
        { ...userData, webEntry: entry },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .then((response) => {
        const user = response.data;
        dispatch(setAuthMail(user.email));
        dispatch(signUpUserSuccess({}));
        history.push('/verify-user-email');
      })
      .catch((error) => {
        dispatch(signUpUserFailure(error.response?.data.message));
      });
  };
};

export const signUserIn = (userData, history, setRequiresVerification) => {
  return (dispatch) => {
    dispatch(signInUserRequest());
    axios
      .post(`${SERVER_URL}/api/v1/users/signin`, userData, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response) => {
        const user = response.data.data.user;
        dispatch(signInUserSuccess(user));
        dispatch(clearAuth());
        history.push('/boards');
      })
      .catch((error) => {
        dispatch(signInUserFailure(error.response?.data.message));
        if (error.response.status === 403) {
          dispatch(setAuthMail(userData.userEmail));
          setRequiresVerification(true);
        }
      });
  };
};

export const sendNotification = (token, params, notificationObject) => {
  return () => {
    axios
      .post(`${SERVER_URL}/api/v1/notifications/`, notificationObject, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const setNotifyStatus = (status) => {
  return {
    type: SET_NOTIFY_STATUS,
    payLoad: status,
  };
};

export const getNotificationStatus = (token) => {
  return (dispatch) => {
    axios
      .get(`${SERVER_URL}/api/v1/notifications/status`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(setNotifyStatus(response.data.data.status.isNotified));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const turnOffNotify = (bool) => {
  return {
    type: TURN_OFF_NOTIFY,
    payLoad: bool,
  };
};

export const fetchNotificationsRequest = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
};

export const fetchNotificationsSuccess = (notifications) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payLoad: notifications,
  };
};

export const fetchNotificationsFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payLoad: error,
  };
};

export const getNotifications = (token) => {
  return (dispatch) => {
    dispatch(fetchNotificationsRequest());
    axios
      .get(`${SERVER_URL}/api/v1/notifications`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const notifications = response.data.data.notifications;

        dispatch(fetchNotificationsSuccess(notifications));
      })
      .catch((error) => {
        dispatch(fetchNotificationsFailure(error.response));
      });
  };
};

export const clearErrorMessage = () => {
  return {
    type: CLEAR_ERROR_MESSAGE,
  };
};

export const isRead = (id) => {
  return {
    type: IS_READ,
    payLoad: id,
  };
};

export const isReadServer = (token, id) => {
  return (dispatch) => {
    dispatch(isRead(id));
    axios
      .patch(
        `${SERVER_URL}/api/v1/notifications/${id}`,
        { isRead: true },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((error) => {
        console.log(error.response);
      });
  };
};
