export const sendDesktopNotification = (title, msg) => {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(`${title}`, {
        body: msg,
        icon: '',
        vibrate: [200, 100, 200],
      });

      notification.addEventListener('click', () => {
        notification.close();
      });
    } else {
      if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {});
      }
    }
  }
};

export const requestDesktopNotificationPermission = () => {
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {});
  }
};

export const handleSocketReconnect = (socket, user, board) => {
  socket.connect();
  socket.emit('newUser', user._id);
  socket.emit('join-board', { boardId: board.id, user: user._id });
};
