import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ChatIcon } from '../../assets/svg/iconlibrary';
import { StreamChat } from 'stream-chat';

import { openChatModal, clearUserChatData, setIsNewMsg } from '../../redux';
import { sendDesktopNotification } from '../../utils';
import audio from '../../assets/sound/msg-notify.wav';

import './chatButton.css';

const clientUser = StreamChat.getInstance(
  process.env.REACT_APP_STREAM_API_KEY,
  process.env.REACT_APP_STREAM_API_SECRET
);

const notifySound = new Audio(audio);

const ChatButton = ({
  clearChatNotifyState,
  openChats,
  channelInUse,
  user,
  chatData,
  toggleChatIndicator,
  isNewMsg,
  isChatModalOpen,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const newNotification = clientUser.on('message.new', (event) => {
      const { text } = event.message;
      const { user: msgUser } = event;

      if (event.user.id !== user._id) {
        sendDesktopNotification(
          `${msgUser.firstName + ' ' + msgUser.lastName}`,
          text
        );
        notifySound.play();
      }
    });

    const unReadCounter = clientUser.on((event) => {
      if (event.total_unread_count !== undefined) {
        if (event.total_unread_count > 0) {
          if (!isChatModalOpen) {
            setCount(event.total_unread_count);
            toggleChatIndicator(true);
          }
        }
      }
    });

    return () => {
      newNotification.unsubscribe();
      unReadCounter.unsubscribe();
    };
  }, [channelInUse, user._id, toggleChatIndicator, isChatModalOpen]);

  const NotificationIcon = ({ countData }) => (
    <div
      style={{
        backgroundColor: '#03a9f4',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        position: 'fixed',
        right: '13px',
        bottom: '34px',
        fontSize: '14px',
        color: 'black',
      }}
    >
      {countData}
    </div>
  );

  const handleOpenChats = () => {
    openChats();
    clearChatNotifyState();
    toggleChatIndicator(false);
  };

  return (
    <div className="chat-button-container">
      <button className="chat-button" onClick={handleOpenChats}>
        <ChatIcon />
        <span className="chat-button-text">Chat</span>
        {(chatData?.total_unread_count > 0 || isNewMsg) && (
          <NotificationIcon countData={count || chatData?.total_unread_count} />
        )}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    notify: state.chat.notify,
    modalType: state.modal.modalType,
    channelInUse: state.chat.activeChannel,
    chatData: state.chat.userChatData,
    isNewMsg: state.chat.isNewMsg,
    isChatModalOpen: state.chat.chatModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openChats: () => dispatch(openChatModal()),
    clearChatNotifyState: () => dispatch(clearUserChatData()),
    toggleChatIndicator: (bool) => dispatch(setIsNewMsg(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatButton);
