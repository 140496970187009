import React from "react";

import { LoadingIcon } from "../../assets/svg/iconlibrary";

import "./LoadingScreen.css";

const LoadingScreen = ({ message }) => {
  return (
    <div className="loading-screen-container">
      <div className="loading-message-container">
        <LoadingIcon />
        <span className="loading-text">{message}</span>
        <div className="loading-dots"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
