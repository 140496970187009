import React, { useState } from "react";
import { LoadingIcon, KevvlarIcon } from "../../assets/svg/iconlibrary";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
  handleForgotPassword,
  handleResendVerificationToken,
} from "../../redux";

const ForgotPasswordPage = ({
  error,
  loading,
  forgotPassword,
  sendToken,
  history,
}) => {
  const [email, setEmail] = useState("");
  const [localError, setLocalError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ email: email }, history);
    setEmail("");
  };

  const handleResend = () => {
    if (email && email !== "") {
      sendToken({ email: email });
    } else {
      setLocalError("Please provide an email");
    }
  };

  const handleChange = (e) => {
    if (localError) {
      setLocalError("");
    }
    setEmail(e.target.value);
  };

  return (
    <div className="signup-container">
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="signup-content">
          <KevvlarIcon />
          <h2 className="signup-white">Forgot Password</h2>
          {error && <div className="sign-in-error">{error}</div>}
          {localError && <div className="sign-in-error">{localError}</div>}
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="signup-form-group">
              <input
                type="email"
                className="signup-form-input"
                name="email"
                placeholder="Enter email"
                value={email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="signup-form-group">
              <button type="submit" className="signup-form-submit">
                Submit
              </button>
            </div>
          </form>
          <p className="info-color">
            Didn't get an email
            <span onClick={handleResend} className="verify-email-link">
              {" "}
              click here!
            </span>
          </p>
          <p className="verify-email-link">
            <Link to="/signin" className="verify-email-link">
              login
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.auth.authEmail,
    error: state.auth.error,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (details, routing) =>
      dispatch(handleForgotPassword(details, routing)),
    sendToken: (details) => dispatch(handleResendVerificationToken(details)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPasswordPage));
