import {
  CREATE_TIMEOUT_FAILURE,
  CREATE_TIMEOUT_REQUEST,
  CREATE_TIMEOUT_SUCCESS,
  GET_USER_TIMEOUT_STATUS_ERROR,
  GET_USER_TIMEOUT_STATUS_REQUEST,
  GET_USER_TIMEOUT_STATUS_SUCCESS,
  RESET_TIMEOUT,
} from './timeoutTypes';

const initialState = {
  timestamp: '',
  timeoutStatus: false,
  error: '',
  loading: false,
};

const timeoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TIMEOUT_STATUS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_USER_TIMEOUT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeoutStatus: action.payLoad.status,
        timestamp: action.payLoad.time,
      };
    case GET_USER_TIMEOUT_STATUS_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payLoad,
      };

    case CREATE_TIMEOUT_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case CREATE_TIMEOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        timeoutStatus: true,
        timestamp: new Date(),
      };

    case CREATE_TIMEOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case RESET_TIMEOUT:
      return {
        ...state,
        timestamp: '',
        timeoutStatus: false,
      };

    default:
      return state;
  }
};

export default timeoutReducer;
