import React from 'react';
import { connect } from 'react-redux';

import './userAvatar.css';

import { setUserModal, getUserEmail, toggleSocketModal } from '../../redux';

import socket from '../../Socket';

const UserAvatar = ({ user, showUserModal, setUserEmail, showSocketModal }) => (
  <div
    className="user-avatar-container"
    onClick={() => {
      if (!socket || !socket.connected) {
        showSocketModal(true);
        return;
      }
      setUserEmail(user.email);
      showUserModal('REMOVE');
    }}
  >
    <img
      className="user-avatar-image"
      alt="img"
      src={user.photo}
      title={user.firstName}
    />
  </div>
);

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    selectBoard: state.board.selectBoard,
    currentUserId: state.user.userData._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showUserModal: (type) => dispatch(setUserModal(type)),
    setUserEmail: (email) => dispatch(getUserEmail(email)),
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
