import React from "react";
import { connect } from "react-redux";

import { removeUserFromTeam } from "../../redux";

const TeamUserItemView = ({ user, userItem, teamId, removeUser, updating }) => {
  const handleRemoveUser = () => {
    removeUser(
      user.token,
      {
        teamId,
        userEmail: userItem.email,
      },
      { teamId }
    );
  };

  return (
    <div className="manage-users-holder">
      <div className="assign-user-list-container">
        <img
          className="user-avatar-image assign-user-image"
          alt="img"
          src={userItem.photo}
          title={`${userItem.firstName} ${userItem.lastName}`}
        />
        <div>
          <div className="assign-user-list-name">{`${userItem.firstName} ${userItem.lastName}`}</div>
          <div className="assign-user-list-email">{`${userItem.email} - ${userItem.plan.title} Plan`}</div>
        </div>
      </div>
      <button
        className="delete-button"
        style={{ margin: "0px" }}
        onClick={handleRemoveUser}
        disabled={updating}
      >
        {updating ? "Removing User" : "Remove User"}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teamId: state.team.selectTeam._id,
    user: state.user.userData,
    updating: state.team.updating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeUser: (token, userObj, params) =>
      dispatch(removeUserFromTeam(token, userObj, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserItemView);
