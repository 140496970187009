import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import SubscriptionInfo from './SubscriptionInfo';

import { closeModal, setInfoModal, setSelectPlan } from '../../../redux';

import './subscriptionModal.css';

const stripePromise = loadStripe(
  'pk_live_51Mvm6tBDlwLcpDtHxaKMM4b1oRYgnUfa7TMtB41waKB8CpvD1J5oJ2Tpp20FxE0dom7dSodmJy3AXih9ZhYiJubN00RYmbxKNk'
);
// pk_test_51Mvm6tBDlwLcpDtHVp0LHwjavfhaueU0A4VZETiMVoAmIZQ1lwsVZVaugvFAK2aHqny3hjrI77owsoEQKarzJ40300reeQdvHs
const SubscriptionModal = ({ planData, closeModal }) => {
  const [successful, setSuccessful] = useState(false);

  const options = {
    clientSecret: planData.clientSecret,
    appearance: {
      theme: 'night',
      labels: 'floating',
    },
  };

  return (
    <>
      {successful ? (
        <SubscriptionInfo />
      ) : (
        <div className="subscription-modal">
          <div className="close-icon-container">
            <FaTimes onClick={closeModal} className="close-icon" />
          </div>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm setSuccessful={setSuccessful} />
          </Elements>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    planData: state.planSubscription.selectPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    showInfoModal: (message) => dispatch(setInfoModal(message)),
    selectPlan: (planData) => dispatch(setSelectPlan(planData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);
