import React from "react";
import hero from "../../assets/kevvlar-hero.png";
import heromeetings from "../../assets/hero-meetings.png";
import herochat from "../../assets/hero-chat.png";
import features from "../../assets/all-tools.svg";
import manage from "../../assets/manage.png";
import figma from "../../assets/figma.svg";
import allapps from "../../assets/allapps.png";
import layerone from "../../assets/layerone.png";
import layertwo from "../../assets/layertwo.png";
import { KevvlarLogo } from "../../assets/svg/iconlibrary";
import { AiFillCheckCircle } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import {
  VideoIcon,
  ChatIconWhite,
  BoardsIconWhite,
} from "../../assets/svg/iconlibrary";
import { Link } from "react-router-dom";
import SavingsCalculator from './SavingsCalculator';

// import NavBar from "../../components/navbar/NavBar";
// import Directory from "../../components/directory/Directory";
import "animate.css";

import "./homePage.css";
import "./stars.css";

const HomePage = () => (
  <div className="homepage">
    <div className="stars">
      <div className="small"></div>
    </div>
    {/* <div className="bg-effect"></div> */}
    {/* top navigation */}
    <div className="homepage-nav">
      <div className="homepage-logo">
        <KevvlarLogo />
      </div>
      <div className="homepage-nav-holder">
        <div
          className="homepage-nav-btns"
          onClick={() => {
            document.getElementById("features").scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }}
        >
          Features
        </div>
        <div
          className="homepage-nav-btns"
          onClick={() => {
            document.getElementById("pricing").scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }}
        >
          Pricing
        </div>
        <Link to="/signin" className="greeting-buttons-container">
          <div className="homepage-nav-btns">Login</div>
        </Link>
        <Link
          to={{
            pathname: "/signup",
            state: { entry: "nav-cta" },
          }}
          className="greeting-buttons-container"
        >
          <div className="homepage-nav-btns">Free Trial</div>
        </Link>
      </div>
    </div>
    {/* hero section */}
    <div className="hero-section">
      <div className="hero-heading fade">
        The Only Project Management Tool You Will Ever Need
      </div>
      <div className="hero-helper-text fade">
        Onboard team members with one single invite for blazing fast efficiency
        and never having to worry about role access again.
      </div>
      <Link
        to={{
          pathname: "/signup",
          state: { entry: "hero-cta" },
        }}
        className="greeting-buttons-container fade"
      >
        <button className="modal-board-button free-trial">Free Trial</button>
      </Link>
      <a className="fade fade-last" href="https://www.producthunt.com/posts/kevvlar?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-kevvlar" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=414383&theme=light" alt="Kevvlar - An&#0032;all&#0045;in&#0045;one&#0032;project&#0032;management&#0032;tool&#0032;focused&#0032;around&#0032;UX | Product Hunt" style={{marginBottom: '15px',     marginTop: '15px'}} /></a>
      <div className="hero-holder">
        <img
          src={hero}
          className="hero-image"
          alt="Hero section"
        ></img>
        <img src={heromeetings} className="fade-slower heromeetings" alt="" />
        <img src={herochat} className="fade-slower herochat" alt="" />
        <img src={layerone} alt="" className="layerone" />
        <img src={layertwo} alt="" className="layertwo" />
      </div>
    </div>
    {/* three helper section */}
    <div className="helper-section" id="features">
      <div className="helper-section-group">
        <div className="helper-icon">
          <BoardsIconWhite />
        </div>
        <div className="helper-title">Kanban Board</div>
        <div className="helper-description">
          Organize your project with drag and drop for maximum efficiency
        </div>
      </div>
      <div className="helper-section-group">
        <div className="helper-icon">
          <VideoIcon />
        </div>
        <div className="helper-title">Meetings</div>
        <div className="helper-description">
          One click gets you into a video meeting for the project you already
          have open
        </div>
      </div>
      <div className="helper-section-group">
        <div className="helper-icon">
          <ChatIconWhite />
        </div>
        <div className="helper-title">Real-time Chat</div>
        <div className="helper-description">
          Global live chat to communicate with your team
        </div>
      </div>
    </div>
    {/* Calclulater section */}
    <SavingsCalculator />
    {/* <div className="calc-section">
      <div className="showcase-title">Savings Calculator</div>
      <div className="calc-holder">
        <div className="input-section">
          <div className="input-title">Select Tools You Currently Use</div>
          <div className="tool-holder">
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
            <div className="tool-selection"></div>
          </div>
          <div className="input-title">Select Your Team Size</div>
          <div className="team-selection">0 - 5</div>
          <div className="team-selection">6 - 15</div>
          <div className="team-selection">16 - 25</div>
          <div className="team-selection">26+</div>
          <div className="showcase-link">Calculate</div>
        </div>  
        <div className="results-section">
          <div className="input-title">How Much You Currently Spend</div>
          <div className="results">$15,000/year</div>
          <div className="input-title">How Much You Could Save</div>
          <div className="results">$5,000/year</div>
        </div> 
      </div> 
    </div> */}
    {/* feature showcase section */}
    <div className="showcase-section">
      <div className="showcase-group">
        <div className="showcase-text-holder">
          <div className="overline">Meetings, Chat, File Sharing</div>
          <div className="showcase-title">All Your Tools, All in One Place</div>
          <div className="showcase-content">
            We have perfected the project management user experience by having
            all the necessary features to organize your team - meetings, cards,
            and live chat - all just one click away.
          </div>
          <div className="cta-holder">
            <Link
              to={{
                pathname: "/signup",
                state: { entry: "tool-cta" },
              }}
            >
              <div className="showcase-link">Try Now</div>
            </Link>
            <div
              className="homepage-nav-btns"
              onClick={() => {
                document.getElementById("access").scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                  inline: "start",
                });
              }}
            >
              I'm Not Convinced
            </div>
          </div>
        </div>
        <img
          src={features}
          className="showcase-img-holder"
          alt="Dislays apps features"
        />
      </div>
    </div>

    <div className="showcase-section" id="access">
      <div className="showcase-group reverse">
        <div className="showcase-text-holder">
          <div className="overline">Control Access</div>
          <div className="showcase-title">
            Give Access With Just One Click. Remove It Just As Fast.
          </div>
          <div className="showcase-content">
            Never have to worry about access control again because all of our
            powerful features are attached to the project. When you remove a
            user, they no longer have access to any meetings, files, pins, or
            searching for that project board.
          </div>
          <div className="cta-holder">
            <Link
              to={{
                pathname: "/signup",
                state: { entry: "access-cta" },
              }}
            >
              <div className="showcase-link">Try Now</div>
            </Link>
            <div
              className="homepage-nav-btns"
              onClick={() => {
                document.getElementById("juggle").scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                  inline: "start",
                });
              }}
            >
              Hm Not Quite.
            </div>
          </div>
        </div>
        <img
          src={manage}
          className="showcase-img-holder"
          alt="shows app feature"
        />
      </div>
    </div>

    <div className="showcase-section" id="juggle">
      <div className="showcase-group">
        <div className="showcase-text-holder">
          <div className="overline">Get Organized Seamlessly</div>
          <div className="showcase-title">Never Juggle 4+ Apps Again</div>
          <div className="showcase-content">
            We get how annoying it is to wonder where files are kept, which app
            the next meeting is in, or which team member is doing what - so we
            built an app to solve that.
          </div>
          <div className="cta-holder">
            <Link
              to={{
                pathname: "/signup",
                state: { entry: "apps-cta" },
              }}
            >
              <div className="showcase-link">Try Now</div>
            </Link>
            <div
              className="homepage-nav-btns"
              onClick={() => {
                document.getElementById("figma").scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                  inline: "start",
                });
              }}
            >
              Tell Me More...
            </div>
          </div>
        </div>
        <img
          src={allapps}
          className="showcase-img-holder"
          alt="shows app feature"
        />
      </div>
    </div>

    <div className="showcase-section" id="figma">
      <div className="showcase-group reverse">
        <div className="showcase-text-holder">
          <div className="overline">Integrations</div>
          <div className="showcase-title">Figma Integration</div>
          <div className="showcase-content">
            You can paste a Figma embed code straight into a card to show your
            prototypes to your team, with live updates.
          </div>
          <div className="cta-holder">
            <Link
              to={{
                pathname: "/signup",
                state: { entry: "figma-cta" },
              }}
            >
              <div className="showcase-link">Try Now</div>
            </Link>
            <Link
              to={{
                pathname: "/signup",
                state: { entry: "figma-helper-cta" },
              }}
            >
              <div className="homepage-nav-btns">Alright, I'll Try It!</div>
            </Link>
          </div>
        </div>
        <img
          src={figma}
          className="showcase-img-holder"
          alt="shows app feature"
        />
      </div>
    </div>

    <div className="showcase-section">
      <div className="pricing-title-holder">
        <div className="showcase-title" id="pricing">Pricing</div>
      </div>
      <div className="pricing-holder">
        <div className="pricing-card">
          <div className="pricing-plan-name">Basic</div>
          <div className="pricing-cost">Free</div>
          <div className="pricing-features-holder">
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">2 Boards</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">1 Chat Channel</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">2 File Uploads/board</div>
            </div>
            <div className="pricing-feature">
              <CiCircleRemove className="x-red" />
              <div className="pricing-feature-name">No Video Conferencing</div>
            </div>
          </div>
          <Link
            to={{
              pathname: "/signup",
              state: { entry: "price-basic-cta" },
            }}
            className="greeting-buttons-container"
          >
            <button className="modal-board-button free-trial">
              Free Trial
            </button>
          </Link>
        </div>
        <div className="pricing-card">
          <div className="pricing-plan-name">Premium</div>
          <div className="pricing-cost">$15 / mo.</div>
          <div className="pricing-features-holder">
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">50 Boards</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">100 Chat Channels</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">25 File Uploads/board</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">3 Invited Trial Users</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">Video Conferencing</div>
            </div>
            <div className="pricing-feature">
              <CiCircleRemove className="x-red" />
              <div className="pricing-feature-name">No Teams</div>
            </div>
          </div>
          <Link
            to={{
              pathname: "/signup",
              state: { entry: "price-premium-cta" },
            }}
            className="greeting-buttons-container"
          >
            <button className="modal-board-button free-trial">
              Free Trial
            </button>
          </Link>
        </div>
        <div className="pricing-card">
          <div className="pricing-plan-name">Teams</div>
          <div className="pricing-cost">$500 / mo.</div>
          <div className="pricing-features-holder">
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">500 Boards</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">Unlimited Chat Channels</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">100 File Uploads/board</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">Video Conferencing</div>
            </div>
            <div className="pricing-feature">
              <AiFillCheckCircle className="checkmark-green" />
              <div className="pricing-feature-name">Create Teams (25 users)</div>
            </div>
          </div>
          <Link
            to={{
              pathname: "/signup",
              state: { entry: "price-teams-cta" },
            }}
            className="greeting-buttons-container"
          >
            <button className="modal-board-button free-trial">
              Free Trial
            </button>
          </Link>
        </div>
      </div>
    </div>

    {/* cta section */}
    <div className="cta-section">
      <div className="cta-text">Ready To Experience Next-Level Efficiency?</div>
      <Link
        to={{
          pathname: "/signup",
          state: { entry: "footer-cta" },
        }}
      >
        <div className="cta-btn">Free Trial</div>
      </Link>
    </div>
    {/* cta section */}
    <div className="footer-section">
      <div className="footer-nav">
        <div className="homepage-logo">
          <KevvlarLogo />
        </div>
        <div className="homepage-nav-holder">
          <div className="homepage-nav-btns">
            <a href="https://kevvlar.com/privacy" target="_blank">Privacy Policy</a>
          </div>
          <div className="homepage-nav-btns">
            <a href="https://kevvlar.com/terms" target="_blank">Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
