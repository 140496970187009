import React from 'react';
import { Popup } from '@mobiscroll/react';
import moment from 'moment';

import ViewUserList from '../UserList/ViewUserList';

const responsivePopup = {
  medium: {
    display: 'anchored',
    width: 400,
    fullScreen: false,
    touchUi: false,
  },
};

const ViewEventPopup = ({ event, anchor, isPopOverOpen, onClose }) => {
  const showStartDate = () => {
    if (event?.start.m) {
      return `${moment(event?.start.m).format('MMM Do, YYYY')} at ${moment(
        event?.start.m
      ).format('H:mm A')}`;
    } else {
      return `${moment(event?.start).format('MMM Do, YYYY')} at ${moment(
        event?.start
      ).format('H:mm A')}`;
    }
  };

  const showEndDate = () => {
    if (event?.end.m) {
      return `${moment(event?.end.m).format('MMM Do, YYYY')} at ${moment(
        event?.end.m
      ).format('H:mm A')}`;
    } else {
      return `${moment(event?.end).format('MMM Do, YYYY')} at ${moment(
        event?.end
      ).format('H:mm A')}`;
    }
  };

  return (
    <Popup
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      headerText={'Event Details'}
      anchor={anchor}
      isOpen={isPopOverOpen}
      onClose={onClose}
      responsive={responsivePopup}
      theme="material"
    >
      <div className="mbsc-padding">
        <div className="view-event-container">
          <label className="view-event-header">Title:</label>
          <div className="view-event">{event?.title}</div>
        </div>
        <div className="view-event-container">
          <label className="view-event-header">Description:</label>
          <div className="view-event">{event?.description}</div>
        </div>
        <ViewUserList userList={event?.users} />
        <div className="event-details-date-container">
          <div>Starts</div>
          <div>{showStartDate()}</div>
        </div>
        <div className="event-details-date-container">
          <div>Ends</div>
          <div>{showEndDate()}</div>
        </div>
      </div>
    </Popup>
  );
};

export default ViewEventPopup;
