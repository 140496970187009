import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  setSelectPlan,
  setSubscribeToPlanModal,
  closeModal,
  setErrorModal,
  setCancelSubscriptionModal,
} from '../../../redux';
import SERVER_URL from '../../../redux/URL';

import BasicCard from './BasicCard';
import PremiumCard from './PremiumCard';
import TeamCard from './TeamCard';

import './pricingModal.css';

const PricingModal = ({
  selectPlan,
  showShowSubcribeModal,
  closeModal,
  user,
  showErrorModal,
  showCancelSubscriptionModal,
}) => {
  const [loadingObj, setLoadingObj] = useState({
    title: '',
    loading: false,
  });

  const handleSuccess = (amount, title, lookupKey, priceId, result) => {
    setLoadingObj({
      title: '',
      loading: false,
    });
    selectPlan({
      price: amount,
      title,
      interval: 'Monthly',
      lookup_key: lookupKey,
      priceId,
      clientSecret: result.data.clientSecret,
    });
    closeModal();
    showShowSubcribeModal();
  };

  const createPaymentIntent = (amount, priceId, lookupKey, title) => {
    setLoadingObj({
      title,
      loading: true,
    });
    axios
      .post(
        `${SERVER_URL}/api/v1/users/create-customer-subscription`,
        {
          priceId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((result) => {
        handleSuccess(amount, title, lookupKey, priceId, result);
      })
      .catch((error) => {
        setLoadingObj({
          title: '',
          loading: false,
        });
        showErrorModal('Could not initialize payment process.');
      });
  };

  return (
    <div className="pricing-modal">
      <h2 className="modal-title">Pricing Plan</h2>
      <div>
        <div className="pricing-holder">
          <BasicCard />
          <PremiumCard
            showCancelSubscriptionModal={showCancelSubscriptionModal}
            createPaymentIntent={createPaymentIntent}
            loadingObj={loadingObj}
            user={user}
          />
          <TeamCard
            showCancelSubscriptionModal={showCancelSubscriptionModal}
            createPaymentIntent={createPaymentIntent}
            loadingObj={loadingObj}
            user={user}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectPlan: (planData) => dispatch(setSelectPlan(planData)),
    showShowSubcribeModal: () => dispatch(setSubscribeToPlanModal()),
    closeModal: () => dispatch(closeModal()),
    showErrorModal: (message) => dispatch(setErrorModal(message)),
    showCancelSubscriptionModal: () => dispatch(setCancelSubscriptionModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingModal);
