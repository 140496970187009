import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { toggleSocketModal, fetchBoard, fetchColumns } from '../../../redux';

import socket from '../../../Socket';

import '../modal.css';

const SocketInfoModal = ({
  info,
  user,
  board,
  showSocketModal,
  getBoard,
  getColumns,
  history,
}) => {
  const handleReconnect = useCallback(() => {
    if (socket.connected) {
      showSocketModal(false);
      return;
    }

    socket.connect();
    socket.emit('newUser', user._id);
    socket.emit('join-board', { boardId: board.id, user: user._id });
    getBoard(
      user.token,
      board.id,
      {
        boardId: board.id,
      },
      null,
      history
    );
    getColumns(user.token, { boardId: board.id }, history);

    setTimeout(() => {
      if (socket.connected) {
        showSocketModal(false);
      } else {
        handleReconnect();
      }
    }, 3500);
  }, [board.id, user._id, showSocketModal]);

  useEffect(() => {
    handleReconnect();
  }, [handleReconnect]);

  return (
    <div className="modal-wrapper">
      <div className="modal-error">
        <div className="close-icon-container">
          <div className="modal-body">
            <h2 className="modal-title">Information</h2>
            <p className="modal-main-info">{info}</p>
            {/* <button className="modal-board-button" onClick={handleReconnect}>
              {isConnecting ? 'Reconnecting' : 'Reconnect'}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    board: state.board.selectBoard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
    getBoard: (token, boardId, params, isMeeting, routing) =>
      dispatch(fetchBoard(token, boardId, params, isMeeting, routing)),
    getColumns: (token, params, history) =>
      dispatch(fetchColumns(token, params, history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SocketInfoModal));
