import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { KevvlarIcon, LoadingIcon } from "../../assets/svg/iconlibrary";

import { handleResetPassword } from "../../redux";

const ResetPasswordPage = ({ error, loading, history, resetPassword }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [token, setToken] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(
      {
        passwordResetToken: token,
        password: newPassword,
        passwordConfirm: confirmNewPassword,
      },
      history
    );
  };

  const handleResend = () => {
    history.push("/forgot-password");
  };

  return (
    <div className="signup-container">
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="signup-content">
          <KevvlarIcon />
          <h2 className="signup-white">Forgot Password</h2>
          {error && <div className="sign-in-error">{error}</div>}

          <form onSubmit={handleSubmit} className="signup-form">
            <div className="signup-form-group">
              <input
                type="text"
                className="signup-form-input"
                name="token"
                placeholder="Enter token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="password"
                className="signup-form-input"
                name="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="password"
                className="signup-form-input"
                name="confirmNewPassword"
                placeholder="Confirm new password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </div>

            <div className="signup-form-group">
              <button type="submit" className="signup-form-submit">
                Reset
              </button>
            </div>
          </form>
          <p className="info-color">
            Didn't get an email
            <span onClick={handleResend} className="verify-email-link">
              {" "}
              click here!
            </span>
          </p>
          <p className="verify-email-link">
            <Link to="/signin" className="verify-email-link">
              login
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.auth.authEmail,
    error: state.auth.error,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (details, routing) =>
      dispatch(handleResetPassword(details, routing)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPasswordPage));
