import React from 'react';
import { connect } from 'react-redux';

import './assignedUsers.css';

const AssignedUsers = ({ assignedUsers, admins, members }) => {
  let users = [];

  if (admins?.length > 0) {
    users = [...users, ...admins];
  }

  if (members?.length > 0) {
    users = [...users, ...members];
  }

  const assignedUserObjList = users.filter((user) =>
    assignedUsers.includes(user._id)
  );

  return (
    <div className="assigned-user-container">
      {assignedUserObjList?.map((user, index) => {
        return (
          <div className="admin-avatar-container" key={index}>
            <img
              className="admin-avatar-image"
              alt="img"
              src={user?.photo}
              title={user?.firstName + ' ' + user?.lastName}
            />
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    admins: state.board.selectBoard?.admins,
    members: state.board.selectBoard?.members,
  };
};

export default connect(mapStateToProps, null)(AssignedUsers);
