import React from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { closeModal } from "../../../redux";

const InfoModal = ({ info, closeModal }) => {
  return (
    <div className="modal-error">
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        <div className="modal-body">
          <h2 className="modal-title">Information</h2>
          <p>{info}</p>
          <button className="modal-error-button" onClick={closeModal}>
            close
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    info: state.modal.infoMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InfoModal));
