import React from "react";
import { FaSearch } from "react-icons/fa";

import "./searchBar.css";

const TeamSearchBar = ({ setSearchKeyword }) => (
  <div className="search-bar-container">
    <FaSearch className="cardsearch-icon" />
    <input
      type="text"
      placeholder="Search"
      className="cards-search"
      onChange={(e) =>
        setSearchKeyword(e.target.value.toLowerCase().replaceAll(/\s/g, ""))
      }
    />
  </div>
);

export default TeamSearchBar;
