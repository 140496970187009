import React, { useState, useRef } from 'react';

import '../modal/card-modal/cardModal.css';

const ColorLabelDropDown = ({ setCardLabel, editCardColor = '' }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const redRef = useRef(null);
  const greenRef = useRef(null);
  const yellowRef = useRef(null);
  const greyRef = useRef(null);

  const handleToggleColor = (color) => {
    if (color === 'red') {
      if (redRef.current.checked) {
        redRef.current.checked = false;
        setCardLabel('');
      } else if (!redRef.current.checked) {
        redRef.current.checked = true;
        setCardLabel(redRef.current.value);
      }
    } else if (color === 'yellow') {
      if (yellowRef.current.checked) {
        yellowRef.current.checked = false;
        setCardLabel('');
      } else if (!yellowRef.current.checked) {
        yellowRef.current.checked = true;
        setCardLabel(yellowRef.current.value);
      }
    } else if (color === 'grey') {
      if (greyRef.current.checked) {
        greyRef.current.checked = false;
        setCardLabel('');
      } else if (!greyRef.current.checked) {
        greyRef.current.checked = true;
        setCardLabel(greyRef.current.value);
      }
    } else if (color === 'green') {
      if (greenRef.current.checked) {
        greenRef.current.checked = false;
        setCardLabel('');
      } else if (!greenRef.current.checked) {
        greenRef.current.checked = true;
        setCardLabel(greenRef.current.value);
      }
    }
  };

  const showCheckedColor = () => {
    if (editCardColor) {
      return (
        <>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('red')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Red</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#E34B4B"
                ref={redRef}
                checked={editCardColor === '#E34B4B'}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('yellow')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Yellow</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#F8BE7A"
                ref={yellowRef}
                checked={editCardColor === '#F8BE7A'}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('grey')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Grey</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#B0B0B0"
                ref={greyRef}
                checked={editCardColor === '#B0B0B0'}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('green')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Green</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#389D59"
                ref={greenRef}
                checked={editCardColor === '#389D59'}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('red')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Red</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#E34B4B"
                ref={redRef}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('yellow')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Yellow</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#F8BE7A"
                ref={yellowRef}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('grey')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Grey</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#B0B0B0"
                ref={greyRef}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
          <li
            className="assign-user-list-item color-picker-item bottom-spacing"
            onClick={() => handleToggleColor('green')}
          >
            <div className="assign-user-list-container">
              <div className="assign-user-list-name">Green</div>
            </div>
            <label>
              <input
                type="radio"
                name="color"
                value="#389D59"
                ref={greenRef}
                onChange={(e) => setCardLabel(e.target.value)}
              />
            </label>
          </li>
        </>
      );
    }
  };

  return (
    <div
      className={`drop-down-button ${showColorPicker ? 'is-active' : ''}`}
      onClick={() => {
        setShowColorPicker(!showColorPicker);
      }}
    >
      <div>Color Label</div>
      <div
        className={`assign-user-wrapper ${
          showColorPicker ? 'active-wrapper' : ''
        }`}
        onClick={() => {
          setShowColorPicker(!showColorPicker);
        }}
      ></div>
      <ul className="color-picker-dropdown-list">
        <h2 className="modal-title">Select Color</h2>
        {showCheckedColor()}
      </ul>
    </div>
  );
};

export default ColorLabelDropDown;
