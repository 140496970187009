import React, { useEffect, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import { connect } from "react-redux";

import { setErrorModal } from "../../redux";

const ChannelUsers = ({ currentUser, showErrorModal }) => {
  const { channel } = useChatContext();
  const [users, setUsers] = useState([]);

  const UserItem = ({ user }) => {
    const handleRemoveUser = async () => {
      try {
        await channel.removeMembers([user.user_id]);
        getChannelMembers();
      } catch (error) {
        showErrorModal("Sorry you do not have rights to perform this action.");
      }
    };

    return (
      <div className="user-item__wrapper" onClick={handleRemoveUser}>
        <div className="user-item__name-wrapper">
          <Avatar image={user.user.image} name={user.user.name} size={32} />
          <p className="user-item__name">{user.user.name}</p>
        </div>
      </div>
    );
  };

  const getChannelMembers = async () => {
    try {
      let sort = { created_at: -1 };
      const usersList = await channel.queryMembers({}, sort, {});

      if (usersList.members.length) {
        const mainUserList = usersList.members.filter(
          (userItem) => userItem.user_id !== currentUser._id
        );
        setUsers(mainUserList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChannelMembers();
  }, []);

  return (
    <div>
      <div className="user-list__header">
        <p>Remove User</p>
      </div>
      {users.map((user) => (
        <UserItem user={user} />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorModal: (msg) => dispatch(setErrorModal(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelUsers);
