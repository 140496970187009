export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_FAILURE = "RESEND_VERIFICATION_FAILURE";

export const SET_AUTH_EMAIL = "SET_AUTH_EMAIL";

export const CLEAR_AUTH = "CLEAR_AUTH";
