import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { nanoid } from "nanoid";

import { closeModal, createColumnServer } from "../../../redux";

const AddColumnModal = ({
  closeModal,
  user,
  boardId,
  teamId,
  addNewColumnServer,
  isLoading,
}) => {
  const [columnTitle, setColumnTitle] = useState("");

  const handleSubmit = () => {
    const columnObj = {
      id: nanoid(7),
      boardId: boardId,
      title: columnTitle,
      cards: [],
      cardsOrder: [],
    };
    addNewColumnServer(user.token, { boardId, teamId }, columnObj);
    setColumnTitle("");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="modal-board"
    >
      <div className="close-icon-container">
        <FaTimes
          onClick={() => {
            closeModal();
          }}
          className="close-icon"
        />
        <div className="modal-body">
          <h2 className="modal-title">Add New Column</h2>
          <input
            className="modal-board-text"
            type="text"
            placeholder="New Column"
            value={columnTitle}
            onChange={(e) => setColumnTitle(e.target.value)}
            autoFocus
            required
          />

          <button className="modal-board-button" type="submit">
            {isLoading ? "Saving" : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    boardId: state.board.selectBoard.id,
    isLoading: state.column.loading,
    teamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    addNewColumnServer: (token, params, columnObj) =>
      dispatch(createColumnServer(token, params, columnObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddColumnModal);
