import React from "react";
import { Avatar } from "stream-chat-react";
import { connect } from "react-redux";

import { setActiveChannel } from "../../redux";

const PreviewItem = ({ filteredUser, setChannelInUse }) => {
  const OnlineIndicator = () => (
    <div
      style={{
        backgroundColor: "#3BA55D",
        borderRadius: "50%",
        width: "8px",
        height: "8px",
        position: "absolute",
        left: "15px",
        bottom: "0",
        color: "black",
      }}
    />
  );

  return (
    <div
      className="channel-preview__item single"
      onClick={() => {
        setChannelInUse(filteredUser.user_id);
      }}
    >
      <span style={{ position: "relative" }}>
        <Avatar
          image={filteredUser?.user?.image}
          name={filteredUser?.user?.name}
          size={24}
        />
        {filteredUser.user.online && <OnlineIndicator />}
      </span>
      <p>{filteredUser?.user.name}</p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChannelInUse: (channel) => dispatch(setActiveChannel(channel)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewItem);
