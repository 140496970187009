import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';
// import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';
import parse from 'html-react-parser';

// ${match.url}

import {
  setCardModal,
  setCurrentColumnData,
  setCurrentCardData,
  resetIsMe,
  clearCardSearchKey,
} from '../../redux/index';

import './card.css';

const Card = ({
  card,
  index,
  isGrid,
  editCardModal,
  column,
  getColumnData,
  getCardData,
  currentCard,
  boardId,
  user,
  isMe,
  searchKeyWord,
  admins,
  members,
  isDragging,
  cardsInUse,
  match,
  history,
}) => {
  const currentDescription = card?.description;
  const boardUsers = [...admins, ...members];
  const [highlight, setHighlight] = useState();
  const [isInUse, setIsInUse] = useState(false);

  useEffect(() => {
    if (cardsInUse.some((dragData) => dragData.id === card.id)) {
      setIsInUse(true);
    } else {
      setIsInUse(false);
    }
  }, [cardsInUse, card.id]);

  // get all users in the board assigned to a card.
  const cardUsers = boardUsers.filter(
    (user) =>
      card?.users?.some((userInCard) => userInCard._id === user?._id) ||
      card?.users?.includes(user?._id)
  );

  const checkIfUserAssignedToCard = useCallback(
    (usersArray) => {
      if (usersArray?.length > 0) {
        if (usersArray?.some((userItem) => userItem._id === user._id)) {
          return true;
        }
      }
    },
    [user]
  );

  const searchForUser = useCallback((usersArray, searchText) => {
    if (usersArray.length > 0) {
      for (let user of usersArray) {
        if (!user.firstName || !user.lastName) return;

        let fullName = user?.firstName + user?.lastName;

        const parsedfullName = fullName.toLowerCase().replaceAll(' ', '');

        if (parsedfullName.includes(searchText)) {
          return true;
        }
      }
    }
  }, []);

  const searchMatch = useCallback(
    (title, description, usersArray) => {
      const parsedTitle = title.toLowerCase().replaceAll(' ', '');
      const parsedDescription = description.toLowerCase().replaceAll(' ', '');
      const parsedSearchKeyWord = searchKeyWord
        .toLocaleLowerCase()
        .replaceAll(' ', '');

      if (
        parsedTitle.includes(parsedSearchKeyWord) ||
        parsedDescription.includes(parsedSearchKeyWord) ||
        searchForUser(usersArray, parsedSearchKeyWord)
      ) {
        return true;
      }
    },
    [searchForUser, searchKeyWord]
  );

  const highlightCard = useCallback(() => {
    if (checkIfUserAssignedToCard(cardUsers) && isMe) {
      setHighlight(true);
    } else if (
      searchMatch(card?.title, card?.description, cardUsers) &&
      searchKeyWord
    ) {
      setHighlight(true);
    } else if (!checkIfUserAssignedToCard(cardUsers) && isMe) {
      setHighlight(false);
    } else if (
      !searchMatch(card?.title, card?.description, cardUsers) &&
      searchKeyWord
    ) {
      setHighlight(false);
    } else if (!isMe && !searchKeyWord) {
      setHighlight();
      setHighlight();
    }
  }, [
    card,
    cardUsers,
    checkIfUserAssignedToCard,
    isMe,
    searchMatch,
    searchKeyWord,
  ]);

  useEffect(() => {
    highlightCard();
  }, [isMe, searchKeyWord, highlightCard]);

  const newDescription = parse(currentDescription, {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === 'iframe') {
        return <span></span>; // remove this from span "//figma iframe"
      }
    },
  });

  return (
    <Draggable
      draggableId={card.id}
      index={index}
      isDragDisabled={isInUse && true}
    >
      {(provided, snapshot) => (
        <div
          className={`
      ${card?.description ? 'card' : 'mini-card'} 
      ${highlight === true && ' highlight-color'}
      ${highlight === false && ' hide-card'}
      ${isDragging && ' highlight-color'}
      ${isInUse && ' not-draggable'}
      ${snapshot.isDragging && ' dragging'}`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onMouseDown={() => {
            if (isInUse) return;
            getCardData(card);
            getColumnData(column);
          }}
          onTouchStart={() => {
            if (isInUse) return;
            getCardData(card);
            getColumnData(column);
          }}
          onClick={() => {
            if (isInUse) return;
            history.push(`${match.url}/${currentCard.id}`);
            getCardData(card);
            getColumnData(column);
            editCardModal('EDIT');
          }}
        >
          <div
            className={`${
              card?.description
                ? 'card-color-label-holder'
                : 'mini-card-color-label-holder'
            }`}
          >
            <div
              style={{ backgroundColor: `${card?.colorLabel}` }}
              className="card-color-label"
            ></div>
          </div>
          <div className="cardlabelholder">
            <div id="card-label" className="card-label"></div>
          </div>
          <div
            className={`${
              card?.description ? 'card-inner' : 'mini-card-inner'
            }`}
          >
            <p
              className={card?.description ? 'card-title' : 'mini-card-title'}
              title={card?.title}
            >
              {card?.title}
            </p>
            {isGrid ? (
              <span>
                {card?.description && (
                  <div className="card-description">{newDescription}</div>
                )}
                <div
                  className={
                    card?.description ? 'card-menu' : 'mini-card-footer'
                  }
                >
                  <p className="card-date">{card?.date}</p>
                  <div className="user-avatar-card-container">
                    {cardUsers.slice(0, 3).map((user, index) => (
                      <img
                        key={index}
                        className="user-avatar-image card-avatar-image"
                        alt="img"
                        src={user.photo}
                        title={user?.firstName + ' ' + user?.lastName}
                      />
                    ))}
                    {cardUsers.length > 3 ? (
                      <div
                        className="card-avatar-more"
                        title={cardUsers.slice(3).map((user) => user.name)}
                      >
                        {'+' + (cardUsers?.length - 3)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </span>
            ) : null}
          </div>
        </div>
      )}
    </Draggable>
  );
};
const mapStateToProps = (state) => {
  return {
    isGrid: state.sideNavRight.isGrid,
    currentCard: state.column.selectCard,
    boardId: state.board.selectBoard.id,
    user: state.user.userData,
    isMe: state.column.isMe,
    searchKeyWord: state.column.cardSearchKeyWord,
    admins: state.board.selectBoard.admins,
    members: state.board.selectBoard.members,
    cardsInUse: state.column.cardsInUse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editCardModal: (type) => dispatch(setCardModal(type)),
    getColumnData: (columnObj) => dispatch(setCurrentColumnData(columnObj)),
    getCardData: (cardObj) => dispatch(setCurrentCardData(cardObj)),
    clearIsMe: () => dispatch(resetIsMe()),
    clearCardSearch: () => dispatch(clearCardSearchKey()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Card));
