import React from "react";

import "./cardLength.css";

const CardLength = ({ titleLength, bodyLength }) => {
  return (
    <div className="character-length-container">
      <span className={titleLength + bodyLength > 1000000 && "size-exceeded"}>
        {titleLength + bodyLength}
      </span>
      /1000000
    </div>
  );
};

export default CardLength;
