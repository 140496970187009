import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import UserDataDropdown from './UserDataDropdown';

const TableData = ({ userData }) => {
  if (!userData) return null;

  const {
    _id,
    firstName,
    lastName,
    email,
    plan,
    createdAt,
    isVerified,
    webEntry,
    status,
    lastActive,
  } = userData;
  const today = moment().format('MM/DD/YYYY');
  const created = moment(createdAt).format('MM/DD/YYYY');
  const currentDate = moment();
  const lastActiveDate = moment(lastActive);
  const daysDifference = currentDate.diff(lastActiveDate, 'days');

  return (
    <div className="admin-users-table-data-item">
      {/* <div className="table-data-cell">
        <img className="admin-avatar-image" alt="img" src={photo} />
      </div> */}
      <div className="table-data-cell">{firstName || 'n/a'}</div>
      <div className="table-data-cell">{lastName || 'n/a'}</div>
      <div className="table-data-cell" title={email}>
        {email}
      </div>
      <div className="table-data-cell">{plan?.title}</div>
      <div className="table-data-cell">{isVerified?.toString()}</div>
      <div className="table-data-cell">{webEntry || 'n/a'}</div>
      <div className="table-data-cell">{status || 'n/a'}</div>
      <div className="table-data-cell created-date">
        {moment(createdAt).format('MM/DD/YYYY h:mm a')}
        {today === created && <div className="new-badge">new</div>}
      </div>
      <div 
        className="table-data-cell created-date"
        style={{ color: daysDifference <= 3 ? 'green' : 'inherit' }}
      >
        {moment(lastActive).format('MM/DD/YYYY h:mm a')}
      </div>
      <div className="table-data-cell">
        <UserDataDropdown userId={_id} status={status} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    updating: state.users.isUpdating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableData);
