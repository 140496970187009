export const TOGGLE_FILE_MODAL = "TOGGLE_FILE_MODAL";

export const FETCH_FILE_SUCCESS = "FETCH_FILE_SUCCESS";
export const FETCH_FILE_FAILURE = "FETCH_FILE_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const CLEAR_FILES = "CLEAR_FILES";
