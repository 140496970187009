import {
  CLOSE_MODAL,
  BOARD_MODAL,
  COLUMN_MODAL,
  CARD_MODAL,
  USER_MODAL,
  CHAT_MODAL,
  PIN_MODAL,
  CONFERENCE_MODAL,
  CALENDAR_MODAL,
  TOGGLE_MINIMIZE,
  CLOSE_CONFERENCE_MODAL,
  ERROR_MODAL,
  INFO_MODAL,
  USERBOARDS_MODAL,
  NOTIFICATION_MODAL,
  CLOSE_NOTIFICATION_MODAL,
  USER_PROFILE,
  PRICING_MODAL,
  TEAM_MODAL,
  TEAM_SETTINGS_MODAL,
  CLOSE_TEAM_SETTINGS_MODAL,
  TEAM_USER_MODAL,
  ADD,
  SUBSCRIBE_PLAN_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
  TOGGLE_SOCKET_MODAL,
  TOGGLE_CARD_MODAL_SAVE_BUTTON,
} from './modalTypes';

const initialState = {
  showModal: false,
  showSocketModal: false,
  modalType: '',
  modalActionType: '',
  conference: '',
  minimize: false,
  notify: false,
  notificationEvent: {},
  errorMessage: '',
  infoMessage: '',
  teamSettings: '',
  isSaveBtn: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_SETTINGS_MODAL:
      return {
        ...state,
        teamSettings: TEAM_SETTINGS_MODAL,
      };

    case TOGGLE_CARD_MODAL_SAVE_BUTTON:
      return {
        ...state,
        isSaveBtn: action.payLoad,
      };

    case TOGGLE_SOCKET_MODAL:
      return {
        ...state,
        showSocketModal: action.payLoad,
      };

    case TEAM_USER_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: TEAM_USER_MODAL,
        modalActionType: ADD,
      };

    case CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: CANCEL_SUBSCRIPTION_MODAL,
      };

    case CLOSE_TEAM_SETTINGS_MODAL:
      return {
        ...state,
        teamSettings: '',
      };

    case CONFERENCE_MODAL:
      return {
        ...state,
        conference: CONFERENCE_MODAL,
      };

    case TOGGLE_MINIMIZE:
      return {
        ...state,
        minimize: !state.minimize,
      };

    case USER_PROFILE:
      return {
        ...state,
        showModal: true,
        modalType: USER_PROFILE,
        modalActionType: action.payLoad,
      };

    case PRICING_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: PRICING_MODAL,
      };

    case SUBSCRIBE_PLAN_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: SUBSCRIBE_PLAN_MODAL,
      };

    case NOTIFICATION_MODAL:
      return {
        ...state,
        notify: true,
        notificationEvent: action.payLoad,
      };

    case BOARD_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: BOARD_MODAL,
        modalActionType: action.payLoad,
      };
    case TEAM_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: TEAM_MODAL,
        modalActionType: action.payLoad,
      };

    case PIN_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: PIN_MODAL,
        modalActionType: action.payLoad,
      };

    case CALENDAR_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: CALENDAR_MODAL,
        modalActionType: action.payLoad,
      };

    case COLUMN_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: COLUMN_MODAL,
        modalActionType: action.payLoad,
      };

    case CARD_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: CARD_MODAL,
        modalActionType: action.payLoad,
      };

    case USER_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: USER_MODAL,
        modalActionType: action.payLoad,
      };

    case USERBOARDS_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: USERBOARDS_MODAL,
        modalActionType: action.payLoad,
      };

    case CHAT_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: CHAT_MODAL,
        modalActionType: action.payLoad,
      };

    case CLOSE_CONFERENCE_MODAL:
      return {
        ...state,
        conference: '',
        minimize: false,
      };

    case CLOSE_NOTIFICATION_MODAL:
      return {
        ...state,
        notify: false,
        notificationEvent: {},
      };

    case ERROR_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: ERROR_MODAL,
        modalActionType: action.payLoad.actionType,
        errorMessage: action.payLoad.message,
      };

    case INFO_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: INFO_MODAL,
        modalActionType: action.payLoad.actionType,
        infoMessage: action.payLoad.message,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        modalType: '',
        modalActionType: '',
        notify: false,
        notificationEvent: {},
        errorMessage: '',
        infoMessage: '',
        showSocketModal: false,
        isSaveBtn: false,
      };

    default:
      return state;
  }
};

export default modalReducer;
