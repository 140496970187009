import React from "react";

import GraphSquare from "../GraphSquare/GraphSquare";

import "./GraphColumn.css";

const GraphColumn = ({ graphdata }) => {
  return (
    <div className="board-graph-container">
      {graphdata.map((columnData, i) => (
        <GraphSquare
          key={i}
          columnData={columnData.activities}
          date={columnData.date}
          day={columnData.day}
        />
      ))}
    </div>
  );
};

export default GraphColumn;
