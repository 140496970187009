import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { nanoid } from "nanoid";

import {
  createNewBoardServer,
  createNewTeamBoardServer,
  editBoardServer,
  handleDeleteBoardServer,
  closeModal,
} from "../../../redux";
import { ADD, EDIT, DELETE } from "../../../redux/modal/modalTypes";

import "./boardModal.css";

const BoardModal = ({
  type,
  closeModal,
  addBoardServer,
  boardTitle,
  boardId,
  updateBoardServer,
  deleteBoardServer,
  user,
  isLoading,
  userLocation,
  addNewTeamBoard,
  selectTeamId,
}) => {
  const [boardName, setBoardName] = useState("");
  const [editBoardName, setEditBoardName] = useState(boardTitle);

  const AddBoard = () => {
    const handleSubmit = () => {
      const boardId = nanoid(7);

      if (!selectTeamId) {
        const boardObj = {
          id: boardId,
          title: boardName,
          numberOfColumns: 0,
          numberOfCards: 0,
          columnsOrder: [],
          admins: [],
          members: [],
        };
        addBoardServer(boardObj, user.token);
      } else if (selectTeamId) {
        const boardObj = { title: boardName, id: boardId };
        addNewTeamBoard(user.token, boardObj, { teamId: selectTeamId });
      }

      setBoardName("");
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Add New Board</h2>
        <input
          className="modal-board-text"
          type="text"
          name="boardName"
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          placeholder="New Board"
          autoFocus
          required
        />
        <button className="modal-board-button" type="submit">
          {isLoading ? "Saving" : "Save"}
        </button>
      </form>
    );
  };

  const EditBoard = () => {
    const handleSubmit = () => {
      updateBoardServer(
        boardId,
        { boardId, teamId: selectTeamId },
        { title: editBoardName },
        user.token
      );
      setEditBoardName("");
    };
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Edit Board</h2>
        <input
          className="modal-board-text"
          type="text"
          name="editBoardName"
          value={editBoardName}
          onChange={(e) => setEditBoardName(e.target.value)}
          autoFocus
          required
        />
        <button className="modal-board-button" type="submit">
          {isLoading ? "Saving" : "Save"}
        </button>
      </form>
    );
  };

  const DeleteBoard = () => {
    return (
      <div className="modal-body">
        <h2 className="modal-title">Are You Sure?</h2>
        <span className="modal-sub-text">
          All information contained in this board will be deleted
        </span>
        <div className="modal-button-container">
          <button
            className="delete-button"
            onClick={() => {
              deleteBoardServer(
                boardId,
                { boardId, teamId: selectTeamId },
                user.token
              );
            }}
          >
            {isLoading ? "Deleting" : "Delete"}
          </button>
          <button className="modal-cancel-button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-board">
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        {type === ADD ? <AddBoard /> : null}
        {type === EDIT ? <EditBoard /> : null}
        {type === DELETE ? <DeleteBoard /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    boardTitle: state.board.selectBoard.title,
    boardId: state.board.selectBoard.id,
    user: state.user.userData,
    isLoading: state.board.loading,
    userLocation: state.board.location,
    selectTeamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBoardServer: (boardObj, token) =>
      dispatch(createNewBoardServer(boardObj, token)),
    addNewTeamBoard: (token, boardObj, params) =>
      dispatch(createNewTeamBoardServer(token, boardObj, params)),
    updateBoardServer: (boardId, params, boardObj, token) =>
      dispatch(editBoardServer(boardId, params, boardObj, token)),
    deleteBoardServer: (boardId, params, token) =>
      dispatch(handleDeleteBoardServer(boardId, params, token)),
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardModal);
