import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';

import {
  closeModal,
  inviteMemberToBoard,
  getUserEmail,
  removeMemberFromBoard,
} from '../../../redux';

import './userModal.css';

const UserModal = ({
  closeModal,
  type,
  inviteMember,
  user,
  currrentBoardId,
  setUserEmail,
  userToRemoveEmail,
  removeMember,
  loading,
}) => {
  const AddUserModal = () => {
    const [userEmail, setUserEmail] = useState('');

    const handleSubmit = () => {
      inviteMember(
        user.token,
        {
          boardId: currrentBoardId,
          userEmail: userEmail,
        },
        currrentBoardId
      );
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="modal-body"
      >
        <h2 className="modal-title">Invite User To Board</h2>

        <span>3 max basic plan users allowed on this board </span>
        <input
          className="modal-board-text"
          type="email"
          name="userEmail"
          placeholder="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          autoFocus
          disabled={loading}
        />
        <div className="modal-button-container">
          <button className="modal-board-button" type="submit">
            {loading ? 'Inviting user' : 'Add User'}
          </button>
        </div>
      </form>
    );
  };

  const RemoveUserModal = () => {
    const handleRemoveMember = () => {
      removeMember(
        user.token,
        {
          userEmail: userToRemoveEmail,
          boardId: currrentBoardId,
        },
        { boardId: currrentBoardId }
      );
      setUserEmail('');
    };

    return (
      <div className="modal-body">
        <h2 className="modal-title">Remove User From Board</h2>
        <div className="modal-button-container">
          <p className="remove-user-email">{userToRemoveEmail}</p>
          <button
            className="delete-button"
            style={{ margin: '0px' }}
            onClick={handleRemoveMember}
          >
            {loading ? 'Removing user' : 'Remove User'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-board">
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        {type === 'ADD' ? <AddUserModal /> : null}
        {type === 'REMOVE' ? <RemoveUserModal /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    user: state.user.userData,
    currrentBoardId: state.board.selectBoard.id,
    userToRemoveEmail: state.board.userEmail,
    loading: state.board.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    inviteMember: (token, boardData, boardId) =>
      dispatch(inviteMemberToBoard(token, boardData, boardId)),
    removeMember: (token, removeObj, params) =>
      dispatch(removeMemberFromBoard(token, removeObj, params)),
    setUserEmail: (email) => dispatch(getUserEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
