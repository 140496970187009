import React from "react";
import { connect } from "react-redux";
import { LoadingIcon } from "../../assets/svg/iconlibrary";
import { withRouter } from "react-router-dom";

import { fetchBoard } from "../../redux";

import "./termsPage.css";

class TermsPage extends React.Component {
  componentDidMount() {
    window.ononline = (event) => {
      this.props.getBoard(this.props.user.token, this.props.board.id);
      this.props.history.goBack();
    };
  }

  render() {
    return (
      <div className="terms-page">
        <p class="c13"><span class="c11">Terms of Service</span></p>
    <p class="c10"><span class="c11"></span></p>
    <p class="c5"><span class="c0">Last Modified: </span><span>Sept 1, 2023</span></p>
    <p class="c5"><span class="c0">Effective: </span><span>Sept 1, 2023</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c7">Acceptance of the Terms of Service:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">These Terms of Service (&ldquo;</span><span class="c9">Terms</span><span
            class="c0">), along with any other terms, agreements, policies (including our Privacy Policy), guidelines,
            or other similar documents provided by Kevvlar, LLC, and its affiliates (&ldquo;</span><span
            class="c9">Kevvlar</span><span class="c0">,&rdquo; &ldquo;</span><span class="c9">we</span><span
            class="c0">,&rdquo; &ldquo;</span><span class="c9">us</span><span class="c0">,&rdquo; or &ldquo;</span><span
            class="c9">our</span><span class="c0">&rdquo;) is an agreement that governs your access and use of
            Kevvlar&rsquo;s website, user account, platform, application, software, API, content, and any and all other
            current and future Kevvlar products and services (collectively, &ldquo;</span><span
            class="c9">Services</span><span class="c0">&rsquo;). Acceptance of these terms does not discriminate between
            your status as a guest, registered user, or the tier of your registered account. </span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">Therefore, in the interest of all parties involved, please read these Terms
            carefully. </span><span class="c9">By using the Website, you accept and agree to be bound and abide by these
            Terms and our Privacy Policy.</span><span class="c0">&nbsp;If you do not want to agree to these Terms or the
            Privacy Policy, you must not access or use any of our Services.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.30j0zll"></a>
    <p class="c5"><span class="c0">Where provided herein, the usage of &ldquo;</span><span class="c9">you</span><span
            class="c0">&rdquo; and &ldquo;</span><span class="c9">your</span><span class="c0">&rdquo; applies to the
            user of our Services. If you are utilizing our Services as an agent or employee of a legal entity, you agree
            that you have the authority to bind that entity to these Terms, and &ldquo;</span><span
            class="c9">you</span><span class="c0">&rdquo; and &ldquo;</span><span class="c9">your</span><span
            class="c0">&rdquo; shall, in addition, apply to that legal entity.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c9">IMPORTANT NOTE: The section titled &ldquo;Dispute Resolution Process; Arbitration
            Agreement; Class Action/Jury Waiver&rdquo; contains an arbitration clause, class-action waiver, and jury
            demand waiver that applies to all United States based Kevvlar users. Please read that section carefully as
            it may significantly affect your legal rights, including your right to file a lawsuit in court.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1fob9te"></a>
    <p class="c5"><span class="c0">Our Services are directed, offered, and available to users who are (a) 13 years of
            age or older or (b) anyone under the applicable age of majority according to the data protection laws and
            regulations in your jurisdiction and reside in the United States. By using our Services, you represent and
            warrant that you are of legal age to form a binding contract with Kevvlar and meet all of the foregoing
            eligibility requirements. If you do not meet all of these requirements, you must not access or use our
            Services.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3znysh7"></a>
    <p class="c5"><span class="c7">Changes to the Terms of Service:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2et92p0"></a>
    <p class="c5"><span class="c0">We may revise and update these Terms from time to time in our sole discretion. All
            changes are effective immediately when we post them, and apply to all our Services thereafter.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.tyjcwt"></a>
    <p class="c5"><span class="c0">Your continued use of our Services following the posting of revised Terms shall mean
            that you accept and agree to the changes, revisions, additions, and any and all other alterations. You are
            expected to check this page so you are aware of any changes, as they are binding on you. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3dy6vkm"></a>
    <p class="c5"><span class="c7">Accessing the Services and Account Security:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1t3h5sf"></a>
    <p class="c5"><span class="c0">We reserve the right to withdraw or amend our Services, and any service, content,
            material, or item we provide on our website, or elsewhere, in our sole discretion without notice. We shall
            not be liable if, for any reason, all or any part of our Services are unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of the Services, or all of the Services, to
            users, including registered users.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.4d34og8"></a>
    <p class="c5"><span class="c0">You are responsible for both:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0 start">
        <li class="c1 li-bullet-0"><span class="c0">Making all arrangements necessary for you to have access to any and
                all of our provided Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Ensuring that all persons who access our Services through your
                internet connection are aware of these Terms and comply with them.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.2s8eyo1"></a>
    <p class="c5"><span class="c0">To access our Services or any of the resources it offers, you may be asked to provide
            certain registration details or other information, including personal information. It is a condition of your
            use of our Services that all the information you provide for access to our Services is true and accurate,
            correct, current, and complete. You agree that all information you provide to register with our Services or
            otherwise, including, but not limited to, through the use of any interactive features on our Services, is
            governed by our Privacy Policy, and you consent to all actions we take with respect to your information
            consistent with our Privacy Policy.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.17dp8vu"></a>
    <p class="c5"><span class="c0">If you choose, or are provided with, a username, password, or any other piece of
            information as part of our security procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also acknowledge that your account is personal to
            you and you agree not to provide any other person with access to our Services or portions of it using your
            username, password, or other security information. You agree to notify us immediately of any unauthorized
            access to or use of your username or password or any other breach of security. You should use particular
            caution when accessing your account from a public or shared computer so that others are not able to view or
            record your password or other personal information. We shall not be held liable for any breach of your user
            account information, including personal information, relating to your disclosure of your account information
            with others or your failure to take precautions when using our Services from public computers, networks,
            etc.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3rdcrjn"></a>
    <p class="c5"><span class="c0">We have the right to disable any username, password, or other identifier, whether
            chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in
            our opinion, you have violated any provision of these Terms.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.26in1rg"></a>
    <p class="c5"><span class="c7">Intellectual Property Rights:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.lnxbz9"></a>
    <p class="c5"><span class="c0">Our Services, including, but not limited to, all information, software, contents,
            features, functionality, displays, images, video, audio, and the design, selection, and arrangement thereof,
            is property of Kevvlar, LLC, its affiliates, licensors, or other providers of such material and are
            protected by United States and international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.35nkun2"></a>
    <p class="c5"><span class="c0">These Terms permit you to use our Services for your use only. You must not reproduce,
            distribute, modify, create derivative works of, publicly display, publicly perform, republish, download,
            store, or transmit any of the material found through our Services, except as follows:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Your computer may temporarily store copies of such materials in RAM
                incidental to your accessing and viewing those materials.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">You may store files that are automatically cached by your web
                browser for display enhancement purposes.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">You may print [or download] one copy of a reasonable number of pages
                of our website for your own personal, non-commercial use and not for further reproduction, publication,
                or distribution.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">If we provide desktop, mobile, or other applications for download,
                you may download a single copy to your computer or mobile device solely for your own use, provided you
                agree to be bound any and all of our terms, agreements, licenses, polices, etc., for such
                applications.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">If we provide social media features with certain content, you may
                take such actions as are enabled by such features.</span></li>
    </ul><a id="id.1ksv4uv"></a>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">You must not:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Modify copies of any materials from our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from our Services.</span></li>
    </ul>
    <p class="c4"><span class="c0"></span></p><a id="id.44sinio"></a>
    <p class="c5"><span class="c0">If you wish to make any use of material found through via our Services other than
            that set out in this section, please address your request to: </span><span>info@kevvlar.com</span><span
            class="c0">.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2jxsxqh"></a>
    <p class="c5"><span class="c0">If you print, copy, modify, download, or otherwise use or provide any other person
            with access to any part of our Services in breach of these Terms, your right to use our Services will cease
            immediately and you must, at our option, return or destroy any copies of the materials you have made. No
            right, title, or interest in or to our Services or any content derived from our Services is transferred to
            you, and all rights not expressly granted are reserved by Kevvlar. Any use of our Services not expressly
            permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other
            laws.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.z337ya"></a>
    <p class="c5"><span class="c7">Trademarks:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3j2qqm3"></a>
    <p class="c5"><span class="c0">Kevvlar&rsquo;s name and all related names, logos, product and service names,
            designs, and slogans are trademarks of Kevvlar&rsquo;s or its affiliates or licensors. You must not use such
            marks without our prior written consent. All other names, logos, product and service names, designs, and
            slogans throughout our Services are the trademarks of their respective owners.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1y810tw"></a>
    <p class="c5"><span class="c7">Prohibited Uses:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.4i7ojhp"></a>
    <p class="c5"><span class="c0">You may use our Services only for lawful purposes and in accordance with these Terms.
            While using our Services, you agree not to:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Violate any applicable federal, state, local, or international law
                or regulation (including, without limitation, any laws regarding the export of data or software to and
                from the United States or other countries or laws of your jurisdiction). </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Use our Services for the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for
                personally identifiable information, or otherwise.</span></li>
    </ul>
    <p class="c4 c8"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Engage in activity that incites or encourages violence or hatred
                against individuals or groups.</span></li>
    </ul>
    <p class="c4 c8"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Use our Services for consumer purposes, as Kevvlar&rsquo;s intended
                and targeted audience is businesses and organizations.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Send, knowingly receive, upload, download, use, or re-use any
                material that does not comply with the Content Standards set out in these Terms.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Transmit, or procure the sending of, any advertising or promotional
                material (without our prior written consent), including any &quot;junk mail,&quot; &quot;chain
                letter,&quot; &quot;spam,&quot; or any other similar solicitation.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Impersonate or attempt to impersonate Kevvlar, a Kevvlar employee,
                another user, or any other person or entity (including, without limitation, by using email addresses or
                screen names associated with any of the foregoing).</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Engage in any other conduct that restricts or inhibits anyone&#39;s
                use or enjoyment of our Services, or which, as determined by us, may harm Kevvlar or our users, or
                expose them to liability.</span></li>
    </ul>
    <p class="c4 c8"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Store or transmit any user data that may infringe upon or
                misappropriate someone else&rsquo;s trademark, copyright, or other intellectual property, or that may be
                tortious or unlawful.</span></li>
    </ul>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Act in any manner that could disable, overburden, damage, or impair
                the site or interfere with any other party&#39;s use of our Services, including their ability to engage
                in real time activities through our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Create, implement, or distribute any robot, spider, or other
                automatic device, process, or means to access our Services for any purpose, including monitoring or
                copying any of the material found through our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Use any manual process to monitor or copy any of the material found
                through our Services, or for any other purpose not expressly authorized in these Terms, without our
                prior written consent.</span></li>
    </ul>
    <p class="c4 c8"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Attempt to reverse engineer, decompile, hack, disable, interfere
                with, disassemble, modify, copy, translate, or disrupt the features, functionality, integrity, or
                performance of our Services (including any mechanism used to restrict or control the functionality of
                our Services), any third party use of our Services, or any third party data contained therein.</span>
        </li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Access our Services in order to build a similar or competitive
                product or service or copy any ideas, features, functions, or graphics of our Services.</span></li>
    </ul>
    <p class="c4 c8"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Use any device, software, or routine that interferes with the proper
                working of our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Introduce any viruses, Trojan horses, worms, logic bombs, or other
                material that is malicious or technologically harmful.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Attempt to gain unauthorized access to, interfere with, damage, or
                disrupt any parts of the our Services, the server on which our Services are stored, or any server,
                computer, or database connected to our Services. </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Attack our Services via a denial-of-service attack or a distributed
                denial-of-service attack.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Otherwise attempt to interfere with the proper working or operation
                of our Services.</span></li>
    </ul>
    <p class="c4 c6"><span class="c0"></span></p><a id="id.2xcytpi"></a>
    <p class="c5"><span class="c7">User Contributions:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1ci93xb"></a>
    <p class="c5"><span class="c0">Our Services may contain message boards, chat rooms, personal webpages or profiles,
            forums, bulletin boards, and other interactive features that allow users to post, submit, publish, display,
            or transmit to other users or other persons content or materials (collectively, &quot;</span><span
            class="c9">User Contributions</span><span class="c0">&quot;) on or throughout our Services.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3whwml4"></a>
    <p class="c5"><span class="c0">All User Contributions must comply with the Content Standards set out in these
            Terms.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2bn6wsx"></a>
    <p class="c5"><span class="c0">Any User Contribution you post to within our Services will be considered
            non-confidential and non-proprietary. By providing any User Contribution within our Services, you grant us
            the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties
            any such material according to your account settings. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.qsh70q"></a>
    <p class="c5"><span class="c0">You represent and warrant that: </span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">You own or control all rights in and to the User Contributions and
                have the right to grant the license granted above to us.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">All of your User Contributions do and will comply with these Terms.
            </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.3as4poj"></a>
    <p class="c5"><span class="c0">You understand and acknowledge that you are responsible for any User Contributions
            you submit or contribute, and you, and not Kevvlar, have full responsibility and liability for such content,
            including its legality, reliability, accuracy, and appropriateness.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1pxezwc"></a>
    <p class="c5"><span class="c0">We are not responsible or liable to any third party for the content or accuracy of
            any User Contributions posted by you or any other user of our Services. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.49x2ik5"></a>
    <p class="c5"><span class="c7">Monitoring and Enforcement; Termination:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2p2csry"></a>
    <p class="c5"><span class="c0">We have the right to:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Remove or refuse to post any User Contributions for any or no reason
                in our sole discretion.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Take any action with respect to any User Contribution that we deem
                necessary or appropriate in our sole discretion, including if we believe that such User Contribution
                violates these Terms, including the Content Standards, infringes on any intellectual property right or
                other right of any person or entity, threatens the personal safety of users of our Services, Kevvlar, or
                the public, or could create any liability for Kevvlar.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Disclose your identity or other information about you to any third
                party who claims that material posted by you violates their rights, including their intellectual
                property rights or their right to privacy.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use of our Services. </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Terminate or suspend your access to all or part of our Services for
                any or no reason, including without limitation, for any violation of these Terms.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.147n2zr"></a>
    <p class="c5"><span class="c0">Without limiting the foregoing, we have the right to cooperate fully with any law
            enforcement authorities or court order requesting or directing us to disclose the identity or other
            information of anyone posting any materials on or through our Services. YOU WAIVE AND HOLD KEVVLAR, ITS
            EMPLOYEES, DIRECTORS, OFFICERS, AFFILIATES, LICENSORS, LICENSEES, AND SERVICE PROVIDERS HARMLESS FROM ANY
            CLAIMS RESULTING FROM ANY ACTION TAKEN BY KEVVLAR, AND ANY OF THE FOREGOING PARTIES, DURING, OR TAKEN AS A
            CONSEQUENCE OF, INVESTIGATIONS BY EITHER KEVVLAR, AND ANY OF THE FOREGOING PARTIES, OR LAW ENFORCEMENT
            AUTHORITIES.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3o7alnk"></a>
    <p class="c5"><span class="c0">However, as it would be overly and unduly burdensome, we do not, nor allege that we
            are able to review all material, including but not limited to User Contributions, before it is posted on our
            Services, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly,
            we assume no liability for any action or inaction regarding transmissions, communications, or content
            provided by any user or third party. We shall not be held liable nor responsible to anyone for performance
            or nonperformance of the activities described in this section. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.23ckvvd"></a>
    <p class="c5"><span class="c7">Content Standards:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.ihv636"></a>
    <p class="c5"><span class="c0">These content standards apply to any and all User Contributions. User Contributions
            must, in their entirety, comply with all applicable federal, state, local, and international laws and
            regulations. Without limiting the foregoing, User Contributions must not:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Contain any material that is defamatory, obscene, indecent, abusive,
                offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Promote sexually explicit or pornographic material, violence, or
                discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</span>
        </li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Infringe any patent, trademark, trade secret, copyright, or other
                intellectual property or other rights of any other person.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Violate the legal rights (including the rights of publicity and
                privacy) of others or contain any material that could give rise to any civil or criminal liability under
                applicable laws or regulations or that otherwise may be in conflict with these Terms, our Privacy
                Policy, or any of our other policies, agreements, terms, guidelines.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Be likely to deceive any person.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Promote any illegal activity, or advocate, promote, or assist any
                unlawful act.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Cause annoyance, inconvenience, harassment, or needless anxiety or
                be likely to upset, embarrass, alarm, offend, or annoy any other person.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Impersonate any person, or misrepresent your identity or affiliation
                with any person or organization. </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Involve commercial activities or sales, such as contests,
                sweepstakes, and other sales promotions, barter, or advertising.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Give the impression that they emanate from or are endorsed by us or
                any other person or entity, if this is not the case.</span></li>
    </ul>
    <p class="c4"><span class="c0"></span></p><a id="id.32hioqz"></a>
    <p class="c5"><span class="c7">Reliance on Information Posted:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1hmsyys"></a>
    <p class="c5"><span class="c0">The information presented on or through our Services is made available solely for
            general information purposes. We do not warrant the accuracy, completeness, or usefulness of this
            information. Any reliance you place on such information is strictly at your own risk. We disclaim all
            liability and responsibility arising from any reliance placed on such materials by you or any other user of
            our Services, or by anyone who may be informed of any of its contents.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.41mghml"></a>
    <p class="c5"><span class="c0">Our Services may include content provided by third parties, including materials
            provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting
            services. All statements and/or opinions expressed in these materials, and all articles and responses to
            questions and other content, other than the content provided by us, are solely the opinions and the
            responsibility of the person or entity providing those materials. These materials do not necessarily reflect
            nor represent Kevvlar&rsquo;s, or its employees&rsquo;, agents&rsquo;, directors&rsquo;, officers&rsquo;,
            licensees&rsquo;, licensors&rsquo;, or affiliates&rsquo;, opinions or beliefs on the subject matter. We are
            not responsible, or liable to you or any third party, for the content or accuracy of any materials provided
            by any third parties.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2grqrue"></a>
    <p class="c5"><span class="c7">Changes to Our Services:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.vx1227"></a>
    <p class="c5"><span class="c0">We may update the content on our Services from time to time, but its content is not
            necessarily complete or up-to-date. Any of the material on our Services may be out of date at any given
            time, and we are under no obligation to update such material. We shall not be held liable nor responsible
            for any outdated materials on all or any portion of our Services.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3fwokq0"></a>
    <p class="c5"><span class="c7">Information About You and Your Visits to Our Services:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1v1yuxt"></a>
    <p class="c5"><span class="c0">All information we collect throughout our Services is subject to our Privacy Policy.
            By using our Services, you consent to all actions taken by us with respect to your information in compliance
            with the Privacy Policy. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.4f1mdlm"></a>
    <p class="c5"><span class="c7">Linking to Our Services, Webpages, and Social Medias:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2u6wntf"></a>
    <p class="c5"><span class="c0">You may link to our Services, webpages, or social media pages, provided you do so in
            a way that is fair and legal and does not damage or harm our reputation or take advantage of it or may
            induce any liability for us, but you must not establish a link in such a way as to suggest any form of
            association, approval, or endorsement on our part, without our prior written consent. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.19c6y18"></a>
    <p class="c5"><span class="c0">Our Services may provide certain social media features that enable you to:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Link from your own or certain third-party websites to certain
                content found via our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Send emails or other communications with certain content, or links
                to certain content, found via our Services.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Cause limited portions of content found via our Services to be
                displayed or appear to be displayed on your own or certain third-party websites.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.3tbugp1"></a>
    <p class="c5"><span class="c0">You may use these features solely as they are provided by us, and solely with respect
            to the content they are displayed with, and otherwise in accordance with any additional terms and conditions
            we provide with respect to such features. Subject to the foregoing, you must not:</span></p>
    <p class="c4"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Establish a link from any website that is not owned by you.</span>
        </li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Cause our Services portions of it to be displayed on, or appear to
                be displayed by, any other site, for example, framing, deep linking, or in-line linking.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c3 lst-kix_list_31-0">
        <li class="c1 li-bullet-0"><span class="c0">Otherwise take any action with respect to the materials found via
                our Services that is inconsistent with any other provision of these Terms, or any other terms, policies,
                agreements, or guidelines.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.28h4qwu"></a>
    <p class="c5"><span class="c0">You agree to cooperate with us in causing any unauthorized framing or linking
            immediately to stop. We reserve the right to withdraw linking permission without notice.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.nmf14n"></a>
    <p class="c5"><span class="c0">We may disable all or any social media features and any links at any time without
            notice in our discretion. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.37m2jsg"></a>
    <p class="c5"><span class="c7">Links from Our Services:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1mrcu09"></a>
    <p class="c5"><span class="c0">If any portion of our Services contains links to other sites and resources provided
            by third parties, these links are provided for your convenience only. This includes links contained in
            advertisements, including banner advertisements and sponsored links. We have no control over the contents of
            those sites or resources, and accept no responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any of the third-party websites linked to any portion of our
            Services, you do so entirely at your own risk and are subjected to the terms and conditions of use for such
            websites.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.46r0co2"></a>
    <p class="c5"><span class="c7">Disclaimer of Warranties:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2lwamvv"></a>
    <p class="c5"><span class="c0">You understand that we cannot and do not guarantee or warrant that files available
            for downloading from the internet or our Services will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements
            for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
            Services for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
            LIABLE NOR RESPONSIBLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
            OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
            OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF OUR SERVICES OR ANY CONTENT, MATERIALS, OR ITEMS OBTAINED
            THROUGH OUR SERVICES OR THROUGH YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO
            IT.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.111kx3o"></a>
    <p class="c5"><span class="c0">YOUR USE OF OUR SERVICES, ITS MATERIAL, CONTENT, AND ANY ITEMS OBTAINED THROUGH OUR
            SERVICES IS AT YOUR OWN RISK. OUR SERVICES, ITS CONTENT, AND ANY ITEMS OBTAINED THROUGH OUR SERVICES ARE
            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER KEVVLAR NOR ANY PERSON ASSOCIATED WITH KEVVLAR MAKES ANY WARRANTY OR
            REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY
            OF OUR SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER KEVVLAR NOR ANYONE ASSOCIATED WITH KEVVLAR
            REPRESENTS OR WARRANTS THAT OUR SERVICES, ITS CONTENT, OR ANY ITEMS OBTAINED THROUGH OUR SERVICES WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES OR THE
            SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT OUR SERVICES OR ANY
            CONTENT OR ITEMS OBTAINED THROUGH OUR SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3l18frh"></a>
    <p class="c5"><span class="c0">TO THE FULLEST EXTENT PROVIDED BY LAW, KEVVLAR HEREBY DISCLAIMS ALL WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.206ipza"></a>
    <p class="c5"><span class="c0">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.4k668n3"></a>
    <p class="c5"><span class="c7">Limitation on Liability:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2zbgiuw"></a>
    <p class="c5"><span class="c0">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL KEVVLAR, ITS AFFILIATES, OR
            THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
            KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OUR
            SERVICES, ANY WEBSITES LINKED TO IT, ANY CONTENT ON OUR SERVICES OR SUCH OTHER WEBSITES, INCLUDING ANY
            DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
            PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
            OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF
            CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1egqt2p"></a>
    <p class="c5"><span class="c0">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.</span></p>
    <p class="c4"><span class="c9"></span></p><a id="id.3ygebqi"></a>
    <p class="c5"><span class="c7">Indemnification:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2dlolyb"></a>
    <p class="c5"><span class="c0">You agree to defend, indemnify, and hold harmless Kevvlar, its affiliates, licensors,
            and service providers, and its and their respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments,
            awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out of or
            relating to your violation of these Terms or your use of our Services, including, but not limited to, your
            User Contributions, any use of our Services&rsquo; content, material, and products other than as expressly
            authorized in these Terms, or your use of any information obtained from our Services.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c7">Dispute Resolution Process; Arbitration Agreement; Class Action/Jury Waiver:</span>
    </p>
    <p class="c4"><span class="c0"></span></p><a id="id.sqyw64"></a>
    <p class="c5"><span class="c0">All matters relating to our Services and these Terms, and any dispute or claim
            arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be
            governed by and construed in accordance with the internal laws of the State of Illinois without giving
            effect to any choice or conflict of law provision or rule (whether of the State of Illinois or any other
            jurisdiction). </span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">First, you agree to attempt, for at least 60 days, upon either parties receipt of a
            notice of dispute, to resolve any dispute, disagreement, or claim informally. If such dispute, disagreement,
            or claim is not informally resolved after 60 days, </span><span class="c9">you and we agree to resolve any
            dispute, disagreement, or claim relating to these Terms, our Services, or between you and us through final
            and binding arbitration in the county of Winnebago, in the state of Illinois, United States</span><span
            class="c0">. </span><span class="c9">Arbitration shall be governed by, and before, the American Arbitration
            Association (&ldquo;AAA&rdquo;) and under the Federal Arbitration Act (&ldquo;FAA&rdquo;), and no disputes,
            disagreements, or claims shall be resolved by the filing of a lawsuit in court in front of a judge or jury.
        </span><span class="c0">You and Kevvlar are instead electing that all disputes shall be resolved by arbitration
            under these Terms. </span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">If there is dispute, disagreement, or claim between you and us, either party must
            first an individualized Notice of Dispute. The Notice of Dispute shall include (i) your name, telephone
            number, mailing address, and email address associated with your account; (ii) the name, telephone number,
            mailing address and email address of your counsel, if any; (iii) a description of your claim, harm, damages,
            date of harm, and any additional relevant information; and (iv) your signature. If you would like to submit
            a Notice of Dispute, you are required to mail an individualized notice to ATTN: Litigation Department,
            Kevvlar, LLC, </span><span>info@kevvlar.com.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">If, after completing the informal dispute resolution process set out above, either
            you or we wish to initiate arbitration, the initiating party must serve the other party with a demand for
            arbitration. Any demand for arbitration by you shall be sent to the Kevvlar Litigation Department address
            listed above. Kevvlar shall send any arbitration demand to the email address associated with your account or
            to your counsel, if any.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5" id="h.3cqmetx"><span class="c0">A single arbitrator will be appointed. The arbitrator may award
            damages, declaratory or injunctive relief, and recoverable costs. Any arbitration award may be enforced
            (such as through a judgment) in any court with jurisdiction. An arbitration award shall have no preclusive
            effect in another arbitration or court proceeding involving Kevvlar and a different individual.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">This duty to arbitrate any disputes, disagreements, claims, or any other such matters
            continues even after termination of your account or use of our Services.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c9">Class action law suits, class-wide arbitrations, private attorney-general actions,
            requests for public injunctions, and any other proceeding or request for relief where someone acts in a
            representative capacity are not allowed. Combining individual proceedings without the &nbsp;consent of all
            parties involved is prohibited.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">You can decline this agreement to arbitrate by emailing an opt-out notice to
        </span><span>info@kevvlar.com</span><span class="c0">&nbsp;within 30 days of </span><span>the effective
            date</span><span class="c0">&nbsp;or when you first register your user account, whichever is later,
            otherwise you shall be bound to arbitrate any and all disputes, disagreements, and claims in accordance with
            these Terms. If you choose to opt out of these arbitration provisions, Kevvlar will also not be bound by
            them.</span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c0">If any provision of this arbitration agreement is found to be invalid, illegal, or
            unenforceable for any reason, then such provision will be severed; however, the remaining provisions shall
            still apply and shall be interpreted to achieve the closest possible intent to the original intent of this
            section, inclusive of the severed provision.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1rvwp1q"></a>
    <p class="c5"><span class="c7">Limitation on Time to File Claims:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.4bvk7pj"></a>
    <p class="c5"><span class="c0">ANY CAUSE OF ACTION, DISPUTE, OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
            THESE TERMS OR OUR SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
            OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.2r0uhxc"></a>
    <p class="c5"><span class="c7">Waiver and Severability:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.1664s55"></a>
    <p class="c5"><span class="c0">No waiver by Kevvlar of any term or condition set out in these Terms shall be deemed
            a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
            failure of Kevvlar to assert a right or provision under these Terms shall not constitute a waiver of such
            right or provision.</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.3q5sasy"></a>
    <p class="c5"><span class="c0">If any provision of these Terms is held by a court or other tribunal of competent
            jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of the Terms will continue in full force
            and effect. </span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c5"><span class="c7">Electronic Communications, Transactions, and Signatures:</span></p>
    <p class="c4"><span class="c7"></span></p>
    <p class="c5"><span class="c0">Utilizing our Services, sending us emails, and completing online forms constitute
            electronic communications. You consent to receive electronic communications, and you agree that all
            agreements, notices, disclosures, and other communications we provide to you electronically, via email and
            via our Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO
            THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
            POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA OUR SERVICES. </span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.25b2l0r"></a>
    <p class="c5"><span class="c7">Entire Agreement:</span></p>
    <p class="c4"><span class="c0"></span></p><a id="id.kgcv8k"></a>
    <p class="c5"><span class="c0">These Terms and our Privacy Policy constitute the sole and entire agreement between
            you and Kevvlar regarding our Services and supersedes all prior and contemporaneous understandings,
            agreements, representations, and warranties, both written and oral, regarding our Services. </span></p>
    <p class="c4"><span class="c0"></span></p>
    <p class="c4"><span class="c0"></span></p>
    <div>
        <p class="c10"><span class="c0"></span></p>
        <p class="c5"><span
                class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </p>
    </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.board.selectBoard,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBoard: (token, boardId) => dispatch(fetchBoard(token, boardId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TermsPage));
