import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import NotificationIcon from '../notification-icon/NotificationIcon';
import CalenderIcon from '../CalendarIcon/CalenderIcon';
import UserProfile from '../profile-image/ProfileImage';
// import { HomeIcon } from "../../assets/svg/iconlibrary";
import { withRouter } from 'react-router';

import {
  clearColumns,
  resetIsMe,
  clearCardSearchKey,
  setCalendarModal,
  clearEvents,
  setCurrentBoardData,
  clearUsers,
  setSelectTeam,
} from '../../redux';
import { KevvlarLogo } from '../../assets/svg/iconlibrary';
import { requestDesktopNotificationPermission } from '../../utils';

import './appbar.css';

const AppBar = ({
  history,
  emptyColumns,
  clearIsMe,
  user,
  clearCardSearch,
  emptyEvents,
  setSelectBoardData,
  handleClearUsers,
  setSelectTeamData,
}) => {
  useEffect(() => {
    // Request permission for desktop notifications when the component mounts
    requestDesktopNotificationPermission();
  }, []);

  return (
    <div className="appbar-container">
      <div className="appbar-menu-container">
        <div className="appbar-ham-icon-container">
          <button
            className="appbar-logo"
            onClick={() => {
              setSelectTeamData({});
              setSelectBoardData({});
              clearCardSearch();
              clearIsMe();
              emptyColumns();
              emptyEvents();
              handleClearUsers();
              history.push('/boards');
            }}
          >
            <KevvlarLogo />
          </button>
          {user.role === 'admin' && (
            <button
              className="admin-route-button"
              onClick={() => {
                handleClearUsers();
                history.push('/admin/users');
              }}
            >
              Admin Dashboard
            </button>
          )}
        </div>
      </div>
      <div className="appbar-user-menu-container">
        <CalenderIcon />
        <NotificationIcon />
        <UserProfile />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    boards: state.board.boards,
    boardState: state.board.loading,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyColumns: () => dispatch(clearColumns()),
    clearIsMe: () => dispatch(resetIsMe()),
    clearCardSearch: () => dispatch(clearCardSearchKey()),
    showCalendar: () => dispatch(setCalendarModal()),
    emptyEvents: () => dispatch(clearEvents()),
    setSelectBoardData: (board) => dispatch(setCurrentBoardData(board)),
    setSelectTeamData: (team) => dispatch(setSelectTeam(team)),
    handleClearUsers: () => dispatch(clearUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppBar));
