export const SET_IS_NEW_MSG = "SET_IS_NEW_MSG";
export const SET_USER_CHAT_DATA = "SET_USER_CHAT_DATA";
export const CLEAR_USER_CHAT_DATA = "CLEAR_USER_CHAT_DATA";

export const OPEN_CHAT_MODAL = "OPEN_CHAT_MODAL";
export const CLOSE_CHAT_MODAL = "CLOSE_CHAT_MODAL";

export const SET_ACTIVE_CHANNEL = "SET_ACTIVE_CHANNEL";

export const CLEAR_CHAT_STATE = "CLEAR_CHAT_STATE";
