import {
  SUDO_SIGNIN_REQUEST,
  SUDO_SIGNIN_SUCCESS,
  SUDO_SIGNIN_ERROR,
  SUDO_SIGNOUT,
} from "./userTypes";

const initialState = {
  isSudo: false,
  loading: false,
  error: "",
  userData: {},
};

const adminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUDO_SIGNIN_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case SUDO_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payLoad.userData,
        isSudo: action.payLoad.isSudo,
      };

    case SUDO_SIGNIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case SUDO_SIGNOUT:
      return {
        ...state,
        isSudo: false,
        loading: false,
        error: "",
        userData: {},
      };

    default:
      return state;
  }
};

export default adminUserReducer;
