import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import AppBar from '../../components/appbar/AppBar';
import SearchBar from '../../components/SearchBar/SearchBar';
import BoardList from '../../components/BoardList/BoardList';
import ChatButton from '../../components/ChatButton/ChatButton';
import NotificationModal from '../../components/modal/NotificationModal/NotificationModal';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import TeamBoardList from '../../components/TeamBoardList/TeamBoardList';
import TeamSettings from '../../components/modal/TeamSettings/TeamSettings';

import { LoadingIcon } from '../../assets/svg/iconlibrary';

import {
  fetchBoards,
  fetchTeams,
  fetchEvents,
  setNotificationModal,
  setLocation,
  setSelectTeam,
  setCurrentBoardData,
  closeTeamSettingsModal,
} from '../../redux';

import './boardsPage.css';

class BoardsPage extends React.Component {
  hhmmssToSeconds = (timestr) => {
    const time = timestr.split(':');
    time[0] = parseInt(time[0]) * 3600;
    time[1] = parseInt(time[1]) * 60;
    time[2] = parseInt(time[2]);
    return time.reduce((acc, val) => acc + val);
  };

  timeDifference = (timeA, timeB) => {
    const difference =
      this.hhmmssToSeconds(timeA) - this.hhmmssToSeconds(timeB);
    return difference;
  };

  isFifteenMinutes = (timeA, timeB, duration) => {
    return this.timeDifference(timeA, timeB) === duration; // 900
  };

  isNow = (timeA, timeB, duration) => {
    return this.timeDifference(timeA, timeB) === duration; // 1s
  };

  checkTiming = (event) => {
    if (
      this.isFifteenMinutes(
        moment(event.start).format('HH:mm:ss'),
        moment().format('HH:mm:ss'),
        900
      )
    ) {
      return this.props.showEventNotification({ ...event, duration: 15 });
    } else if (
      this.isNow(
        moment(event.start).format('HH:mm:ss'),
        moment().format('HH:mm:ss'),
        1
      )
    ) {
      return this.props.showEventNotification({ ...event, duration: 0 });
    }
  };

  showEventNotifications = () => {
    this.props.eventList.forEach((event) => {
      if (
        event.recurring &&
        event.recurring.repeat === 'daily' &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring &&
        event.recurring.repeat === 'weekly' &&
        event.recurring.weekDays === moment().format('dd') &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring &&
        event.recurring.repeat === 'monthly' &&
        event.recurring.day === moment().format('D') &&
        moment().isSameOrBefore(event.recurring.until)
      ) {
        this.checkTiming(event);
      } else if (
        event.recurring === null &&
        moment().isBefore(event.start) &&
        moment().isSame(event.start, 'day')
      ) {
        this.checkTiming(event);
      }
    });
  };

  componentDidMount() {
    this.props.closeTeamSettings();
    this.props.selectBoard({});
    this.props.selectTeam({});
    this.props.getTeams(this.props.user.token);
    this.props.getBoards(this.props.user.token, this.props.history);
    this.props.getEvents(this.props.user.token);

    window.onoffline = (event) => {
      this.props.history.push('/error');
    };

    this.showEventInterval = setInterval(() => {
      this.showEventNotifications();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.showEventInterval);
  }

  render() {
    return (
      <div>
        {this.props.isFetching ? (
          <LoadingScreen message={'Loading'} />
        ) : (
          <div className="boards-page-holder">
            <AppBar />
            <div className="boards-page">
              <div className="board-main">
                <SearchBar />
                {this.props.boardState ? (
                  <LoadingIcon />
                ) : (
                  <>
                    <div className="boards-container">
                      <h2 className="all-boards-title">Personal Boards</h2>
                      <BoardList />
                    </div>

                    <div className="boards-container">
                      <TeamBoardList />
                    </div>
                  </>
                )}
              </div>
            </div>
            {this.props.teamSettings && <TeamSettings />}
            <NotificationModal />
            <ChatButton />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    boardState: state.board.isFetching,
    selectBoardId: state.board.selectBoard?.id,
    eventList: state.calendar.eventList,
    isChatModalOpen: state.chat.chatModalOpen,
    teamSettings: state.modal.teamSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBoards: (token, history) => dispatch(fetchBoards(token, history)),
    getEvents: (token) => dispatch(fetchEvents(token)),
    showEventNotification: (eventData) =>
      dispatch(setNotificationModal(eventData)),
    setUserLocation: (location) => dispatch(setLocation(location)),
    selectTeam: (team) => dispatch(setSelectTeam(team)),
    selectBoard: (board) => dispatch(setCurrentBoardData(board)),
    getTeams: (token) => dispatch(fetchTeams(token)),
    closeTeamSettings: () => dispatch(closeTeamSettingsModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BoardsPage));
