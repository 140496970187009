import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageCompress from 'quill-image-compress';
import ImageResize from 'quill-image-resize';
import MagicUrl from 'quill-magic-url';
import * as Emoji from 'quill-emoji';
import { connect } from 'react-redux';

import 'quill-emoji/dist/quill-emoji.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import './cardDetails.css';

const CardDetails = ({
  bodyText,
  setCardBody,
  setBodyLength,
  setRawDescData,
  user,
  board,
  currentCard,
}) => {
  Quill.register('modules/imageCompress', ImageCompress);
  Quill.register('modules/ImageResize', ImageResize);
  Quill.register('modules/magicUrl', MagicUrl);
  Quill.register('modules/emoji', Emoji);

  const MODULES = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['image', 'code-block', 'blockquote', 'link'],
    ],
    clipboard: {
      matchVisual: false,
    },
    ImageResize: {
      modules: ['Resize', 'DisplaySize'],
    },
    imageCompress: {
      quality: 5,
      maxWidth: 2000,
      maxHeight: 2000,
      imageType: 'image/jpeg', // default
    },
    magicUrl: true,
    'emoji-toolbar': true,
    'emoji-shortname': true,
  };

  const handleChange = (content, delta, source, editor) => {
    setCardBody(editor.getHTML());
    setBodyLength(editor.getHTML().length);
    setRawDescData(editor.getText().replace(' ', ''));
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    const regex = /<iframe.*?src="(.*?)".*?><\/iframe>/;
    const match = regex.exec(pastedData);
    if (match !== null) {
      const figmaSrc = match[1];
      const iframe = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="100%" height="90%" src="${figmaSrc}" allowfullscreen></iframe>`;
      e.preventDefault();
      const range = window.getSelection().getRangeAt(0);
      const node = document.createElement('div');
      node.innerHTML = iframe;
      range.insertNode(node);
    }
  };

  return (
    <div className="modal-body-description" onPaste={handlePaste}>
      <ReactQuill
        theme="snow"
        modules={MODULES}
        onChange={handleChange}
        value={bodyText}
        className="big-editor"
        id="ql-editor-big"
        tabIndex={1}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    board: state.board.selectBoard,
    currentCard: state.column.selectCard,
  };
};

export default connect(mapStateToProps, null)(CardDetails);
