import {
  TOGGLE_FILE_MODAL,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  CLEAR_FILES,
} from "./fileTypes";

const initialState = {
  showFiles: false,
  loading: false,
  files: [],
  error: "",
  deleting: false,
};

const FileReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FILE_MODAL:
      return {
        ...state,
        showFiles: !state.showFiles,
      };

    case FETCH_FILE_SUCCESS:
      return {
        ...state,
        files: action.payLoad,
      };

    case FETCH_FILE_FAILURE:
      return {
        ...state,
        error: action.payLoad,
      };

    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: [action.payLoad, ...state.files],
      };

    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case DELETE_FILE_REQUEST:
      return {
        ...state,
        deleting: true,
        error: "",
      };

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        deleting: false,
        files: state.files.filter((file) => file._id !== action.payLoad),
      };

    case DELETE_FILE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.payLoad,
      };

    case CLEAR_FILES:
      return {
        ...state,
        files: [],
        showFiles: false,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
};

export default FileReducer;
