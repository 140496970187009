import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppBar from '../../components/appbar/AppBar';

import SearchFilter from '../../components/admin/SearchFilter';
import TableHeader from '../../components/admin/TableHeader';
import TableData from '../../components/admin/TableData';

import {
  clearUsers,
  handleGetUsers,
} from '../../redux/admin/users/usersActions';

import './admin.css';

const UsersPage = ({ users, getUsers, user, loading, handleClearUsers }) => {
  const [keyWord, setKeyWord] = useState('');
  const [tempPage, setTempPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const handleNextPage = () => {
    setTempPage((prevState) => prevState + 1);
  };

  const handlePrevPage = () => {
    setTempPage((prevState) => prevState - 1);
  };

  const handleClearSearch = () => {
    setIsSearching(false);
    setKeyWord('');
    handleClearUsers();
    getUsers(user.token, tempPage, 25);
  };

  useEffect(() => {
    getUsers(user.token, tempPage, 25);
  }, [getUsers, user, tempPage]);

  return (
    <div>
      <AppBar />
      <div className="admin-page-container">
        <h4 className="admin-page-title">Users</h4>
        <SearchFilter
          keyWord={keyWord}
          setKeyWord={setKeyWord}
          setIsSearching={setIsSearching}
        />
        {isSearching && (
          <div>
            <p
              onClick={handleClearSearch}
              className="admin-page-load-more-users-button clear-search"
            >
              {users.length} Results &nbsp;&nbsp;&nbsp; Clear Search
            </p>
          </div>
        )}
        {loading ? (
          <p className="admin-page-loading">Loading...</p>
        ) : (
          <div className="admin-page-content">
            <TableHeader />
            {users?.map((userData, index) => (
              <TableData key={index} userData={userData} />
            ))}
            {!isSearching && (
              <div className="pagination-actions-container">
                {!(tempPage <= 0) && (
                  <p
                    onClick={handlePrevPage}
                    className="admin-page-load-more-users-button"
                  >
                    Previous Page
                  </p>
                )}
                {users.length !== 0 && (
                  <p
                    onClick={handleNextPage}
                    className="admin-page-load-more-users-button"
                  >
                    Next page
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.usersList,
    user: state.user.userData,
    loading: state.users.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (token, page, size) =>
      dispatch(handleGetUsers(token, page, size)),
    handleClearUsers: () => dispatch(clearUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
