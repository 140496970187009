export const TOGGLE_PIN_MODAL = "TOGGLE_PIN_MODAL";

export const ADD_PIN_REQUEST = "ADD_PIN_REQUEST";
export const ADD_PIN_SUCCESS = "ADD_PIN_SUCCESS";
export const ADD_PIN_FAILURE = "ADD_PIN_FAILURE";

export const FETCH_PINS_REQUEST = "FETCH_PINS_REQUEST";
export const FETCH_PINS_SUCCESS = "FETCH_PINS_SUCCESS";
export const FETCH_PINS_FAILURE = "FETCH_PINS_FAILURE";

export const DELETE_PIN_REQUEST = "DELETE_PIN_REQUEST";
export const DELETE_PIN_SUCCESS = "DELETE_PIN_SUCCESS";
export const DELETE_PIN_FAILURE = "DELETE_PIN_FAILURE";

export const CLEAR_PINS = "CLEAR_PINS";
