export const FETCH_BOARDS_REQUEST = "FETCH_BOARDS_REQUEST";
export const FETCH_BOARDS_SUCCESS = "FETCH_BOARDS_SUCCESS";
export const FETCH_BOARDS_FAILURE = "FETCH_BOARDS_FAILURE";

export const FETCH_TEAM_BOARDS_REQUEST = "FETCH_TEAM_BOARDS_REQUEST";
export const FETCH_TEAM_BOARDS_SUCCESS = "FETCH_TEAM_BOARDS_SUCCESS";
export const FETCH_TEAM_BOARDS_FAILURE = "FETCH_TEAM_BOARDS_FAILURE";

export const CLEAR_BOARDS = "CLEAR_BOARDS";

export const ADD_NEW_BOARD_LOCAL = "ADD_NEW_BOARD_LOCAL";

export const ADD_NEW_BOARD_SERVER_REQUEST = "ADD_NEW_BOARD_SERVER_REQUEST";
export const ADD_NEW_BOARD_SERVER_SUCCESS = "ADD_NEW_BOARD_SERVER_SUCCESS";
export const ADD_NEW_BOARD_SERVER_FAILURE = "ADD_NEW_BOARD_SERVER_FAILURE";

export const ADD_NEW_TEAM_BOARD_SERVER_REQUEST =
  "ADD_NEW_TEAM_BOARD_SERVER_REQUEST";
export const ADD_NEW_TEAM_BOARD_SERVER_SUCCESS =
  "ADD_NEW_TEAM_BOARD_SERVER_SUCCESS";
export const ADD_NEW_TEAM_BOARD_SERVER_FAILURE =
  "ADD_NEW_TEAM_BOARD_SERVER_FAILURE";

export const EDIT_BOARD_LOCAL = "EDIT_BOARD_LOCAL";

export const EDIT_BOARD_SERVER_REQUEST = "EDIT_BOARD_SERVER_REQUEST";
export const EDIT_BOARD_SERVER_SUCCESS = "EDIT_BOARD_SERVER_SUCCESS";
export const EDIT_BOARD_SERVER_FAILURE = "EDIT_BOARD_SERVER_FAILURE";

export const DELETE_BOARD_LOCAL = "DELETE_BOARD_LOCAL";

export const DELETE_BOARD_SERVER_REQUEST = "DELETE_BOARD_SERVER_REQUEST";
export const DELETE_BOARD_SERVER_SUCCESS = "DELETE_BOARD_SERVER_SUCCESS";
export const DELETE_BOARD_SERVER_FAILURE = "DELETE_BOARD_SERVER_FAILURE";

export const ADD_COLUMN_TO_COLUMNS_ORDER_LOCAL =
  "ADD_COLUMN_TO_COLUMNS_ORDER_LOCAL";
export const REMOVE_COLUM_FROM_COLUMNS_ORDER_LOCAL =
  "REMOVE_COLUM_FROM_COLUMNS_ORDER_LOCAL";
export const CHANGE_COLUMNS_ORDER_LOCAL = "CHANGE_COLUMNS_ORDER_LOCAL";

export const ENTER_SEARCH_TEXT = "ENTER_SEARCH_TEXT";
export const SET_CURRENT_BOARD_DATA = "SET_CURRENT_BOARD_DATA";

export const INVITE_MEMBER_TO_BOARD_REQUEST = "INVITE_MEMBER_TO_BOARD_REQUEST";
export const INVITE_MEMBER_TO_BOARD_SUCCESS = "INVITE_MEMBER_TO_BOARD_SUCCESS";
export const INVITE_MEMBER_TO_BOARD_FAILURE = "ADD_MEMBER_TO_BOARD_FAILURE";

export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST";
export const ACCPET_INVITE_SUCCESS = "ACCPET_INVITE_SUCCESS";
export const ACCEPT_INVITE_FAILURE = "ACCEPT_INVITE_FAILURE";

export const DECLINE_INVITE_REQUEST = "DECLINE_INVITE_REQUEST";
export const DECLINE_INVITE_SUCCESS = "DECLINE_INVITE_SUCCESS";
export const DECLINE_INVITE_FAILURE = "DECLINE_INVITE_FAILURE";

export const REMOVE_MEMBER_FROM_BOARD_REQUEST =
  "REMOVE_MEMBER_FROM_BOARD_REQUEST";
export const REMOVE_MEMBER_FROM_BOARD_SUCCESS =
  "REMOVE_MEMBER_FROM_BOARD_SUCCESS";
export const REMOVE_MEMBER_FROM_BOARD_FAILURE =
  "REMOVE_MEMBER_FROM_BOARD_FAILURE";

export const GET_USER_EMAIL = "GET_USER_EMAIL";

export const UPDATE_BOARD_MEMBERS = "UPDATE_BOARD_MEMBERS";

export const SET_LOCATION = "SET_LOCATION";
export const TEAM = "TEAM";
export const BOARD = "BOARD";
