import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { closeModal } from '../../redux/index';
import {
  BOARD_MODAL,
  COLUMN_MODAL,
  CARD_MODAL,
  USER_MODAL,
  ERROR_MODAL,
  INFO_MODAL,
  CALENDAR_MODAL,
  USERBOARDS_MODAL,
  USER_PROFILE,
  PIN_MODAL,
  PRICING_MODAL,
  TEAM_MODAL,
  TEAM_USER_MODAL,
  SUBSCRIBE_PLAN_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
} from '../../redux/modal/modalTypes';

import BoardModal from './board-modal/BoardModal';
import ColumnModal from './column-modal/ColumnModal';
import CardModal from './card-modal/CardModal';
import UserModal from './user-modal/UserModal';
import CalendarModal from './CalendarModal/CalendarModal';
import ErrorModal from './ErrorModal/ErrorModal';
import InfoModal from './InfoModal/InfoModal';
import BoardUserMdal from './BoardUsersModal/BoardUsersModal';
import UserProfile from './UserProfile/UserProfile';
import PinModal from './PinModal/PinModal';
import TeamModal from './team-modal/TeamModal';
import TeamUserModal from './user-modal/TeamUserModal';
import SubscriptionModal from './SubscriptionModal/SubscriptionModal';
import CancelSubscriptionModal from './CancelSubscriptionModal/CancelSubscriptionModal';

import './modal.css';
import PricingModal from './PricingModal/PricingModal';

const Modal = React.memo(
  ({
    closeModal,
    modalType,
    currentBoardId,
    history,
    actionType,
    isCardSaveState,
    showModal,
  }) => {
    switch (modalType) {
      case BOARD_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <BoardModal />
          </div>
        );

      case TEAM_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <TeamModal />
          </div>
        );

      case CARD_MODAL:
        return (
          <div className="modal-wrapper">
            {isCardSaveState === false && (
              <div
                className="modal-close-overlay"
                onClick={() => {
                  history.push(`/board/${currentBoardId}`);
                  closeModal();
                }}
              ></div>
            )}
            <CardModal />
          </div>
        );

      case COLUMN_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <ColumnModal />
          </div>
        );

      case USER_PROFILE:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <UserProfile />
          </div>
        );

      case USER_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <UserModal />
          </div>
        );

      case SUBSCRIBE_PLAN_MODAL:
        return (
          <div className="modal-wrapper">
            <div className="modal-close-overlay"></div>

            <SubscriptionModal />
          </div>
        );

      case CANCEL_SUBSCRIPTION_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>

            <CancelSubscriptionModal />
          </div>
        );

      case TEAM_USER_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <TeamUserModal />
          </div>
        );

      case PIN_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <PinModal />
          </div>
        );

      case CALENDAR_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <CalendarModal />
          </div>
        );

      case ERROR_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                history.push(`/boards`);
                closeModal();
              }}
            ></div>
            <ErrorModal />
          </div>
        );
      case INFO_MODAL:
        return (
          <div className="modal-wrapper">
            <div className="modal-close-overlay" onClick={closeModal}></div>
            <InfoModal />
          </div>
        );

      case PRICING_MODAL:
        return (
          <div className="modal-wrapper">
            <div className="modal-close-overlay" onClick={closeModal}></div>
            <PricingModal />
          </div>
        );

      case USERBOARDS_MODAL:
        return (
          <div className="modal-wrapper">
            <div
              className="modal-close-overlay"
              onClick={() => {
                closeModal();
              }}
            ></div>
            <BoardUserMdal />
          </div>
        );

      default:
        return null;
    }
  }
);

const mapStateToProps = (state) => {
  return {
    modalType: state.modal.modalType,
    actionType: state.modal.modalActionType,
    currentBoardId: state.board.selectBoard?.id,
    isCardSaveState: state.modal.isSaveBtn,
    showModal: state.modal.showModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Modal));
