import React from 'react';

import { AiFillCheckCircle } from 'react-icons/ai';
import { CiCircleRemove } from 'react-icons/ci';

const returnEndDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

const PremiumCard = ({
  showCancelSubscriptionModal,
  createPaymentIntent,
  loadingObj,
  user,
}) => {
  return (
    <div
      className={`pricing-card ${
        user.plan.title === 'Premium' && 'current-plan-border'
      }`}
    >
      <div className="pricing-plan-name">Premium</div>
      <div className="pricing-cost">$15 / mo. per user</div>
      <div className="pricing-features-holder">
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">50 Boards</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">100 Chat Channels</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">25 File Uploads/board</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">3 Invited Trial Users</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">Video Conferencing</div>
        </div>
        <div className="pricing-feature">
          <CiCircleRemove className="x-red" />
          <div className="pricing-feature-name">No Teams</div>
        </div>
      </div>
      <div className="greeting-buttons-container">
        {user.plan.title === 'Basic' && (
          <button
            className="modal-board-button free-trial"
            disabled={loadingObj.loading}
            onClick={() => {
              createPaymentIntent(
                15,
                'price_1Mz3ayBDlwLcpDtHiTBU2PeC',
                'KEVVLAR_PREMIUM',
                'Premium'
              );
            }}
          >
            {loadingObj.title === 'Premium' && loadingObj.loading
              ? 'Setting Up...'
              : 'Subscribe'}
          </button>
        )}

        {/* {user.plan.title === 'Team' && (
          <button
            className="modal-board-button free-trial"
            disabled={loadingObj.loading}
            onClick={() => {
              createPaymentIntent(
                15,
                'price_1Mz3ayBDlwLcpDtHiTBU2PeC',
                'KEVVLAR_PREMIUM',
                'Premium'
              );
            }}
          >
            {loadingObj.title === 'Premium' && loadingObj.loading
              ? 'Setting Up...'
              : 'Downgrade'}
          </button>
        )} */}

        {user.plan.title === 'Premium' && (
          <div className="current-plan">Your Current Plan</div>
        )}

        {user.plan.title === 'Premium' && user.stripeCancelAt !== null && (
          <div className="sub-ends-text">
            Ends: {returnEndDate(user.stripeCancelAt)}
          </div>
        )}

        {user.plan.title === 'Premium' && user.stripeCancelAt === null && (
          <div
            className="cancel-sub-text"
            onClick={() => {
              showCancelSubscriptionModal();
            }}
          >
            Cancel Subscription
          </div>
        )}
      </div>
    </div>
  );
};

export default PremiumCard;
