import React from "react";

import "./userList.css";

const ViewUserList = ({ userList }) => {
  if (!userList) {
    return null;
  }

  return (
    <div className="user-list-container">
      {userList?.map((userItem, index) => (
        <li className="assign-user-list-item" key={index}>
          <div className="assign-user-list-container">
            <img
              className="user-avatar-image assign-user-image"
              alt="img"
              src={userItem?.photo}
              title={userItem?.name}
            />
            <div>
              <div className="assign-user-list-name">{userItem?.name}</div>
              <div className="assign-user-list-email">{userItem?.email}</div>
            </div>
          </div>
        </li>
      ))}
    </div>
  );
};

export default ViewUserList;
