import React, { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { connect } from 'react-redux';
import PreviewItem from './PreviewItem';
import { FaTrash } from 'react-icons/fa';

import { setErrorModal, setActiveChannel } from '../../redux';

const TeamChannelPreview = ({
  setActiveChannel,
  setIsCreating,
  setIsEditing,
  channel,
  type,
  setToggleContainer,
  showErrorModal,
  setChannelInUse,
  ...props
}) => {
  const { client, channel: activeChannel } = useChatContext();

  const handleDelete = async () => {
    try {
      await channel.delete();
    } catch (error) {
      showErrorModal('Sorry you do not have rights to perform this action.');
    }
  };

  const handleDeleteDM = async () => {
    try {
      await channel.delete();
    } catch (error) {
      showErrorModal('Sorry you do not have rights to perform this action.');
    }
  };

  const Indicator = ({ count }) => (
    <span
      style={{
        backgroundColor: '#03a9f4',
        width: '15px',
        color: 'black',
        marginLeft: '10px',
        textAlign: 'center',
        borderRadius: '50%',
        fontSize: '13px',
      }}
    >
      {count}
    </span>
  );

  const ChannelPreview = () => (
    <div
      className={`channel-main-container`}
      onClick={() => {
        setChannelInUse(channel.data.id);
      }}
    >
      <p className="channel-preview__item">
        # {channel.data.name}
        {(channel.state.unreadCount && channel?.id !== activeChannel?.id) >
          0 && <Indicator count={channel.state.unreadCount} />}
      </p>
      <FaTrash className="del-channel-icon" onClick={handleDelete} />
    </div>
  );

  const DirectPreview = () => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    useEffect(() => {
      const members = Object.values(channel.state.members);
      const users = members.filter((user) => user.user_id !== client.userID);
      setFilteredUsers(users);
    }, []);

    return (
      <>
        {filteredUsers.map((filteredUser, index) => (
          <div className="direct-messages-container" key={index}>
            <PreviewItem filteredUser={filteredUser} />
            {props.unread > 0 && <Indicator count={props.unread} />}
            {channel.data.created_by.id === client.userID && (
              <FaTrash
                className="del-channel-icon-dm"
                onClick={handleDeleteDM}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__wrapper_selected'
          : 'channel-preview__wrapper'
      }
      onClick={() => {
        setIsCreating(false);
        setIsEditing(false);
        setActiveChannel(channel);
        if (setToggleContainer) {
          setToggleContainer((prevState) => !prevState);
        }
      }}
    >
      {type === 'team' ? <ChannelPreview /> : <DirectPreview />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorModal: (msg) => dispatch(setErrorModal(msg)),
    setChannelInUse: (channel) => dispatch(setActiveChannel(channel)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamChannelPreview);
