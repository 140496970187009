import React, { useState } from 'react';
import { Chat } from 'stream-chat-react';
import { connect } from 'react-redux';
import { Resizable } from 're-resizable';

import ChannelListContainer from '../../components/Chat/ChannelListContainer';
import ChannelContainer from '../../components/Chat/ChannelContainer';

import './chatPage.css';

const isMobile = /iPhone|iPad|iPod|Android|Windows Phone/i.test(
  navigator.userAgent
);

const ChatPage = ({ client, user }) => {
  const [createType, setCreateType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (!client.user) {
    client.connectUser(
      {
        id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        image: user.photo,
        email: user.email,
      },
      user.chatToken
    );
  }

  const onResizeStop = (e, dir, el, delta) => {
    const { height, width } = delta;
    const currHeight = localStorage.getItem('chatHeight') * 1 || 600;
    const currWidth = localStorage.getItem('chatWidth') * 1 || 950;

    const newHeight = currHeight + height;
    const newWidth = currWidth + width;

    localStorage.setItem('chatWidth', newWidth);
    localStorage.setItem('chatHeight', newHeight);
  };

  if (!isMobile) {
    return (
      <Resizable
        defaultSize={{
          width: localStorage.getItem('chatWidth') || 950,
          height: localStorage.getItem('chatHeight') || 600,
        }}
        minWidth={950}
        minHeight={600}
        maxHeight={1200}
        maxWidth={1600}
        enable={{
          top: true,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: true,
        }}
        onResizeStop={onResizeStop}
      >
        <div className="chat-main-holder">
          <Chat client={client} theme="team light">
            <ChannelListContainer
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              setCreateType={setCreateType}
              setIsEditing={setIsEditing}
            />
            <ChannelContainer
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              createType={createType}
            />
          </Chat>
        </div>
      </Resizable>
    );
  }

  return (
    <div className="chat-page-container">
      <div className="chat-main-holder">
        <Chat client={client} theme="team light">
          <ChannelListContainer
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            setCreateType={setCreateType}
            setIsEditing={setIsEditing}
          />
          <ChannelContainer
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            createType={createType}
          />
        </Chat>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

export default connect(mapStateToProps, null)(ChatPage);
