import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { closeModal, setInfoModal, fetchUserPlan } from '../../../redux';

import SERVER_URL from '../../../redux/URL';

import '../modal.css';

const CancelSubscriptionModal = ({
  closeModal,
  showInfoModal,
  user,
  getUserPlan,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = () => {
    setLoading(true);
    axios
      .post(
        `${SERVER_URL}/api/v1/users/cancel-customer-subscription`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((result) => {
        setLoading(false);
        closeModal();
        showInfoModal(`Subscription cancellation Successful.`);
      })
      .catch((error) => {
        setLoading(false);
        closeModal();
        console.log(error);
        showInfoModal(`Subscription cancellation failed.`);
      });
  };

  return (
    <div className="modal-error">
      <div className="close-icon-container">
        {!loading && <FaTimes onClick={closeModal} className="close-icon" />}
        <div className="modal-body">
          <h2 className="modal-title">Cancel Subscription</h2>
          <p className="align-text-center">
            Are you sure you want to cancel your subscription? <br />
            You will keep your current subscription until the last day on your
            account is up.
          </p>
          <button
            className="delete-button"
            onClick={handleCancelSubscription}
            disabled={loading}
          >
            {loading ? 'Canceling...' : 'Cancel Plan'}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    info: state.modal.infoMessage,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    showInfoModal: (message) => dispatch(setInfoModal(message)),
    getUserPlan: (token) => dispatch(fetchUserPlan(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CancelSubscriptionModal));
