import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import leftSideNavReducer from "./left-side-nav/leftSideNavReducer";
import rightSideNavReducer from "./right-side-nav/rightSideNavReducer";
import activityReducer from "./activity/activityReducer";
import fileReducer from "./file/fileReducer";
import modalReducer from "./modal/modalReducer";
import boardReducer from "./board/boardReducer";
import columnReducer from "./column/columnReducer";
import userReducer from "./user/userReducer";
import chatReducer from "./chat/chatReducer";
import calendarReducer from "./calendar/calendarReducer";
import pinReducer from "./pin/pinReducer";
import authReducer from "./auth/authReducer";
import emailSubscriptionReducer from "./email-subscription/subscriptionReducer";
import planSubscriptionReducer from './plan-subscription/subscriptionReducer'
import timeoutReducer from "./timeout/timeoutReducer";
import usersReducer from "./admin/users/usersReducer";
import adminUserReducer from "./admin/user/userReducer";
import teamsReducer from "./team/teamReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  sideNavLeft: leftSideNavReducer,
  sideNavRight: rightSideNavReducer,
  modal: modalReducer,
  board: boardReducer,
  team: teamsReducer,
  column: columnReducer,
  user: userReducer,
  _user: adminUserReducer,
  activity: activityReducer,
  file: fileReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  pin: pinReducer,
  auth: authReducer,
  emailSubscription: emailSubscriptionReducer,
  planSubscription: planSubscriptionReducer,
  timeout: timeoutReducer,
  users: usersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
