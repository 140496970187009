import {
  TOGGLE_PIN_MODAL,
  ADD_PIN_REQUEST,
  ADD_PIN_SUCCESS,
  ADD_PIN_FAILURE,
  FETCH_PINS_REQUEST,
  FETCH_PINS_SUCCESS,
  FETCH_PINS_FAILURE,
  DELETE_PIN_REQUEST,
  DELETE_PIN_SUCCESS,
  DELETE_PIN_FAILURE,
  CLEAR_PINS,
} from './pinTypes';
import axios from 'axios';

import SERVER_URL from '../URL';

export const togglePinDropdown = () => {
  return {
    type: TOGGLE_PIN_MODAL,
  };
};

export const addPinRequest = () => {
  return {
    type: ADD_PIN_REQUEST,
  };
};

export const addPinSuccess = () => {
  return {
    type: ADD_PIN_SUCCESS,
  };
};

export const addPinFailure = (error) => {
  return {
    type: ADD_PIN_FAILURE,
    payLoad: error,
  };
};

export const fetchPinsRequest = () => {
  return {
    type: FETCH_PINS_REQUEST,
  };
};

export const fetchPinsSuccess = (pins) => {
  return {
    type: FETCH_PINS_SUCCESS,
    payLoad: pins,
  };
};

export const fetchPinsFailure = (error) => {
  return {
    type: FETCH_PINS_FAILURE,
    payLoad: error,
  };
};

export const deletePinRequest = () => {
  return {
    type: DELETE_PIN_REQUEST,
  };
};

export const deletePinSuccess = (pinId) => {
  return {
    type: DELETE_PIN_SUCCESS,
    payLoad: pinId,
  };
};

export const deletePinFailure = (error) => {
  return {
    type: DELETE_PIN_FAILURE,
    payLoad: error,
  };
};

export const fetchPins = (token, params) => {
  return (dispatch) => {
    axios
      .get(`${SERVER_URL}/api/v1/pins`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const pins = response.data.data.pins;
        dispatch(fetchPinsSuccess(pins));
      })
      .catch((error) => {
        dispatch(fetchPinsFailure(error.message));
      });
  };
};

export const createPin = (token, boardId, data) => {
  return (dispatch) => {
    dispatch(addPinRequest());
    axios
      .post(`${SERVER_URL}/api/v1/pins`, data, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          boardId,
        },
      })
      .then(() => {
        dispatch(addPinSuccess());
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(addPinFailure(error.message));
      });
  };
};

export const handleDeletePin = (token, boardId, pinId) => {
  return (dispatch) => {
    dispatch(deletePinRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/pins/${pinId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          boardId,
        },
      })
      .then(() => {
        dispatch(deletePinSuccess(pinId));
      })
      .catch((error) => {
        dispatch(deletePinFailure(error.message));
      });
  };
};

export const clearPins = () => {
  return {
    type: CLEAR_PINS,
  };
};
