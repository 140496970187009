import React from "react";
import { connect } from "react-redux";

import PinItem from "../PinItem/PinItem";

import { togglePinDropdown, setPinModal } from "../../redux";

import "./pinDropdown.css";

const PinDropdown = ({ showPins, showPinModal, pins }) => {
  return (
    <div>
      <div className="pins-close-wrapper" onClick={() => showPins()}></div>
      <div className="pin-profile-menu">
        <div className="pin-main-title">Pins</div>
        <div className="pin-item-wrapper">
          {pins.map((pin) => (
            <PinItem key={pin._id} pin={pin} />
          ))}
        </div>
        <div
          className="new-pin-text"
          onClick={() => {
            showPins();
            showPinModal();
          }}
        >
          New pin
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    board: state.board.selectBoard,
    pins: state.pin.pins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showPins: () => dispatch(togglePinDropdown()),
    showPinModal: () => dispatch(setPinModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinDropdown);
