import React, { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';

import ResultsDropdown from './ResultsDropdown';
import { SearchIcon } from '../../assets/SearchIcon';

const ChannelSearch = ({ setToggleContainer }) => {
  const { client, setActiveChannel } = useChatContext();
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [teamChannels, setTeamChannels] = useState([]);
  const [directChannels, setDirectChannels] = useState([]);

  useEffect(() => {
    if (!query) {
      setTeamChannels([]);
      setDirectChannels([]);
    }
  }, [query]);

  function hasCommonValue(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) {
        return true;
      }
    }
    return false;
  }

  const getChannels = async (text) => {
    try {
      // const userResponse = client.queryUsers({
      //   id: { $ne: client.userID },
      //   name: { $autocomplete: text },
      // });

      const response = await client.queryUsers({
        id: { $ne: client.userID },
        name: { $autocomplete: text },
      });

      if (response.users.length) {
        const responseUsers = response.users.filter((userItem) => {
          const meBoards = client.user.boards || [];
          const userItemBoards = userItem.boards || [];

          if (
            userItem.email !== client.user.email &&
            hasCommonValue(meBoards, userItemBoards)
          ) {
            return true;
          } else {
            return false;
          }
        });

        setDirectChannels(responseUsers);
      }
    } catch (error) {
      setQuery('');
    }
  };

  const onSearch = (event) => {
    event.preventDefault();

    setLoading(true);
    setQuery(event.target.value);
    getChannels(event.target.value);
  };

  const setChannel = (channel) => {
    setQuery('');
    setActiveChannel(channel);
  };

  return (
    <div className="channel-search__container">
      <div className="channel-search__input__wrapper">
        <div className="channel-serach__input__icon">
          <SearchIcon />
        </div>
        <input
          className="channel-search__input__text"
          placeholder="Search"
          type="text"
          value={query}
          onChange={onSearch}
        />
      </div>
      {query && (
        <ResultsDropdown
          teamChannels={teamChannels}
          directChannels={directChannels}
          loading={loading}
          setChannel={setChannel}
          setQuery={setQuery}
          setToggleContainer={setToggleContainer}
        />
      )}
    </div>
  );
};

export default ChannelSearch;
