export const SIGN_UP_USER_REQUEST = 'SIGN_UP_USER_REQUEST';
export const SIGN_UP_USER_SUCCESS = 'SIGN_UP_USER_SUCCESS';
export const SIGN_UP_USER_FAILURE = 'SIGN_UP_USER_FAILURE';

export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';
export const SIGN_IN_USER_SUCCESS = 'SIGN_IN_USER_SUCCESS';
export const SIGN_IN_USER_FAILURE = 'SIGN_IN_USER_FAILURE';

export const UPDATE_PROFILE_WITH_IMG_REQUEST =
  'UPDATE_PROFILE_WITH_IMG_REQUEST';
export const UPDATE_PROFILE_WITH_IMG_SUCCESS =
  'UPDATE_PROFILE_WITH_IMG_SUCCESS';
export const UPDATE_PROFILE_WITH_IMG_FAILIURE =
  'UPDATE_PROFILE_WITH_IMG_FAILIURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const TURN_OFF_NOTIFY = 'TURN_OFF_NOTIFY';
export const SET_NOTIFY_STATUS = 'SET_NOTIFY_STATUS ';
export const IS_READ = 'IS_READ';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const LOG_USER_OUT = 'LOG_USER_OUT';

export const SET_USER_PLAN = 'SET_USER_PLAN';
