import axios from "axios";
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_VERIFICATION_REQUEST,
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_FAILURE,
  SET_AUTH_EMAIL,
  CLEAR_AUTH,
} from "./authTypes";

import SERVER_URL from "../URL";

export const forgotPasswordRequest = () => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
};

export const forgotPasswordSuccess = (email) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payLoad: email,
  };
};

export const forgotPasswordFailure = (error) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payLoad: error,
  };
};

export const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};

export const resetPasswordSuccess = (email) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payLoad: email,
  };
};

export const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payLoad: error,
  };
};

export const verifyEmailRequest = () => {
  return {
    type: VERIFY_EMAIL_REQUEST,
  };
};

export const verifyEmailSuccess = (email) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payLoad: email,
  };
};

export const verifyEmailFailure = (error) => {
  return {
    type: VERIFY_EMAIL_FAILURE,
    payLoad: error,
  };
};

export const resendVerificationTokenRequest = () => {
  return {
    type: RESEND_VERIFICATION_REQUEST,
  };
};

export const resendVerificationTokenSuccess = (email) => {
  return {
    type: RESEND_VERIFICATION_SUCCESS,
    payLoad: email,
  };
};

export const resendVerificationTokenFailure = (error) => {
  return {
    type: RESEND_VERIFICATION_FAILURE,
    payLoad: error,
  };
};

export const setAuthMail = (email) => {
  return {
    type: SET_AUTH_EMAIL,
    payLoad: email,
  };
};

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH,
  };
};

export const handleForgotPassword = (emailDetail, history) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest());
    axios
      .post(`${SERVER_URL}/api/v1/users/forgot-password`, emailDetail, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data.user));
        history.push("/reset-password");
      })
      .catch((error) => {
        dispatch(forgotPasswordFailure(error.response.data.message));
      });
  };
};

export const handleResetPassword = (resetDetails, history) => {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/users/reset-password`, resetDetails, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data.user));
        history.push("/signin");
        dispatch(clearAuth());
      })
      .catch((error) => {
        dispatch(resetPasswordFailure(error.response.data.message));
      });
  };
};

export const handleVerifyEmail = (verificationDetails, history) => {
  return (dispatch) => {
    dispatch(verifyEmailRequest());
    axios
      .post(`${SERVER_URL}/api/v1/users/verify-email`, verificationDetails, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        dispatch(verifyEmailSuccess(response.data.email));
        dispatch(clearAuth());
        history.push("/signin");
      })
      .catch((error) => {
        dispatch(verifyEmailFailure(error.response.data.message));
      });
  };
};

export const handleResendVerificationToken = (resendDetails) => {
  return (dispatch) => {
    dispatch(resendVerificationTokenRequest());
    axios
      .post(`${SERVER_URL}/api/v1/users/resend-token`, resendDetails, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        dispatch(resendVerificationTokenSuccess(response.data.email));
      })
      .catch((error) => {
        dispatch(resendVerificationTokenFailure(error.response.data.message));
      });
  };
};
