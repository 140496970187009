import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { closeModal, removeMemberFromBoard } from '../../../redux';

import './boardUsersModal.css';

const BoardUserModal = ({
  user,
  currrentBoardId,
  removeMember,
  admins,
  members,
  ownerId,
}) => {
  const users = [...admins, ...members];

  const handleRemoveMember = (userEmail) => {
    removeMember(
      user.token,
      {
        boardId: currrentBoardId,
        userEmail,
      },
      { boardId: currrentBoardId }
    );
  };

  return (
    <div className="manage-users-modal-board">
      <div className="close-icon-container"></div>
      <div className="modal-body">
        <h2 className="modal-title">
          {user._id === ownerId ? 'Manage Users' : 'All Users'}
        </h2>
        <div className="manage-user-container">
          {users.map((userItem, index) => (
            <div className="manage-users-holder" key={index}>
              <div className="assign-user-list-container">
                <img
                  className="user-avatar-image assign-user-image"
                  alt="img"
                  src={userItem?.photo}
                  title={userItem?.name}
                />
                <div>
                  <div className="assign-user-list-name">
                    {userItem?.firstName + ' ' + userItem?.lastName}
                  </div>
                  <div className="assign-user-list-email">
                    {userItem?.email + ' - ' + userItem?.plan?.title + ' Plan'}
                  </div>
                </div>
              </div>
              {ownerId === user._id && (
                <button
                  className="delete-button"
                  style={{ margin: '0px' }}
                  onClick={() => handleRemoveMember(userItem.email)}
                >
                  Remove User
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    user: state.user.userData,
    currrentBoardId: state.board.selectBoard.id,
    admins: state.board.selectBoard.admins,
    members: state.board.selectBoard.members,
    ownerId: state.board.selectBoard.owner._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    removeMember: (token, removeObj, params) =>
      dispatch(removeMemberFromBoard(token, removeObj, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BoardUserModal));
