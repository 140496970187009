import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CardTitle from '../../CardTitle/CardTitle';
import CardDetails from '../../CardDetails/CardDetails';
import CardLength from '../../CardLength/CardLength';
import CardDatePicker from '../../CardDatePicker/CardDatePicker';
import AssignUserDropDown from '../../AssignUserDropDown/AssignUserDropDown';
import ColorLabelDropDown from '../../ColorLabelDropDown/ColorLabelDropDown';
import AssignedUsers from '../../AssignedUsers/AssignedUsers';

import {
  editCardServer,
  deleteCardServer,
  toggleSocketModal,
  toggleCardSaveBtn,
} from '../../../redux';
import CardModalSaveButton from '../../CardModalSaveButton/CardModalSaveButton';
import CopyLinkButton from '../../CardModalSaveButton/CopyLinkButton';

import socket from '../../../Socket';

const EditCardModal = ({
  updateCardServer,
  handleDeleteCardServer,
  user,
  currentBoardId,
  teamId,
  currentCard,
  currentColumn,
  admins = [],
  members = [],
  history,
  isMe,
  searchKeyWord,
  showSocketModal,
  isLoading,
  enableSaveCardBtn,
  isCardSaveState,
}) => {
  let boardUsers = [];

  if (admins?.length > 0) {
    boardUsers = [...boardUsers, ...admins];
  }

  if (members?.length > 0) {
    boardUsers = [...boardUsers, ...members];
  }

  const [isDeleting, setIsDeleting] = useState(false);
  const [editCardTitle, setEditCardTitle] = useState(currentCard.title);
  const [editCardBody, setEditCardBody] = useState(currentCard.description);
  const [editCardDate, setEditCardDate] = useState(currentCard?.date);
  const [editCardColor, setEditCardColor] = useState(currentCard.colorLabel);
  const [editCardUsers, setEditCardUsers] = useState(currentCard?.users || []);
  const [titleLength, setTitleLength] = useState(currentCard?.title?.length);
  const [newlyAssignedUsers, setNewlyAssignedUsers] = useState([]);
  const [bodyLength, setBodyLength] = useState(
    currentCard?.description?.length
  );
  const [rawDescData, setRawDescData] = useState('');

  const handleFormatUser = (cardUsers) => {
    if (typeof cardUsers?.at(0) === 'object') {
      return cardUsers.map((user) => user._id);
    } else if (typeof cardUsers?.at(0) === 'string') {
      return cardUsers;
    } else {
      return [];
    }
  };

  const [newCheckedUsers, setNewCheckedUsers] = useState(
    handleFormatUser(currentCard?.users)
  );

  function stripHTMLTags(html) {
    if (!html) return;
    const stringWithoutTags = html.replace(/<\/?[^>]+(>|$)/g, '');

    // Remove all empty spaces using a regular expression
    const stringWithoutSpaces = stringWithoutTags.replace(/\s+/g, '');

    return stringWithoutSpaces;
  }

  const prevTitleLength = currentCard?.title?.length;
  const prevDescLength = stripHTMLTags(currentCard?.description)?.length;
  const prevColor = currentCard?.colorLabel;
  const prevUserList = handleFormatUser(currentCard?.users);
  const prevDate = currentCard?.date;

  const arraysEqualAndSameLength = useCallback((arr1, arr2) => {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort the arrays so that the strings are in the same order
    arr1.sort();
    arr2.sort();

    // Compare each element of the sorted arrays
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    // If all elements match and the arrays are of the same length, return true
    return true;
  }, []);

  useEffect(() => {
    if (
      stripHTMLTags(editCardBody)?.length !== prevDescLength ||
      editCardTitle?.length !== prevTitleLength ||
      prevColor !== editCardColor ||
      !arraysEqualAndSameLength(prevUserList, newCheckedUsers) ||
      prevDate !== editCardDate
    ) {
      enableSaveCardBtn(true);
    } else {
      enableSaveCardBtn(false);
    }
  }, [
    rawDescData,
    editCardTitle,
    editCardColor,
    newCheckedUsers,
    editCardDate,
    arraysEqualAndSameLength,
  ]);

  const handleSubmit = () => {
    if (titleLength + bodyLength > 1000000 || isCardSaveState === false) return;
    const cardObj = {
      id: currentCard.id,
      columnId: currentColumn.id,
      columnTitle: currentColumn.title,
      title: editCardTitle,
      description: editCardBody,
      date: editCardDate,
      colorLabel: editCardColor,
      users: newCheckedUsers,
      newUsers: newlyAssignedUsers.map((user) => user._id),
    };
    updateCardServer(
      user.token,
      { boardId: currentBoardId, teamId },
      currentCard.id,
      cardObj,
      history,
      newlyAssignedUsers,
      user._id
    );
  };

  const handleDelete = () => {
    if (!socket || !socket.connected) {
      showSocketModal(true);
      return;
    }

    setIsDeleting(true);
    const deleteObj = {
      columnId: currentColumn.id,
      cardId: currentCard.id,
      cardTitle: currentCard.title,
      columnTitle: currentColumn.title,
    };

    handleDeleteCardServer(
      user.token,
      currentBoardId,
      { boardId: currentBoardId, teamId },
      currentCard.id,
      history,
      deleteObj,
      setIsDeleting,
      user._id
    );
  };

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const checkedUser = boardUsers.find(
        (user) => user._id === e.target.value
      );
      setNewCheckedUsers([...newCheckedUsers, checkedUser._id]);
      setEditCardUsers([...editCardUsers, checkedUser]);
      setNewlyAssignedUsers([...newlyAssignedUsers, checkedUser]);
    } else {
      const unCheckedUser = e.target.value;
      setEditCardUsers(
        editCardUsers.filter((user) => user._id !== unCheckedUser)
      );
      setNewCheckedUsers(
        newCheckedUsers.filter((userId) => userId !== unCheckedUser)
      );
      setNewlyAssignedUsers(
        newlyAssignedUsers.filter((user) => user._id === unCheckedUser)
      );
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!socket || !socket.connected) {
          showSocketModal(true);
          return;
        }
        handleSubmit();
      }}
      className="modal-body"
    >
      <h2 className="modal-title">Edit Card</h2>
      <div className="big-card-container">
        <div className="input-description-container">
          <CardTitle
            setCardTitle={setEditCardTitle}
            setTitleLength={setTitleLength}
            cardTitle={editCardTitle}
          />
          <CardDetails
            bodyText={editCardBody}
            setCardBody={setEditCardBody}
            setBodyLength={setBodyLength}
            setRawDescData={setRawDescData}
          />
          <CardLength titleLength={titleLength} bodyLength={bodyLength} />
        </div>
        <div className="modal-footer-container">
          <div className="action-buttons-container">
            <CardDatePicker
              setCardDate={setEditCardDate}
              cardDate={editCardDate}
            />
            <AssignUserDropDown
              users={boardUsers}
              handleChange={handleChange}
              currentUsers={newCheckedUsers}
            />
            <ColorLabelDropDown
              setCardLabel={setEditCardColor}
              editCardColor={editCardColor}
            />
            <CopyLinkButton />
            <AssignedUsers assignedUsers={newCheckedUsers} />
          </div>

          <div className="save-delete-container">
            {!isDeleting && (
              <CardModalSaveButton
                titleLength={titleLength}
                bodyLength={bodyLength}
                editCardColor={editCardColor}
                isLoading={isLoading}
              />
            )}
            {isLoading ? null : (
              <button
                disabled={isDeleting}
                className="delete-button"
                onClick={handleDelete}
              >
                {isDeleting ? 'Deleting' : 'Delete'}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    currentBoardId: state.board.selectBoard?.id,
    currentCard: state.column.selectCard,
    currentColumn: state.column.selectColumn,
    admins: state.board.selectBoard?.admins,
    members: state.board.selectBoard?.members,
    isMe: state.column.isMe,
    searchKeyWord: state.column.cardSearchKeyWord,
    isLoading: state.column.loading,
    teamId: state.team.selectTeam._id,
    isCardSaveState: state.modal.isSaveBtn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
    enableSaveCardBtn: (state) => dispatch(toggleCardSaveBtn(state)),
    updateCardServer: (
      token,
      params,
      cardId,
      cardObj,
      history,
      newUsers,
      userId
    ) =>
      dispatch(
        editCardServer(
          token,
          params,
          cardId,
          cardObj,
          history,
          newUsers,
          userId
        )
      ),
    handleDeleteCardServer: (
      token,
      boardId,
      params,
      cardId,
      history,
      deleteObj,
      setDeleting,
      userId
    ) =>
      dispatch(
        deleteCardServer(
          token,
          boardId,
          params,
          cardId,
          history,
          deleteObj,
          setDeleting,
          userId
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditCardModal));
