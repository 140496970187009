import {
  TOGGLE_ACTIVITY,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  CLEAR_ACTIVITIES,
} from "./activityTypes";

const initialState = {
  showActivity: false,
  activities: [],
  error: "",
  loading: false,
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACTIVITY:
      return {
        ...state,
        showActivity: !state.showActivity,
      };

    case FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        activities: [],
      };

    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payLoad,
      };

    case FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payLoad,
      };

    case CLEAR_ACTIVITIES:
      return {
        ...state,
        showActivity: false,
        activities: [],
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default activityReducer;
