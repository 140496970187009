import React from "react";
import { connect } from "react-redux";
import { LoadingIcon } from "../../assets/svg/iconlibrary";
import { withRouter } from "react-router-dom";

import { fetchBoard } from "../../redux";

import "./privacyPage.css";

class PrivacyPage extends React.Component {
  componentDidMount() {
    window.ononline = (event) => {
      this.props.getBoard(this.props.user.token, this.props.board.id);
      this.props.history.goBack();
    };
  }

  render() {
    return (
      <div className="terms-page">
        <p class="c14"><span class="c4">Kevvlar&rsquo;s Privacy Policy</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Effective: September 1, 2023.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Kevvlar, LLC, and its affiliates (&ldquo;</span><span class="c4">Kevvlar</span><span
            class="c0">,&rdquo; or &ldquo;</span><span class="c4">we</span><span class="c0">&rdquo;) are dedicated to
            protecting and respecting the privacy of your personal information through our compliance with this policy.
        </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">This privacy policy describes the types of information we may collect from you or
            that you may provide when you visit the website located at </span><span>https://</span><span
            class="c0">kevvlar.com, (our &ldquo;</span><span class="c4">Website</span><span class="c0">&rdquo;) and our
            business product management application (our &ldquo;</span><span class="c4">App</span><span
            class="c0">&rdquo;), and our practices for collecting, using, maintaining, protecting, and disclosing that
            information.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Please read this policy carefully to understand our policies and practices regarding
            your information and how we will treat it. If you do not agree with our policies and practices, your choice
            is not to use our Website or our App. By accessing or using our Website or App, you agree to this privacy
            policy. We may change this policy from time to time, which shall be effective when posted. Your continued
            use of our Website or App after we make changes is deemed to be acceptance of those changes, so please check
            the policy periodically for updates. Your continued use of the Website or App following any change shall
            constitute acceptance of that change. The most current version of this privacy policy will be available on
            the Website and can be accessed via the App and will supersede all previous versions of this privacy policy.
        </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">It is our policy to post any changes we make to our privacy policy on this page with
            a notice that the privacy policy has been updated on the Website home page and/or via email to the email
            address associated with your user account. If we make material changes to how we treat our users&#39;
            personal information, we will notify you through a notice on the Website home page and/or via email to the
            email address associated with your user account. The date the privacy policy was last revised is identified
            at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable
            email address for you, and you are also responsible for periodically visiting our Website and this privacy
            policy to check for any changes.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">You agree not to use any device, software or routine to interfere or attempt to
            interfere with the proper working of the Website and App or postings on the Website and App. </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c9">Your use of our Website and App indicates that you have read and accepted the privacy
            policy as outlined below.</span><span class="c0">&nbsp;If you have any questions regarding this privacy
            policy, you can contact us at info@kevvlar.com.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c15 c9">Children Under the Age of 13</span><span class="c4">:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Our Website and App are not intended for, marketed, or targeted at children under 13,
            and we do not knowingly or intentionally collect information about children under 13. If you believe that we
            have collected information about a child under 13, please contact us at the email address listed above so
            that we may delete the information.</span></p>
    <p class="c2"><span class="c0"></span></p><a id="id.gjdgxs"></a>
    <p class="c3"><span class="c6">Information We Collect About You and How We Collect It:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">We collect several types of information from and about users of our Website and App,
            including information:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_1-0 start">
        <li class="c1 li-bullet-0"><span class="c0">By which you may be personally identified, such as name, postal
                address, email address, telephone number, and IP address (&quot;</span><span class="c4">personal
                identifiable information</span><span class="c0">&quot;);</span></li>
        <li class="c1 li-bullet-0"><span class="c0">That is about you but individually does not identify you, such as
                date and time stamp of login and login attempts; and</span></li>
        <li class="c1 li-bullet-0"><span class="c0">About your internet connection, the equipment you use to access our
                Website or App, and usage details.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Regarding billing and credit card information depending on the
                user&rsquo;s subscription to specific services, products, content, material, and other items. </span>
        </li>
    </ul>
    <p class="c2 c5"><span class="c0"></span></p>
    <p class="c3"><span class="c0">We collect this information:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_1-0">
        <li class="c1 li-bullet-0"><span class="c0">Directly from you when you provide it to us.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Automatically as you navigate through the site or application.
                Information collected automatically may include usage details, including date and time stamp of login
                and login attempt, IP addresses, and information collected through cookies, web beacons and other
                tracking technologies.</span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c15 c9">Use of Information Submitted</span><span class="c9">:</span><span
            class="c0">&nbsp; </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span>During use of the Website or App, you may complete forms, participate in discussions, messaging,
            and other conversations as well as share data using third-party plugins or apps. We may collect various
            types of information from and about users of our Website and App, including personal identifiable
            information, as defined above. You may be required to give personally identifiable information
            (&ldquo;</span><span class="c9">PII</span><span class="c0">&rdquo;). This information you submit is used to
            contact you regarding services and/or related content. We may collect and share aggregated demographic
            information with our partners, affiliates, licensors, and licensees, but this is not linked to any PII. We
            may contact you via email or via United States Postal Service mailing in the future to share information
            about services, information, products, or content provided by Kevvlar. </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span>You also may provide information to be published or displayed (hereinafter, &quot;</span><span
            class="c9">posted</span><span>&quot;) on public areas of the Website or App, or transmitted to other users
            of the Website, App, or third parties (collectively, &quot;</span><span class="c9">User
            Contributions</span><span class="c0">&quot;). Your User Contributions are posted on and transmitted to
            others at your own risk. Please be aware that no security measures are perfect or impenetrable.
            Additionally, we cannot control the actions of other users of the Website or App with whom you may choose to
            share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will
            not be viewed by unauthorized persons.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c15 c9">Adjust Personal Settings or Delete Account</span><span class="c4">: &nbsp;</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">You always have the option of adjusting your email preferences or privacy settings as
            they relate to Kevvlar&rsquo;s use of PII through clicking the link in the email footer to unsubscribe or by
            emailing Kevvlar at info@kevvlar.com. You can review, change, or delete your personal information by
            contacting us at info@kevvlar.com.</span></p>
    <p class="c2"><span class="c0"></span></p><a id="id.30j0zll"></a>
    <p class="c3"><span class="c8">Information We Collect Through Automatic Data Collection Technologies</span><span
            class="c4">:</span></p>
    <p class="c2"><span class="c4"></span></p>
    <p class="c3"><span class="c0">As you navigate through and interact with our Website and App, we may use automatic
            data collection technologies, including but not limited to Google Analytics, to collect certain information
            about your equipment, browsing actions, and patterns, including:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_3-0 start">
        <li class="c1 li-bullet-0"><span class="c0">Details of your visits to our Website or App, including traffic
                data, location data, login and login attempts, and other communication data and the resources that you
                access and use on the Website or App.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Information about your computer and internet connection, including
                your IP address, operating system, and browser type.</span></li>
    </ul>
    <p class="c2 c5"><span class="c0"></span></p>
    <p class="c3"><span class="c0">We also may use these technologies to collect information about your activities on
            our Website or App. Please contact us at info@kevvlar.com for information on how you can opt out of
            behavioral tracking on our Website and App and how we respond to web browser signals and other mechanisms
            that enable consumers to exercise choice about behavioral tracking.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">The information we collect automatically may include personal information. We may
            maintain it with personal information we collect in other ways. It helps us to improve our Website and App
            and to deliver a better and more personalized service, such as by enabling us to:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_3-0">
        <li class="c1 li-bullet-0"><span class="c0">Estimate our audience size and usage patterns.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Recognize you when you return to our Website and App.</span></li>
    </ul>
    <p class="c2 c5"><span class="c0"></span></p>
    <p class="c3"><span class="c0">The technologies we use for this automatic data collection may include
            &ldquo;</span><span class="c4">cookies</span><span class="c0">.&rdquo; A cookie is a piece of data stored on
            your hard drive to help us improve your access to our Website and App and help identify repeat visitors. For
            instance, when we use a cookie to identify you, you would not have to log in with a password more than once,
            thereby saving time while using the Website and App. Cookies can also enable us to track and target your
            interests to enhance the experience on the Website and App. &nbsp;</span></p>
    <p class="c2"><span class="c0"></span></p><a id="id.1fob9te"></a>
    <p class="c3"><span class="c6">How We Use Your Information</span><span class="c4">:</span></p>
    <p class="c2"><span class="c6"></span></p>
    <p class="c3"><span class="c0">We use information that we collect about you or that you provide to us, including any
            personal information:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_1-0">
        <li class="c1 li-bullet-0"><span class="c0">To present our Website, App, services, and contents to you.</span>
        </li>
        <li class="c1 li-bullet-0"><span class="c0">To personalize and optimize our Website, App, services, and content
                to you.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To provide you with information or services that you request from
                us.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">For research and development purposes.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To communicate with you about the Website, App, services, and
                content.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To fulfill any other purpose for which you provide it.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To carry out our obligations and enforce our rights arising from any
                contracts entered into between you and us, including for billing and collection.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To notify you about changes to our Website and App or any products
                or services we offer or provide though it.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To allow you to participate in interactive features on our Website
                and App.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">In any other way we may describe when you provide the
                information.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">For any other purpose with your consent.</span></li>
    </ul>
    <p class="c2 c11"><span class="c0"></span></p><a id="id.3znysh7"></a>
    <p class="c3"><span class="c6">Disclosure of Your Information</span><span class="c4">:</span></p>
    <p class="c2"><span class="c6"></span></p>
    <p class="c3"><span class="c0">We may disclose aggregated information about our users, and information that does not
            identify any individual, without restriction. We may disclose personal information that we collect or you
            provide as described in this privacy policy:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_3-0">
        <li class="c1 li-bullet-0"><span class="c0">To any subsidiaries or affiliates.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To contractors, service providers, and other third parties we use to
                support our business and who are bound by contractual obligations to keep personal information
                confidential and use it only for the purposes for which we disclose it to them.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To a buyer or other successor in the event of a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or transfer of some or all of Kevvlar&rsquo;s
                assets or stock, or whether as a going concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which personal information held by Kevvlar about our Website and App users is among the
                assets transferred.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To fulfill the purpose for which you provide it. </span></li>
        <li class="c1 li-bullet-0"><span class="c0">For any other purpose disclosed by us when you provide the
                information.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">With your consent.</span></li>
    </ul>
    <p class="c2 c5"><span class="c0"></span></p>
    <p class="c3"><span class="c0">We may also disclose your personal information:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_3-0">
        <li class="c1 li-bullet-0"><span class="c0">To comply with any court order, law, or legal process, including to
                respond to any government or regulatory request.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">To enforce or apply our terms of service and other policies,
                guidelines, or agreements, including for billing and collection purposes.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">If we believe disclosure is necessary or appropriate to protect the
                rights, property, or safety of Kevvlar, our customers, or others. </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c9 c15">Linking to Another Website and User Generated Documents</span><span class="c4">:
            &nbsp;</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span>When you are navigating the Website and App, you may have the opportunity to click on links that
            take you outside of Kevvlar&rsquo;s services. Such as to other websites which Kevvlar does not control.
            Kevvlar assumes no liability, obligation, or responsibility for such content, representations, warranties,
            or privacy practices of such websites. Although the Website and App may contain links to sites and content
            in foreign countries, it is intended for use only by those individuals within the United States of
            America.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c9 c10">Disclaimer of Warranty</span><span class="c4">: &nbsp;</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c13">Kevvlar makes no warranties and shall not be liable nor responsible for the use of
            the Website and App or interruption of or error in the services provided by the Website and App under any
            circumstances, including any negligence by Kevvlar.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c10 c9">Limitation of Liability</span><span class="c4">: &nbsp;</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Kevvlar shall not be liable for damages of any kind, including but not limited to as
            actual, punitive, incidental, consequential damages or any damages or costs arising from court costs or
            attorneys&rsquo; fees, that are indirectly related to the use of the Website, App, or other services
            provided by the Website, App, or Kevvlar&rsquo;s other products, contents, services, materials, or other
            items. Certain states do not allow the exclusion of incidental or consequential damages, so this exclusion
            may not apply to you.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c10 c9">Your State Privacy Rights</span><span class="c4">:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">If you are a resident of California, Colorado, Virginia, Utah, Connecticut or Nevada,
            your state&#39;s laws may provide you with specific rights regarding our use of your personal information,
            including:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ul class="c7 lst-kix_list_2-0 start">
        <li class="c1 li-bullet-0"><span class="c0">Confirming whether we process a person&rsquo;s personal
                information.</span></li>
    </ul>
    <ul class="c7 lst-kix_list_1-0">
        <li class="c1 li-bullet-0"><span class="c0">Access to and deletion of certain personal information.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Data portability.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Opting out of personal data processing for targeted advertising and
                sales.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Correct inaccuracies in their personal information, taking into
                account the information&#39;s nature and processing purpose.</span></li>
        <li class="c1 li-bullet-0" id="h.2et92p0"><span class="c0">Opt-out of profiling in furtherance of decisions that
                produce legal or similarly significant effects. </span></li>
    </ul>
    <p class="c2"><span class="c0"></span></p><a id="id.tyjcwt"></a>
    <p class="c3"><span class="c6">Data Security</span><span class="c4">:</span></p>
    <p class="c2"><span class="c4"></span></p>
    <p class="c3"><span class="c0">We have implemented measures designed to secure your personal information from
            accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide
            to us is stored on our secure servers behind firewalls. </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">The safety and security of your information also depends on you. Where we have given
            you (or where you have chosen) a password for access to certain parts of our Website and App, you are
            responsible for keeping this password confidential. We ask you not to share your password with anyone.
        </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">Unfortunately, the transmission of information via the internet is not completely
            secure. Although we do our best to protect your personal information, we cannot guarantee the security of
            your personal information transmitted to our Website and App. Any transmission of personal information is at
            your own risk. We are not responsible for circumvention of any privacy settings or security measures
            contained on the Website or App. </span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c6">How We Store and Secure the Information We Collect</span><span class="c4">:</span>
    </p>
    <p class="c2"><span class="c4"></span></p>
    <p class="c3"><span class="c0">Kevvlar uses industry standard technical and organizational measures to secure the
            information we collect and store from users that access our Website and App. While we implement safeguards
            designed to protect your information, it is important to note that no security system is impenetrable, and
            we cannot guarantee that information, either during transmission through the internet or while stored on our
            systems or otherwise in our care, is absolutely safe from breach or interception by unauthorized
            persons.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c3"><span class="c0">If you use our server(s) or data center(s), you shall be held responsible for
            securing storage and access to the information you put into our App or services. Kevvlar shall not be liable
            nor responsible for any breach of security or unauthorized access to any user information.</span></p>
    <p class="c2"><span class="c0"></span></p><a id="id.3dy6vkm"></a>
    <p class="c3"><span class="c6">Contact Information</span><span class="c4">:</span></p>
    <p class="c2"><span class="c4"></span></p>
    <p class="c3"><span class="c0">To ask questions or comment about this privacy policy and our privacy practices,
            contact us at: </span></p>
    <p class="c3"><span class="c0">info@kevvlar.com. </span></p>
    <p class="c2"><span class="c0"></span></p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.board.selectBoard,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBoard: (token, boardId) => dispatch(fetchBoard(token, boardId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivacyPage));
