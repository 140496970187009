import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';

import { closeModal, createPin } from '../../../redux';

import './pinModal.css';

const PinModal = ({ closeModal, addPin, user, boardId }) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  const handleSubmit = () => {
    addPin(user.token, boardId, {
      title: title,
      url: url,
      user: user._id,
      boardId: boardId,
      timeCreated: moment(),
    });
    setTitle('');
    setUrl('');
    closeModal();
  };

  return (
    <div className="modal-pin">
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="modal-body"
        >
          <h2 className="modal-title">Add New Pin</h2>
          <input
            className="modal-pin-text"
            type="text"
            name="pinTitle"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            placeholder="Title"
          />
          <input
            className="modal-pin-text"
            type="text"
            name="pinUrl"
            value={url}
            required
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL (https://google.com)"
          />
          <button className="modal-board-button" type="submit">
            Add Pin
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boardId: state.board.selectBoard.id,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    addPin: (token, boardId, data) => dispatch(createPin(token, boardId, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinModal);
