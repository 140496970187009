import React, { useState } from 'react';

import '../modal/card-modal/cardModal.css';

const AssignUserDropDown = ({ users, handleChange, currentUsers }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div
      className={`drop-down-button ${showDropDown ? 'is-active' : ''}`}
      onClick={() => {
        setShowDropDown(!showDropDown);
      }}
    >
      <div>Assign User</div>
      <div
        className={`assign-user-wrapper ${
          showDropDown ? 'active-wrapper' : ''
        }`}
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
      ></div>
      <ul className="assign-user-dropdown-list">
        <h2 className="modal-title">Assign a User</h2>
        {users.map((user, index) => (
          <li className="assign-user-list-item" key={index}>
            <label className="assign-user-label-container">
              <div className="assign-user-list-container">
                <img
                  className="user-avatar-image assign-user-image"
                  alt="img"
                  src={user?.photo}
                  title={user?.name}
                />
                <div>
                  <div className="assign-user-list-name">
                    {user?.firstName + ' ' + user?.lastName}
                  </div>
                  <div className="assign-user-list-email">{user?.email}</div>
                </div>
              </div>
              <input
                type="checkbox"
                name={user.email}
                value={user._id}
                defaultChecked={currentUsers?.some((userId) =>
                  userId === user?._id ? true : false
                )}
                onChange={handleChange}
              />
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AssignUserDropDown;
