import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CardSearchBar from '../../components/CardSearchBar/CardSearchBar';
import UserAvatar from '../user-avatar/UserAvatar';
import AdminAvatar from '../admin-avatar/AdminAvatar';
import { TagIcon } from '../../assets/svg/iconlibrary';

import {
  setUserModal,
  clearColumns,
  toggleActivity,
  toggleFileModal,
  fetchFiles,
  toggleAssignedMe,
  resetIsMe,
  clearCardSearchKey,
  setConferenceModal,
  clearEvents,
  setBoardUsersModal,
  togglePinDropdown,
  fetchPins,
  setCurrentBoardData,
  getTimeoutStatus,
  setSelectTeam,
  resetTimeout,
  toggleSocketModal,
} from '../../redux';

import { CONFERENCE_MODAL } from '../../redux/modal/modalTypes';

import {
  BoardIcon,
  AddUserIcon,
  FileshareIcon,
  ActivityIcon,
  MeetingIcon,
} from '../../assets/svg/iconlibrary';

import './boardnavbar.css';
import { useCallback } from 'react';
import socket from '../../Socket';

const BoardNavBar = ({
  board,
  history,
  showUserModal,
  emptyColumns,
  user,
  showActivity,
  showFiles,
  showPins,
  getFiles,
  startMeeting,
  sortByMe,
  clearIsMe,
  isMe,
  clearCardSearch,
  modalType,
  emptyEvents,
  showBoardUsersModal,
  getPins,
  setSelectBoardData,
  setSelectTeamData,
  timeoutStatus,
  teamId,
  timeoutTimestamp,
  resetUserTimeout,
  owner,
  showSocketModal,
}) => {
  const [socketState, setSocketState] = useState(true);

  const checkHourPassed = useCallback(() => {
    const oneHourInMilliseconds = 65 * 60 * 10000; // Convert one hour 5 mintues to milliseconds 60 * 60 * 1000
    const now = Date.now(); // Get the current timestamp in milliseconds
    const timestampInMilliseconds = new Date(timeoutTimestamp).getTime(); // Convert the input timestamp to milliseconds

    return now - timestampInMilliseconds >= oneHourInMilliseconds;
  }, [timeoutTimestamp]);

  useEffect(() => {
    socket.on('connect', () => {
      setSocketState(true);
    });

    socket.on('disconnect', () => {
      setSocketState(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    let interval;
    if (timeoutStatus && modalType !== 'CONFERENCE_MODAL') {
      interval = setInterval(() => {
        if (checkHourPassed()) {
          clearInterval(interval);
          resetUserTimeout();
        }
      }, 300000); // 300000
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    checkHourPassed,
    owner,
    modalType,
    user,
    timeoutStatus,
    resetUserTimeout,
  ]);

  const handleToggleSockets = () => {
    if (socketState) {
      socket.disconnect();
    } else {
      socket.connect();
      socket.emit('newUser', user._id);
      socket.emit('join-board', {
        boardId: board.id,
        user: user._id,
      });
    }
  };

  return (
    <div className="boardnavbar">
      <div className="boardnavbar-group">
        <button
          className="boardnavbar-btn"
          onClick={() => {
            setSelectTeamData({});
            setSelectBoardData({});
            clearCardSearch();
            clearIsMe();
            emptyColumns();
            emptyEvents();
            history.push('/boards');
          }}
        >
          <BoardIcon />
          <div className="boardnavbar-boardtitle">{board?.title}</div>
        </button>

        {user.role === 'admin' && (
          <div className="socket-btn" onClick={handleToggleSockets}>
            {socketState ? '🌐' : '⛔'}
            <input type="checkbox" readOnly checked={socketState} />
          </div>
        )}

        {/* <button className="boardnavbar-btn">
          <LockIcon />
          <div className="boardnavbar-boardtitle">Private</div>
        </button> */}
        {/* <button className="boardnavbar-btn">
          <TeamIcon />
          <div className="boardnavbar-boardtitle">Team Name</div>
        </button> */}

        {board?.admins?.map((admin, index) => (
          <AdminAvatar key={index} admin={admin} />
        ))}
        {board?.members?.slice(0, 2).map((member, index) => (
          <UserAvatar key={index} user={member} />
        ))}
        {board?.members?.length > 2 ? (
          <div
            className="avatar-more"
            title={board?.members?.slice(2).map((member) => member.name)}
            onClick={() => {
              showBoardUsersModal();
            }}
          >
            {'+' + (board?.members?.length - 2)}
          </div>
        ) : null}
        {!board.team && (
          <button
            className="add-user-icon"
            onClick={() => {
              if (!socket || !socket.connected) {
                showSocketModal(true);
                return;
              }
              showUserModal();
            }}
          >
            <AddUserIcon />
          </button>
        )}
      </div>
      <div className="boardnavbar-group">
        <CardSearchBar />
        <div className="assigned-to-me-holder">
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                clearCardSearch();
                sortByMe();
              }}
              readOnly
              checked={isMe}
            />
            <span className="slider round"></span>
          </label>
          <div className="assigned-title">Assigned To Me</div>
        </div>
        {modalType !== CONFERENCE_MODAL && (
          <button
            title={
              timeoutStatus
                ? 'Trial Limit exceeded: Only 1 meeting allowed per hour'
                : null
            }
            className={`boardnavbar-btn ${timeoutStatus && 'disable'}`}
            onClick={() => {
              if (timeoutStatus) return;
              startMeeting();
            }}
          >
            <MeetingIcon />
            <div className="boardnavbar-boardtitle">Meeting</div>
          </button>
        )}
        <button
          className="boardnavbar-btn"
          onClick={() => {
            showActivity();
          }}
        >
          <ActivityIcon />
          <div className="boardnavbar-boardtitle">Activity</div>
        </button>
        <button
          onClick={() => {
            getFiles(user.token, { boardId: board.id, teamId });
            showFiles();
          }}
          className="boardnavbar-btn"
        >
          <FileshareIcon />
          <div className="boardnavbar-boardtitle">Files</div>
        </button>
        <button
          onClick={() => {
            getPins(user.token, { boardId: board.id, teamId });
            showPins();
          }}
          className="boardnavbar-btn"
        >
          <TagIcon />
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    board: state.board.selectBoard,
    boardState: state.board.loading,
    user: state.user.userData,
    activities: state.column.activities,
    isMe: state.column.isMe,
    modalType: state.modal.conference,
    timeoutStatus: state.timeout.timeoutStatus,
    teamId: state.team.selectTeam._id,
    owner: state.board.selectBoard?.owner,
    timeoutTimestamp: state.timeout.timestamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showBoardUsersModal: (type) => dispatch(setBoardUsersModal(type)),
    showUserModal: (type) => dispatch(setUserModal(type)),
    emptyColumns: () => dispatch(clearColumns()),
    showActivity: () => dispatch(toggleActivity()),
    showFiles: () => dispatch(toggleFileModal()),
    showPins: () => dispatch(togglePinDropdown()),
    getFiles: (token, params) => dispatch(fetchFiles(token, params)),
    getPins: (token, params) => dispatch(fetchPins(token, params)),
    startMeeting: () => dispatch(setConferenceModal()),
    sortByMe: () => dispatch(toggleAssignedMe()),
    clearIsMe: () => dispatch(resetIsMe()),
    clearCardSearch: () => dispatch(clearCardSearchKey()),
    emptyEvents: () => dispatch(clearEvents()),
    setSelectBoardData: (board) => dispatch(setCurrentBoardData(board)),
    setSelectTeamData: (team) => dispatch(setSelectTeam(team)),
    getUserTimeoutStatus: (token, modalType) =>
      dispatch(getTimeoutStatus(token, modalType)),
    resetUserTimeout: () => dispatch(resetTimeout()),
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BoardNavBar));
