import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import { subscribeUser } from "../../redux";

import "./emailSubscriptionPage.css";

const SubscribePage = ({ loading = true, handleSubscribeUser }) => {
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      handleSubscribeUser(userId);
    }
  }, [userId, handleSubscribeUser]);

  return (
    <div className="sub-container">
      <h4 className="text-default">Hello, great to see you here</h4>
      {loading ? (
        <p className="text-default">Hang on subscribing you...</p>
      ) : (
        <>
          <p className="text-default">
            Emailing notifications has been turned on
          </p>
          <p className="text-default">
            To unsubscribe click <Link to={`/unsubscribe/${userId}`}>here</Link>
          </p>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.emailSubscription.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubscribeUser: (userId) => dispatch(subscribeUser(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePage);
