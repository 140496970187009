import { connect } from "react-redux";
import { FaTimes } from "react-icons/fa";

import { closeModal, deleteColumnServer } from "../../../redux";

const DeleteColumnModal = ({
  closeModal,
  user,
  boardId,
  teamId,
  currentColumnId,
  deleteCurrentColumnServer,
  isLoading,
}) => {
  return (
    <div className="modal-board">
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        <div className="modal-body">
          <h2 className="modal-title">Are You Sure?</h2>
          <span className="modal-sub-text">
            All information contained in this column will be deleted
          </span>
          <div className="modal-button-container">
            <button
              className="delete-button"
              onClick={() => {
                deleteCurrentColumnServer(
                  user.token,
                  { boardId, teamId },
                  currentColumnId
                );
              }}
            >
              {isLoading ? "Deleting" : "Delete"}
            </button>
            {!isLoading && (
              <button className="modal-cancel-button" onClick={closeModal}>
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.modal.modalActionType,
    user: state.user.userData,
    boardId: state.board.selectBoard.id,
    currentColumnId: state.column.selectColumn.id,
    currentColumnTitle: state.column.selectColumn.title,
    isLoading: state.column.loading,
    teamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    deleteCurrentColumnServer: (token, params, columnId) =>
      dispatch(deleteColumnServer(token, params, columnId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteColumnModal);
