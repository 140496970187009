import axios from 'axios';
import {
  GET_USER_TIMEOUT_STATUS_REQUEST,
  GET_USER_TIMEOUT_STATUS_SUCCESS,
  GET_USER_TIMEOUT_STATUS_ERROR,
  CREATE_TIMEOUT_REQUEST,
  CREATE_TIMEOUT_SUCCESS,
  CREATE_TIMEOUT_FAILURE,
  RESET_TIMEOUT,
} from './timeoutTypes';

import SERVER_URL from '../URL';

export const getUserTimeoutStatusRequest = () => {
  return {
    type: GET_USER_TIMEOUT_STATUS_REQUEST,
  };
};

export const getUserTimeoutStatusSuccess = (status) => {
  return {
    type: GET_USER_TIMEOUT_STATUS_SUCCESS,
    payLoad: status,
  };
};

export const getUserTimeoutStatusFailure = (error) => {
  return {
    type: GET_USER_TIMEOUT_STATUS_ERROR,
    payLoad: error,
  };
};

export const getTimeoutStatus = (params) => {
  return (dispatch) => {
    dispatch(getUserTimeoutStatusRequest());
    axios
      .get(`${SERVER_URL}/api/v1/timeout/${params.boardId}`, {
        params,
      })
      .then((response) => {
        const status = response.data.status;
        const time = response.data.time;
        if (status === false) {
          dispatch(getUserTimeoutStatusSuccess({ status: false, time }));
        } else {
          dispatch(getUserTimeoutStatusSuccess({ status: true, time }));
        }
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(getUserTimeoutStatusFailure(error.msg));
      });
  };
};

export const createTimeoutRequest = () => {
  return {
    type: CREATE_TIMEOUT_REQUEST,
  };
};

export const createTimeoutSuccess = () => {
  return {
    type: CREATE_TIMEOUT_SUCCESS,
  };
};

export const createTimeoutFailure = (error) => {
  return {
    type: CREATE_TIMEOUT_FAILURE,
    payLoad: error,
  };
};

export const createTimeout = (token, boardId) => {
  return (dispatch) => {
    dispatch(createTimeoutRequest());
    axios
      .post(
        `${SERVER_URL}/api/v1/timeout`,
        { boardId },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(createTimeoutSuccess());
      })
      .catch((error) => {
        dispatch(createTimeoutFailure(error.message));
      });
  };
};

export const resetTimeout = () => {
  return {
    type: RESET_TIMEOUT,
  };
};
