import React, { useState } from "react";

import { Datepicker, setOptions } from "@mobiscroll/react";

import "./cardDatePicker.css";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const CardDatePicker = ({ setCardDate, cardDate }) => {
  const [openPicker, setOpenPicker] = useState(false);

  const changeDatePicker = (args) => {
    setCardDate(args.valueText);
  };

  const show = () => {
    setOpenPicker(true);
  };
  const onClose = () => {
    setOpenPicker(false);
  };

  return (
    <>
      <Datepicker
        controls={["calendar"]}
        showOnClick={false}
        showOnFocus={false}
        isOpen={openPicker}
        onClose={onClose}
        onChange={changeDatePicker}
        responsive={{
          display: "anchored",
        }}
      />
      <div className="drop-down-button-date-picker" onClick={show}>
        {!cardDate ? "Select Date" : cardDate}
      </div>
    </>
  );
};

export default CardDatePicker;
