import React from 'react';
import { connect } from 'react-redux';
import { VscSettingsGear } from 'react-icons/vsc';

import BoardItem from '../BoardItem/BoardItem';

import {
  setBoardModal,
  setTeamModal,
  setSelectTeam,
  setTeamSettingsModal,
} from '../../redux';

import './teamBoardList.css';

const TeamBoardList = ({
  teams,
  boards,
  searchKeyWord,
  showBoardModal,
  showTeamModal,
  setCurrentTeam,
  showTeamSetting,
  user,
}) => {
  return (
    <div>
      {teams.map((team) => (
        <div key={team._id}>
          <div className="team-title-header">
            <h2 className="all-boards-title">{team.title}</h2>

            {team.owner._id === user._id && (
              <div className="team-header-icons-holder">
                <VscSettingsGear
                  className="team-settings-icon"
                  onClick={() => {
                    setCurrentTeam(team);
                    showTeamSetting();
                  }}
                />
              </div>
            )}
          </div>

          <div className="boards-list">
            {boards
              .filter((board) =>
                board?.title
                  ?.toLowerCase()
                  ?.replace(/\s/g, '')
                  ?.includes(searchKeyWord.toLowerCase().replace(/\s/g, ''))
              )
              .map(
                (boardItem) =>
                  boardItem.team === team._id && (
                    <BoardItem key={boardItem.id} board={boardItem} />
                  )
              )}

            {team.owner._id === user._id && user.plan.title === 'Team' && (
              <div
                className="add-board-button sub-color"
                onClick={() => {
                  setCurrentTeam(team);
                  showBoardModal();
                }}
              >
                + Add New Board
              </div>
            )}
          </div>
        </div>
      ))}

      {user.plan.title === 'Team' && (
        <h2 className="new-team-btn" onClick={() => showTeamModal()}>
          + Create New Team
        </h2>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    boards: state.board.boards,
    user: state.user.userData,
    searchKeyWord: state.board.searchKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showBoardModal: (type) => dispatch(setBoardModal(type)),
    showTeamModal: (type) => dispatch(setTeamModal(type)),
    setCurrentTeam: (team) => dispatch(setSelectTeam(team)),
    showTeamSetting: () => dispatch(setTeamSettingsModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamBoardList);
