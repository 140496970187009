import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';

import CardTitle from '../../CardTitle/CardTitle';
import CardDetails from '../../CardDetails/CardDetails';
import CardLength from '../../CardLength/CardLength';
import CardDatePicker from '../../CardDatePicker/CardDatePicker';
import AssignUserDropDown from '../../AssignUserDropDown/AssignUserDropDown';
import ColorLabelDropDown from '../../ColorLabelDropDown/ColorLabelDropDown';
import AssignedUsers from '../../AssignedUsers/AssignedUsers';
import CardModalSaveButton from '../../CardModalSaveButton/CardModalSaveButton';

import socket from '../../../Socket';

import {
  closeModal,
  addNewCardServer,
  toggleSocketModal,
  toggleCardSaveBtn,
} from '../../../redux';

const AddCardModal = ({
  createCardServer,
  user,
  currentColumnId,
  currentColumnTitle,
  currentBoardId,
  teamId,
  admins,
  members,
  isLoading,
  showSocketModal,
  enableSaveCardBtn,
  isCardSaveState,
}) => {
  const users = [...admins, ...members];

  const [cardTitle, setCardTitle] = useState('');
  const [cardBody, setCardBody] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardLabel, setCardLabel] = useState('');
  const [assignedUsers, setAssignUsers] = useState([]);
  const [assignedUsersObj, setAssignedUsersObj] = useState([]);
  const [titleLength, setTitleLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [rawDescData, setRawDescData] = useState('');

  useEffect(() => {
    if (
      rawDescData.length - 1 > 0 ||
      cardTitle.length > 0 ||
      cardDate.length > 0 ||
      assignedUsers.length > 0 ||
      cardLabel.length > 0
    ) {
      enableSaveCardBtn(true);
    } else {
      enableSaveCardBtn(false);
    }
  }, [rawDescData, cardTitle, cardDate, assignedUsers, cardLabel]);

  const handleSubmit = () => {
    if (titleLength + bodyLength > 1000000 || isCardSaveState === false) return;
    const _cardId = nanoid(7);
    const cardObj = {
      id: _cardId,
      columnId: currentColumnId,
      columnTitle: currentColumnTitle,
      boardId: currentBoardId,
      title: cardTitle,
      description: cardBody,
      date: cardDate,
      colorLabel: cardLabel,
      users: assignedUsers,
    };
    createCardServer(
      user.token,
      user._id,
      currentBoardId,
      { boardId: currentBoardId, teamId },
      cardObj
    );
  };

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const checkedUser = users.find((user) => user._id === e.target.value);
      setAssignUsers([...assignedUsers, checkedUser._id]);
      setAssignedUsersObj([...assignedUsersObj, checkedUser]);
    } else {
      const unCheckedUser = e.target.value;
      setAssignUsers(
        assignedUsers.filter((userId) => userId !== unCheckedUser)
      );
      setAssignedUsersObj(
        assignedUsersObj.filter((userObj) => userObj._id !== unCheckedUser)
      );
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!socket || !socket.connected) {
          showSocketModal(true);
          return;
        }
        handleSubmit();
      }}
      className="modal-body"
    >
      <h2 className="modal-title">Add New Card</h2>
      <div className="big-card-container">
        <div className="input-description-container">
          <CardTitle
            setCardTitle={setCardTitle}
            setTitleLength={setTitleLength}
            cardTitle={cardTitle}
          />
          <CardDetails
            bodyText={cardBody}
            setCardBody={setCardBody}
            setBodyLength={setBodyLength}
            setRawDescData={setRawDescData}
          />
          <CardLength titleLength={titleLength} bodyLength={bodyLength} />
        </div>
        <div className="modal-footer-container">
          <div className="action-buttons-container">
            <CardDatePicker setCardDate={setCardDate} cardDate={cardDate} />
            <AssignUserDropDown
              users={users}
              handleChange={handleChange}
              currentUsers={assignedUsers}
            />
            <ColorLabelDropDown setCardLabel={setCardLabel} />
            <AssignedUsers assignedUsers={assignedUsers} />
          </div>
          <CardModalSaveButton
            titleLength={titleLength}
            bodyLength={bodyLength}
            isLoading={isLoading}
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    currentBoardId: state.board.selectBoard.id,
    currentColumnId: state.column.selectColumn.id,
    currentColumnTitle: state.column.selectColumn.title,
    admins: state.board.selectBoard.admins,
    members: state.board.selectBoard.members,
    isLoading: state.column.loading,
    teamId: state.team.selectTeam._id,
    isCardSaveState: state.modal.isSaveBtn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    createCardServer: (token, userId, boardId, params, cardObj) =>
      dispatch(addNewCardServer(token, userId, boardId, params, cardObj)),
    showSocketModal: (state) => dispatch(toggleSocketModal(state)),
    enableSaveCardBtn: (state) => dispatch(toggleCardSaveBtn(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCardModal);
