import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";
import { connect } from "react-redux";

import UserList from "./UserList";
import { CloseCreateChannel } from "../../assets/CloseCreateChannel";
import { setErrorModal, setInfoModal } from "../../redux";

const ChannelNameInput = ({ channelName = "", setChannelName }) => {
  const handleChange = (event) => {
    event.preventDefault();

    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Name</p>
      <input
        value={channelName}
        onChange={handleChange}
        placeholder="channel-name"
      />
      <p>Add Members</p>
    </div>
  );
};

const CreateChannel = ({
  createType,
  setIsCreating,
  showErrorModal,
  showInfoModal,
  user,
}) => {
  const { client, setActiveChannel } = useChatContext();
  const [selectedUsers, setSelectedUsers] = useState([client.userID || ""]);
  const [channelName, setChannelName] = useState("");

  const handleShowLimitInfo = () => {
    setIsCreating(false);
    showInfoModal(
      "Trial Limit exceeded: Only 1 channel is allowed on this plan"
    );
  };

  const createChannel = async (e) => {
    e.preventDefault();

    const channels = await client.queryChannels({
      members: { $in: [user._id] },
    });

    let parsedChannels = [];

    if (
      createType === "team" &&
      channels.length > 0 &&
      user?.plan?.title === "Basic"
    ) {
      parsedChannels = channels.filter(
        (channelItem) =>
          channelItem.type === "team" &&
          channelItem.data.created_by.id === user._id
      );

      if (parsedChannels.length >= 1) {
        return handleShowLimitInfo();
      }
    }

    const parsedName = `${channelName.replaceAll(" ", "")}___${Date.now()}`;
    try {
      const newChannel = await client.channel(createType, parsedName, {
        name: channelName,
        members: selectedUsers,
      });

      await newChannel.watch();

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID]);
      setActiveChannel(parsedName);
    } catch (error) {
      console.log(error.response);
      setIsCreating(false);
      showErrorModal("Sorry channel could not be created");
    }
  };

  return (
    <div className="create-channel__container">
      <div className="create-channel__header">
        <p>
          {createType === "team"
            ? "Create a New Channel"
            : "Send a Direct Message"}
        </p>
        <CloseCreateChannel setIsCreating={setIsCreating} />
      </div>
      {createType === "team" && (
        <ChannelNameInput
          channelName={channelName}
          setChannelName={setChannelName}
        />
      )}
      <UserList isCreating={true} setSelectedUsers={setSelectedUsers} />
      <div className="create-channel__button-wrapper" onClick={createChannel}>
        <p>
          {createType === "team" ? "Create Channel" : "Create Message Group"}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorModal: (msg) => dispatch(setErrorModal(msg)),
    showInfoModal: (msg) => dispatch(setInfoModal(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateChannel);
