import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import moment from 'moment';

import { BoardIcon } from '../../assets/svg/iconlibrary';
import GraphColumn from '../GraphColumn/GraphColumn';

import {
  setCurrentBoardData,
  setBoardModal,
  fetchActivities,
} from '../../redux';
import { EDIT, DELETE } from '../../redux/modal/modalTypes';
import { EditIcon, TrashIcon } from '../../assets/svg/iconlibrary';

import SERVER_URL from '../../redux/URL';

import './boardItem.css';

const BoardItem = ({ user, board, setSelectBoardData, showModal, history }) => {
  const [graphdata, setGraphdata] = useState([]);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/v1/activities/days/15`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        params: {
          boardId: board.id,
        },
      })
      .then((response) => {
        const rawResArr = response.data.results;

        // create array and populate it with date string starting from today - 14 days ago
        let last14DaysArr = new Array(14)
          .fill()
          .map((item, i) => moment().subtract(i, 'days').utc().format());

        for (let res of rawResArr) {
          last14DaysArr.forEach((date, i) => {
            if (moment.utc(date).isSame(moment.utc(res._id.date), 'day')) {
              last14DaysArr[i] = {
                day: res._id.day,
                date: res._id.date,
                activities: res.activities,
              };
            }
          });
        }

        // convert all empty date string to empty arrays
        for (let str of last14DaysArr) {
          if (typeof str === 'string') {
            let index = last14DaysArr.indexOf(str);

            last14DaysArr[index] = [];
          }
        }

        setGraphdata(last14DaysArr);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      setGraphdata([]);
    };
  }, [board, user]);

  const handleDirectToActivityPage = () => {
    setSelectBoardData(board);
    history.push(`/board/${board.id}`);
  };

  return (
    <div className="board-item">
      <div
        className="class-for-item-on-click-event"
        onClick={handleDirectToActivityPage}
      />
      <span>
        <div className="title-container">
          <BoardIcon />
          <span className="board-item-title">{board?.title}</span>
        </div>
        <div className="board-item-info-container">
          <div>
            <p className="sub-color board-item-no-margin">
              {board?.numberOfColumns} Columns
            </p>
            <p className="sub-color board-item-no-margin">
              {board?.numberOfCards} Cards
            </p>
          </div>
          <GraphColumn graphdata={graphdata} />
        </div>
      </span>

      <div className="board-item-footer">
        <div className="board-num-members sub-color">
          {board?.members?.length + board?.admins?.length} Users
        </div>
        {board?.owner?._id?.includes(user._id) ? (
          <div className="board-item-icons">
            <TrashIcon
              handleDelete={() => {
                setSelectBoardData(board);
                showModal(DELETE);
              }}
              className="column-header-trash-icon"
            />
            <EditIcon
              handleEdit={() => {
                setSelectBoardData(board);
                showModal(EDIT);
              }}
              className="column-header-edit-icon"
            />
          </div>
        ) : (
          <div className="admin-name">
            <p>
              <span className="bolden">Admin: </span>
              {board?.owner.firstName}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    activities: state.column.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectBoardData: (board) => dispatch(setCurrentBoardData(board)),
    getActivities: (token, boardId) =>
      dispatch(fetchActivities(token, boardId)),
    showModal: (type) => dispatch(setBoardModal(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BoardItem));
