import React, { useEffect, useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  toggleRightSideNav,
  turnOffNotify,
  getNotificationStatus,
} from '../../redux/index';
import './notificationIcon.css';
import { sendDesktopNotification } from '../../utils';

import socket from '../../Socket';

const NotificationIcon = ({
  user,
  toggleRightSideNav,
  notifyOff,
  getNotifyStatus,
}) => {
  const [isNotified, setIsNotified] = useState(false);
  const history = useHistory();
  const path = history.location.pathname;

  useEffect(() => {
    getNotifyStatus(user.token);
  }, [user.token, getNotifyStatus]);

  useEffect(() => {
    if (path.includes('/board') && !path.includes('/boards')) {
      socket.on('getNotification', () => {
        sendDesktopNotification('New Notification', 'Assigned to Card');
        setIsNotified(true);
        notifyOff(true);
      });
    }

    return () => {
      socket.off('getNotification');
    };
  }, [path, notifyOff]);

  return (
    <div className="notification-icon-container">
      <FaBell
        onClick={() => {
          if (path.includes('/board') && !path.includes('/boards')) {
            socket.emit('turnOffNotify', user._id);
          }
          toggleRightSideNav();
          notifyOff(false);
          setIsNotified(false);
        }}
        className="notification-icon"
      />
      <span
        style={
          isNotified || user.isNotified
            ? {
                backgroundColor: '#03A9F4',
                borderRadius: '20px',
                width: '10px',
                height: '10px',
              }
            : null
        }
      ></span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSideNav: () => dispatch(toggleRightSideNav()),
    notifyOff: (bool) => dispatch(turnOffNotify(bool)),
    getNotifyStatus: (token) => dispatch(getNotificationStatus(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationIcon);
