import axios from 'axios';
import {
  FETCH_BOARDS_REQUEST,
  FETCH_BOARDS_SUCCESS,
  FETCH_BOARDS_FAILURE,
  FETCH_TEAM_BOARDS_REQUEST,
  FETCH_TEAM_BOARDS_SUCCESS,
  FETCH_TEAM_BOARDS_FAILURE,
  CLEAR_BOARDS,
  ADD_NEW_BOARD_LOCAL,
  ADD_NEW_BOARD_SERVER_REQUEST,
  ADD_NEW_BOARD_SERVER_SUCCESS,
  ADD_NEW_BOARD_SERVER_FAILURE,
  ADD_NEW_TEAM_BOARD_SERVER_REQUEST,
  ADD_NEW_TEAM_BOARD_SERVER_SUCCESS,
  ADD_NEW_TEAM_BOARD_SERVER_FAILURE,
  INVITE_MEMBER_TO_BOARD_REQUEST,
  INVITE_MEMBER_TO_BOARD_SUCCESS,
  INVITE_MEMBER_TO_BOARD_FAILURE,
  ADD_COLUMN_TO_COLUMNS_ORDER_LOCAL,
  EDIT_BOARD_LOCAL,
  EDIT_BOARD_SERVER_REQUEST,
  EDIT_BOARD_SERVER_SUCCESS,
  EDIT_BOARD_SERVER_FAILURE,
  DELETE_BOARD_LOCAL,
  DELETE_BOARD_SERVER_REQUEST,
  DELETE_BOARD_SERVER_SUCCESS,
  DELETE_BOARD_SERVER_FAILURE,
  REMOVE_MEMBER_FROM_BOARD_REQUEST,
  REMOVE_MEMBER_FROM_BOARD_SUCCESS,
  REMOVE_MEMBER_FROM_BOARD_FAILURE,
  ENTER_SEARCH_TEXT,
  SET_CURRENT_BOARD_DATA,
  REMOVE_COLUM_FROM_COLUMNS_ORDER_LOCAL,
  CHANGE_COLUMNS_ORDER_LOCAL,
  GET_USER_EMAIL,
  UPDATE_BOARD_MEMBERS,
  ACCEPT_INVITE_REQUEST,
  ACCPET_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  DECLINE_INVITE_REQUEST,
  DECLINE_INVITE_SUCCESS,
  DECLINE_INVITE_FAILURE,
  SET_LOCATION,
} from './boardTypes';

import {
  clearColumns,
  setConferenceModal,
  clearActivities,
  clearFiles,
  clearPins,
  closeModal,
  setErrorModal,
  setInfoModal,
  toggleRightSideNav,
  clearNotifications,
  clearEvents,
  handleLogOutUser,
  setUserPlan,
} from '../index';

import SERVER_URL from '../URL';

import socket from '../../Socket';

export const updateBoardMembers = (members) => {
  return {
    type: UPDATE_BOARD_MEMBERS,
    payLoad: members,
  };
};

// FETCH BOARDS
export const fetchBoardsRequest = () => {
  return {
    type: FETCH_BOARDS_REQUEST,
  };
};

export const fetchBoardsSuccess = (boards) => {
  return {
    type: FETCH_BOARDS_SUCCESS,
    payLoad: boards,
  };
};

export const fetchBoardsFailure = (error) => {
  return {
    type: FETCH_BOARDS_FAILURE,
    payLoad: error,
  };
};

// TEAM BOARDS
export const fetchTeamBoardsRequest = () => {
  return {
    type: FETCH_TEAM_BOARDS_REQUEST,
  };
};

export const fetchTeamBoardsSuccess = (boards) => {
  return {
    type: FETCH_TEAM_BOARDS_SUCCESS,
    payLoad: boards,
  };
};

export const fetchTeamBoardsFailure = (error) => {
  return {
    type: FETCH_TEAM_BOARDS_FAILURE,
    payLoad: error,
  };
};

export const fetchBoards = (token, history) => {
  return (dispatch) => {
    dispatch(fetchBoardsRequest());
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearPins());
    axios
      .get(`${SERVER_URL}/api/v1/boards`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const boards = response.data.data.boards;
        const logoutStatus = response.data.isLogout;
        const user = response.data.user;

        if (logoutStatus) {
          return dispatch(handleLogOutUser(history));
        }

        if (user) {
          dispatch(setUserPlan(user));
        }
        dispatch(fetchBoardsSuccess(boards));
      })
      .catch((error) => {
        dispatch(fetchBoardsFailure(error.message));
      });
  };
};

export const fetchTeamBoards = (token, params) => {
  return (dispatch) => {
    dispatch(fetchTeamBoardsRequest());
    dispatch(clearBoards());
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearPins());
    axios
      .get(`${SERVER_URL}/api/v1/teams/boards`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const boards = response.data.data.boards;
        dispatch(fetchTeamBoardsSuccess(boards));
      })
      .catch((error) => {
        dispatch(fetchTeamBoardsFailure(error.message));
      });
  };
};

export const fetchBoard = (
  token,
  boardId,
  params,
  withMeeting = false,
  history = null
) => {
  return (dispatch) => {
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearPins());
    axios
      .get(`${SERVER_URL}/api/v1/boards/${boardId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const board = response.data.data.board;
        dispatch(setCurrentBoardData(board));
        if (withMeeting) {
          dispatch(setConferenceModal());
        }
      })
      .catch((error) => {
        if (history) {
          history.push('/boards');
          dispatch(setErrorModal('Board was not found'));
        }
        dispatch(fetchBoardsFailure(error.message));
      });
  };
};

export const clearBoards = () => {
  return {
    type: CLEAR_BOARDS,
  };
};

// ADD NEW BOARDS
export const addNewBoardLocal = (boardObj) => {
  return {
    type: ADD_NEW_BOARD_LOCAL,
    payLoad: boardObj,
  };
};

export const addNewBoardServerRequest = () => {
  return {
    type: ADD_NEW_BOARD_SERVER_REQUEST,
  };
};

export const addNewBoardServerSuccess = () => {
  return {
    type: ADD_NEW_BOARD_SERVER_SUCCESS,
  };
};

export const addNewBoardServerFailure = (error) => {
  return {
    type: ADD_NEW_BOARD_SERVER_FAILURE,
    payLoad: error,
  };
};

// NEW TEAM BOARD
export const addNewTeamBoardServerRequest = () => {
  return {
    type: ADD_NEW_TEAM_BOARD_SERVER_REQUEST,
  };
};

export const addNewTeamBoardServerSuccess = () => {
  return {
    type: ADD_NEW_TEAM_BOARD_SERVER_SUCCESS,
  };
};

export const addNewTeamBoardServerFailure = (error) => {
  return {
    type: ADD_NEW_TEAM_BOARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    payLoad: location,
  };
};

export const createNewBoardServer = (boardObj, token) => {
  return (dispatch) => {
    dispatch(addNewBoardServerRequest());
    axios
      .post(`${SERVER_URL}/api/v1/boards`, boardObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const board = response.data.data.board;
        dispatch(addNewBoardLocal(board));
        dispatch(addNewBoardServerSuccess());
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(addNewBoardServerFailure(error.message));
      });
  };
};

export const createNewTeamBoardServer = (token, boardObj, params) => {
  return (dispatch) => {
    dispatch(addNewTeamBoardServerRequest());
    axios
      .post(`${SERVER_URL}/api/v1/teams/boards`, boardObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const board = response.data.data.board;
        dispatch(addNewBoardLocal(board));
        dispatch(addNewTeamBoardServerSuccess());
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(addNewTeamBoardServerFailure(error.message));
      });
  };
};

//EDIT BOARDS
export const editCurrentBoardLocal = (boardObj) => {
  return {
    type: EDIT_BOARD_LOCAL,
    payLoad: boardObj,
  };
};

export const editBoardServerRequest = () => {
  return {
    type: EDIT_BOARD_SERVER_REQUEST,
  };
};

export const editBoardServerSuccess = () => {
  return {
    type: EDIT_BOARD_SERVER_SUCCESS,
  };
};

export const editBoardServerFailure = (error) => {
  return {
    type: EDIT_BOARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const editBoardServer = (boardId, params, boardObj, token) => {
  return (dispatch) => {
    dispatch(editBoardServerRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/boards/${boardId}`, boardObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const board = response.data.data.board;
        dispatch(editCurrentBoardLocal(board));
        dispatch(editBoardServerSuccess());
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(editBoardServerFailure(error.message));
      });
  };
};

export const changeColumnsOrderServer = (boardId, boardObj, token) => {
  return (dispatch) => {
    axios
      .patch(
        `${SERVER_URL}/api/v1/boards/changecolumnsorder/${boardId}`,
        boardObj,
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            boardId,
          },
        }
      )
      .then((response) => {
        const board = response.data.data.board;
        socket.emit('change-columns-order', board.columnsOrder);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

// DELETE BOARDS
export const deleteCurrentBoardLocal = (boardId) => {
  return {
    type: DELETE_BOARD_LOCAL,
    payLoad: boardId,
  };
};

export const handleDeleteBoardLocal = (boardId) => {
  return (dispatch) => {
    dispatch(deleteCurrentBoardLocal(boardId));
    dispatch(clearColumns());
  };
};

export const deleteBoardServerRequest = () => {
  return {
    type: DELETE_BOARD_SERVER_REQUEST,
  };
};

export const deleteBoardServerSuccess = () => {
  return {
    type: DELETE_BOARD_SERVER_SUCCESS,
  };
};

export const deleteBoardServerFailure = (error) => {
  return {
    type: DELETE_BOARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const handleDeleteBoardServer = (boardId, params, token) => {
  return (dispatch) => {
    dispatch(deleteBoardServerRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/boards/${boardId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(() => {
        dispatch(handleDeleteBoardLocal(boardId));
        dispatch(deleteBoardServerSuccess());
        dispatch(clearEvents());
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(deleteBoardServerFailure(error.message));
      });
  };
};

export const inviteMemberToBoardRequest = () => {
  return {
    type: INVITE_MEMBER_TO_BOARD_REQUEST,
  };
};

export const inviteMemberToBoardSuccess = (board) => {
  return {
    type: INVITE_MEMBER_TO_BOARD_SUCCESS,
    payLoad: board,
  };
};

export const inviteMemberToBoardFailure = (error) => {
  return {
    type: INVITE_MEMBER_TO_BOARD_FAILURE,
    payLoad: error,
  };
};

export const acceptMemberInviteRequest = () => {
  return {
    type: ACCEPT_INVITE_REQUEST,
  };
};

export const acceptMemberInviteSuccess = (board) => {
  return {
    type: ACCPET_INVITE_SUCCESS,
    payLoad: board,
  };
};

export const acceptMemberInviteFailure = (error) => {
  return {
    type: ACCEPT_INVITE_FAILURE,
    payLoad: error,
  };
};

export const declineMemberInviteRequest = () => {
  return {
    type: DECLINE_INVITE_REQUEST,
  };
};

export const declineMemberInviteSuccess = (board) => {
  return {
    type: DECLINE_INVITE_SUCCESS,
    payLoad: board,
  };
};

export const declineMemberInviteFailure = (error) => {
  return {
    type: DECLINE_INVITE_FAILURE,
    payLoad: error,
  };
};

export const acceptInvitation = (token, answer, history) => {
  return (dispatch) => {
    dispatch(acceptMemberInviteRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/boards/accept-member-invite`, answer, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const board = response.data.board;
        dispatch(acceptMemberInviteSuccess(board));
        dispatch(fetchBoards(token));
        dispatch(toggleRightSideNav());
        dispatch(clearNotifications());
        history.push('/boards');
      })
      .catch((error) => {
        dispatch(acceptMemberInviteFailure(error.response?.data?.message));
        dispatch(setErrorModal(error?.response?.data?.message));
      });
  };
};

export const declineInvitation = (token, answer) => {
  return (dispatch) => {
    dispatch(declineMemberInviteRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/boards/decline-member-invite`, answer, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const board = response.data.board;
        dispatch(declineMemberInviteSuccess(board));
        dispatch(toggleRightSideNav());
        dispatch(clearNotifications());
      })
      .catch((error) => {
        if (error.response.data.message) {
          dispatch(declineMemberInviteFailure(error.response.data.message));
          dispatch(setErrorModal(error.response.data.message));
        }
      });
  };
};

export const inviteMemberToBoard = (token, addObj, boardId) => {
  return (dispatch) => {
    dispatch(inviteMemberToBoardRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/boards/invite-member`, addObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          boardId,
        },
      })
      .then((response) => {
        const board = response.data.board;
        dispatch(inviteMemberToBoardSuccess(board));
        dispatch(setInfoModal('User invite sent successfully'));
      })
      .catch((error) => {
        if (error.response.data.message) {
          dispatch(inviteMemberToBoardFailure(error.response.data.message));
          dispatch(setErrorModal(error.response.data.message));
        }
      });
  };
};

export const removeMemberFromBoardRequest = () => {
  return {
    type: REMOVE_MEMBER_FROM_BOARD_REQUEST,
  };
};

export const removeMemberFromBoardSuccess = (members) => {
  return {
    type: REMOVE_MEMBER_FROM_BOARD_SUCCESS,
    payLoad: members,
  };
};

export const removeMemberFromBoardFailure = (error) => {
  return {
    type: REMOVE_MEMBER_FROM_BOARD_FAILURE,
    payLoad: error,
  };
};

export const removeMemberFromBoard = (token, removeObj, params) => {
  return (dispatch) => {
    dispatch(removeMemberFromBoardRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/boards/removemember`, removeObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const members = response.data.data.board.members;
        dispatch(removeMemberFromBoardSuccess(members));
        dispatch(closeModal());
        socket.emit('remove-member', removeObj.userEmail);
      })
      .catch((error) => {
        dispatch(setErrorModal(error.response.data.message));
        dispatch(removeMemberFromBoardFailure(error.response.data.message));
      });
  };
};

export const enterSearchText = (text) => {
  return {
    type: ENTER_SEARCH_TEXT,
    payLoad: text,
  };
};

export const setCurrentBoardData = (board) => {
  return {
    type: SET_CURRENT_BOARD_DATA,
    payLoad: board,
  };
};

export const addColumnToColumnsOrderLocal = (columnId) => {
  return {
    type: ADD_COLUMN_TO_COLUMNS_ORDER_LOCAL,
    payLoad: columnId,
  };
};

export const removeColumnFromColumnsOrderLocal = (columnId) => {
  return {
    type: REMOVE_COLUM_FROM_COLUMNS_ORDER_LOCAL,
    payLoad: columnId,
  };
};

export const changeColumnsOrderLocal = (changeObj) => {
  return {
    type: CHANGE_COLUMNS_ORDER_LOCAL,
    payLoad: changeObj,
  };
};

export const getUserEmail = (email) => {
  return {
    type: GET_USER_EMAIL,
    payLoad: email,
  };
};
