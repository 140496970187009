export const FETCH_COLUMNS_REQUEST = "FETCH_COLUMNS_REQUEST";
export const FETCH_COLUMNS_SUCCESS = "FETCH_COLUMNS_SUCCESS";
export const FETCH_COLUMNS_FAILURE = "FETCH_COLUMNS_FAILURE";

export const ADD_NEW_COLUMN_LOCAL = "ADD_NEW_COLUMN_LOCAL";
export const ADD_NEW_COLUMN_SERVER_REQUEST = "ADD_NEW_COLUMN_SERVER_REQUEST";
export const ADD_NEW_COLUMN_SERVER_SUCCESS = "ADD_NEW_COLUMN_SERVER_SUCCESS";
export const ADD_NEW_COLUMN_SERVER_FAILURE = "ADD_NEW_COLUMN_SERVER_FAILURE";

export const EDIT_COLUMN_LOCAL = "EDIT_COLUMN_LOCAL";
export const EDIT_COLUMN_SERVER_REQUEST = "EDIT_COLUMN_SERVER_REQUEST";
export const EDIT_COLUMN_SERVER_SUCCESS = "EDIT_COLUMN_SERVER_SUCCESS";
export const EDIT_COLUMN_SERVER_FAILURE = "EDIT_COLUMN_SERVER_FAILURE";

export const REMOVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_SUCCESS =
  "REMOVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_SUCCESS";
export const REOMVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_FAILURE =
  "REOMVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_FAILURE";

export const DELETE_COLUMN_LOCAL = "DELETE_COLUMN_LOCAL";
export const DELETE_COLUMN_SERVER_REQUEST = "DELETE_COLUMN_SERVER_REQUEST";
export const DELETE_COLUMN_SERVER_SUCCESS = "DELETE_COLUMN_SERVER_SUCCESS";
export const DELETE_COLUMN_SERVER_FAILURE = "DELETE_COLUMN_SERVER_FAILURE";

export const ADD_NEW_CARD_LOCAL = "ADD_NEW_CARD_LOCAL";
export const ADD_NEW_CARD_SERVER_REQUEST = "ADD_NEW_CARD_SERVER_REQUEST";
export const ADD_NEW_CARD_SERVER_SUCCESS = "ADD_NEW_CARD_SERVER_SUCCESS";
export const ADD_NEW_CARD_SERVER_FAILURE = "ADD_NEW_CARD_SERVER_FAILURE";

export const EDIT_CARD_LOCAL = "EDIT_CARD_LOCAL";
export const EDIT_CARD_SERVER_REQUEST = "EDIT_CARD_SERVER_REQUEST";
export const EDIT_CARD_SERVER_SUCCESS = "EDIT_CARD_SERVER_SUCCESS";
export const EDIT_CARD_SERVER_FAILURE = "EDIT_CARD_SERVER_FAILURE";

export const DELETE_CARD_LOCAL = "DELETE_CARD_LOCAL";
export const DELETE_CARD_SERVER_REQUEST = "DELETE_CARD_SERVER_REQUEST";
export const DELETE_CARD_SERVER_SUCCESS = "DELETE_CARD_SERVER_SUCCESS";
export const DELETE_CARD_SERVER_FAILURE = "DELETE_CARD_SERVER_FAILURE";

export const CHANGE_CARDS_ORDER_LOCAL = "CHANGE_CARD_ORDER_LOCAL";

export const REMOVE_CARD_FROM_SOURCE_COLUMN_LOCAL =
  "REMOVE_CARD_FROM_SOURCE_COLUMN_LOCAL";

export const CHANGE_CARD_COLUMN_ID = "CHANGE_CARD_COLUMN_ID";

export const CHANGE_CARD_COLUMN_LOCAL = "CHANGE_CARD_COLUMN_LOCAL";

export const CHANGE_CARDS_ORDER_IO = "CHANGE_CARDS_ORDER_IO";
export const REMOVE_CARD_FROM_SOURCE_COLUMN_IO =
  "REMOVE_CARD_FROM_SOURCE_COLUMN_IO";
export const CHANGE_CARD_COLUMN_IO = "CHANGE_CARD_COLUMN_IO";

export const CHANGE_CARD_COLUMN_SERVER_FAILURE =
  "CHANGE_CARD_COLUMN_SERVER_FAILURE";

export const TOGGLE_ASSIGNED_TO_ME = "TOGGLE_ASSIGNED_TO_ME";
export const RESET_IS_ME = "RESET_IS_ME";

export const DRAG_CARD_IN_COLUMN_REQUEST = "DRAG_CARD_IN_COLUMN_REQUEST";
export const DRAG_CARD_IN_COLUMN_SUCCESS = "DRAG_CARD_IN_COLUMN_SUCCESS";
export const DRAG_CARD_IN_COLUMN_FAILURE = "DRAG_CARD_IN_COLUMN_FAILURE";

export const DRAG_CARD_TO_DIFF_COLUMN_REQUEST =
  "DRAG_CARD_TO_DIFF_COLUMN_REQUEST";
export const DRAG_CARD_TO_DIFF_COLUMN_SUCCESS =
  "DRAG_CARD_TO_DIFF_COLUMN_SUCCESS";
export const DRAG_CARD_TO_DIFF_COLUMN_FAILURE =
  "DRAG_CARD_TO_DIFF_COLUMN_FAILURE";

export const ENTER_CARD_SEARCH_KEY = "ENTER_CARD_SEARCH_KEY";
export const CLEAR_CARD_SEARCH_KEY = "CLEAR_CARD_SEARCH_KEY";
export const SET_CURRENT_CARD_DATA = "SET_CURRENT_CARD-DATA";
export const SET_CURRENT_COLUMN_DATA = "SET_CURRENT_COLUMN_DATA";
export const CLEAR_COLUMNS = "CLEAR_COLUMNS";
export const COLUMN_API_URL =
  "https://intense-garden-79464.herokuapp.com/api/v1/columns";

export const REFRESH_COLUMNS_LOCAL = "REFRESH_COLUMNS_LOCAL";

export const ADD_IN_USE_CARD = "ADD_IN_USE_CARD";
export const REMOVE_IN_USE_CARD = "REMOVE_IN_USE_CARD";
export const REMOVE_IN_USE_CARD_BY_USER = "REMOVE_IN_USE_CARD_BY_USER";
