import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';

import {
  closeModal,
  setSelectPlan,
  setInfoModal,
  fetchUserPlan,
} from '../../../redux';

const CheckoutForm = ({
  setSuccessful,
  planData,
  showInfoModal,
  clearSelectPlan,
  closeModal,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 2500);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      setLoading(false);
      closeModal();
      showInfoModal(
        `Subscription to the '${planData.title.toUpperCase()}' plan failed`
      );
      clearSelectPlan({});
    } else {
      setLoading(false);
      setSuccessful(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="modal-title">{planData.title} Plan</h2>
      <PaymentElement />
      {showButton && (
        <button
          className="modal-board-button stripe-pay-btn"
          type="submit"
          disabled={loading}
        >
          {loading
            ? 'Processing...'
            : `Pay $${planData.price} ${planData.interval}`}
        </button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    planData: state.planSubscription.selectPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    showInfoModal: (message) => dispatch(setInfoModal(message)),
    clearSelectPlan: (planData) => dispatch(setSelectPlan(planData)),
    getUserPlan: (token) => dispatch(fetchUserPlan(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
