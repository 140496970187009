import React from "react";
import { connect } from "react-redux";
import { FaTimes, FaBars } from "react-icons/fa";

// import { MinimizeIcon } from "../../assets/svg/iconlibrary";

import { closeChatModal } from "../../redux";

const CompanyHeader = ({ closeChat }) => {
  return (
    <div className="channel-list__header">
      <p className="channel-list__header__text">
        <button className="chat-minimize-btn" onClick={closeChat}>
          <FaTimes />
        </button>
        Chat
      </p>
      <FaBars className="chat-menu-bar-icon" />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeChat: () => dispatch(closeChatModal()),
  };
};

export default connect(null, mapDispatchToProps)(CompanyHeader);
