import axios from "axios";
import moment from "moment";

import {
  TOGGLE_FILE_MODAL,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  CLEAR_FILES,
} from "./fileTypes";

import { setErrorModal } from "../index";

import SERVER_URL from "../URL";

export const toggleFileModal = () => {
  return {
    type: TOGGLE_FILE_MODAL,
  };
};

export const uploadFileRequest = () => {
  return {
    type: UPLOAD_FILE_REQUEST,
  };
};

export const uploadFileSuccess = (file) => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payLoad: file,
  };
};

export const uploadFileFailure = (error) => {
  return {
    type: UPLOAD_FILE_FAILURE,
    payLoad: error,
  };
};

export const fetchFilesSuccess = (files) => {
  return {
    type: FETCH_FILE_SUCCESS,
    payLoad: files,
  };
};

export const fetchFilesFailure = (error) => {
  return {
    type: FETCH_FILE_FAILURE,
    payLoad: error,
  };
};

export const deleteFileRequest = () => {
  return {
    type: DELETE_FILE_REQUEST,
  };
};

export const deleteFileSuccess = (file) => {
  return {
    type: DELETE_FILE_SUCCESS,
    payLoad: file,
  };
};

export const deleteFileFailure = (error) => {
  return {
    type: DELETE_FILE_FAILURE,
    payLoad: error,
  };
};

export const fetchFiles = (token, params) => {
  return (dispatch) => {
    axios
      .get(`${SERVER_URL}/api/v1/files`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const files = response.data.data.files;
        dispatch(fetchFilesSuccess(files));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchFilesFailure(error.msg));
      });
  };
};

export const uploadFile = (token, params, fileData, fileName, setFileData) => {
  return (dispatch) => {
    dispatch(uploadFileRequest());
    // upload file to could storage
    axios
      .post("https://api.cloudinary.com/v1_1/dfsv0vn6z/upload", fileData)
      .then((response) => {
        const file = response.data;
        const newFileData = {
          secureUrl: file.secure_url,
          fileName: fileName,
          date: moment(),
          publicId: file.public_id,
        };

        // create file data in db
        axios
          .post(`${SERVER_URL}/api/v1/files`, newFileData, {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params,
          })
          .then((response) => {
            const file = response.data.data.file;
            dispatch(uploadFileSuccess(file));
            setFileData(null);
          })
          .catch((error) => {
            dispatch(uploadFileFailure(error));
            setFileData(null);
            dispatch(setErrorModal("Sorry could not upload file, try again"));
          });
      })
      .catch((error) => {
        dispatch(uploadFileFailure(error));
        dispatch(setErrorModal("Sorry could not upload file, try again"));
      });
  };
};

export const deleteFile = (token, params, fileId) => {
  return (dispatch) => {
    dispatch(deleteFileRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/files/${fileId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(() => {
        dispatch(deleteFileSuccess(fileId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setErrorModal("Sorry could not delete file"));
        dispatch(uploadFileFailure(error.msg));
      });
  };
};

export const clearFiles = () => {
  return {
    type: CLEAR_FILES,
  };
};
