import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

const RedirectToLoginPage = ({ component: Component, authMail, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authMail ? (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authMail: state.auth.authEmail,
  };
};

export default connect(mapStateToProps, null)(RedirectToLoginPage);
