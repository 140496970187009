import {
  ADD_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  CLEAR_EVENTS,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  ADD_NEW_EVENT_SERVER_REQUEST,
  ADD_NEW_EVENT_SERVER_SUCCESS,
  ADD_NEW_EVENT_SERVER_FAILURE,
  EDIT_EVENT_SERVER_REQUEST,
  EDIT_EVENT_SERVER_SUCCESS,
  EDIT_EVENT_SERVER_FAILURE,
  DELETE_EVENT_SERVER_REQUEST,
  DELETE_EVENT_SERVER_SUCCESS,
  DELETE_EVENT_SERVER_FAILURE,
  REFRESH_EVENT_LIST,
} from "./calendarTypes";

import axios from "axios";
import moment from "moment";

import SERVER_URL from "../URL";
import socket from "../../Socket";
import { sendNotification, turnOffNotify } from "../user/userActions";

export const addEvent = (newEvent) => {
  return {
    type: ADD_EVENT,
    payLoad: newEvent,
  };
};

export const editEvent = (id, updatedEvent) => {
  return {
    type: EDIT_EVENT,
    payLoad: { eventId: id, event: updatedEvent },
  };
};

export const deletEvent = (eventId) => {
  return {
    type: DELETE_EVENT,
    payLoad: eventId,
  };
};

export const clearEvents = () => {
  return {
    type: CLEAR_EVENTS,
  };
};

export const refreshEventList = () => {
  return {
    type: REFRESH_EVENT_LIST,
  };
};

export const addNewEventServerRequest = () => {
  return {
    type: ADD_NEW_EVENT_SERVER_REQUEST,
  };
};

export const addNewEventServerSuccess = () => {
  return {
    type: ADD_NEW_EVENT_SERVER_SUCCESS,
  };
};

export const addNewEventServerFailure = (error) => {
  return {
    type: ADD_NEW_EVENT_SERVER_FAILURE,
    payLoad: error,
  };
};

export const addEventServer = (token, params, eventObj, setOpen) => {
  return (dispatch) => {
    dispatch(addNewEventServerRequest());
    axios
      .post(`${SERVER_URL}/api/v1/events`, eventObj, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const event = response.data.data.event;
        dispatch(addNewEventServerSuccess());
        dispatch(addEvent(event));

        for (let i = 0; i < event.users.length; i++) {
          dispatch(
            sendNotification(token, params, {
              title: "Invited to Event",
              message: `Invited to Event`,
              user: event.users[i]._id,
              timeCreated: moment(),
              boardId: params.boardId,
              eventId: event.id,
            })
          );

          socket.emit("sendNotification", {
            senderId: event.originUser._id,
            receiverId: event.users[i]._id,
          });
        }

        dispatch(
          sendNotification(token, params, {
            title: "Created Event",
            message: `${event.originUser.firstName} created this event`,
            user: event.originUser._id,
            timeCreated: moment(),
            boardId: params.boardId,
            eventId: event.id,
          })
        );

        if (eventObj.originUser === event.originUser._id) {
          dispatch(turnOffNotify(true));
        }

        setOpen(false);
      })
      .catch((error) => {
        dispatch(addNewEventServerFailure(error.message));
      });
  };
};

export const editEventServerRequest = () => {
  return {
    type: EDIT_EVENT_SERVER_REQUEST,
  };
};

export const editEventServerSuccess = () => {
  return {
    type: EDIT_EVENT_SERVER_SUCCESS,
  };
};

export const editEventServerFailure = (error) => {
  return {
    type: EDIT_EVENT_SERVER_FAILURE,
    payLoad: error,
  };
};

export const editEventServer = (
  token,
  eventId,
  params,
  eventObj,
  newEventUsers,
  setOpen
) => {
  return (dispatch) => {
    dispatch(editEventServerRequest());
    axios
      .patch(
        `${SERVER_URL}/api/v1/events/${eventId}`,
        { ...eventObj, newUsers: newEventUsers },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((response) => {
        const event = response.data.data.event;
        dispatch(editEventServerSuccess());
        dispatch(editEvent(event.id, event));
        if (newEventUsers.length > 0) {
          for (let i = 0; i < newEventUsers.length; i++) {
            dispatch(
              sendNotification(token, event.boardId, {
                title: "Invited to Event",
                message: `Invited to Event`,
                user: newEventUsers[i],
                timeCreated: moment(),
                boardId: event.boardId,
                eventId: event.id,
              })
            );

            socket.emit("sendNotification", {
              senderId: event.originUser._id,
              receiverId: newEventUsers[i],
            });
          }
        }
        setOpen(false);
      })
      .catch((error) => {
        dispatch(editEventServerFailure(error.message));
      });
  };
};

export const deleteEventServerRequest = () => {
  return {
    type: DELETE_EVENT_SERVER_REQUEST,
  };
};

export const deleteEventServerSuccess = () => {
  return {
    type: DELETE_EVENT_SERVER_SUCCESS,
  };
};

export const deleteEventServerFailure = (error) => {
  return {
    type: DELETE_EVENT_SERVER_FAILURE,
    payLoad: error,
  };
};

export const deleteEventServer = (token, params, event, setOpen) => {
  return (dispatch) => {
    dispatch(deleteEventServerRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/events/${event.id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(() => {
        dispatch(deleteEventServerSuccess());
        dispatch(deletEvent(event.id));
        setOpen(false);
      })
      .catch((error) => {
        dispatch(deleteEventServerFailure(error.message));
      });
  };
};

export const fetchEventsRequest = () => {
  return {
    type: FETCH_EVENTS_REQUEST,
  };
};

export const fetchEventsSuccess = (events) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payLoad: events,
  };
};

export const fetchEventsFailure = (error) => {
  return {
    type: FETCH_EVENTS_FAILURE,
    payLoad: error,
  };
};

export const fetchEvents = (token) => {
  return (dispatch) => {
    dispatch(fetchEventsRequest());
    axios
      .get(`${SERVER_URL}/api/v1/events`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const events = response.data.data.events;
        dispatch(fetchEventsSuccess(events));
      })
      .catch((error) => {
        dispatch(fetchEventsFailure(error.message));
      });
  };
};
