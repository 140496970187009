import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { KevvlarIcon, LoadingIcon } from "../../assets/svg/iconlibrary";

import {
  setAuthMail,
  handleResendVerificationToken,
  handleVerifyEmail,
} from "../../redux";

import "./verifyEmail.css";

const VerifyEmailPage = ({
  userEmail,
  error,
  loading,
  history,
  sendToken,
  setAuthEmail,
  verifyAccount,
}) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    if (userEmail && history.location?.state === "resend") {
      sendToken({ email: userEmail });

      history.replace({ state: "" });
    }
  }, [userEmail, history, sendToken]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (token && token !== "" && userEmail) {
      verifyAccount({ token: token, email: userEmail }, history);
    }
  };

  return (
    <div className="signup-container">
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="signup-content">
          <KevvlarIcon />
          <h2 className="signup-white">Verify Email</h2>
          <div className="signup-white">
            A verification token as been sent to your email
          </div>
          {error && <div className="sign-in-error">{error}</div>}
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="signup-form-group">
              <input
                type="text"
                className="signup-form-input"
                name="token"
                placeholder="Enter token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
              />
            </div>

            <div className="signup-form-group">
              <button type="submit" className="signup-form-submit">
                Verify
              </button>
            </div>
          </form>
          <p className="spam-color">Make sure to check your junk folder!</p>
          <p className="info-color">
            Didn't get an email?
            <span
              onClick={() => sendToken({ email: userEmail })}
              className="verify-email-link"
            >
              {" "}
              click here!
            </span>
          </p>
          <p className="verify-email-link">
            <Link to="/signin" className="verify-email-link">
              login
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.auth.authEmail,
    error: state.auth.error,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthEmail: (email) => dispatch(setAuthMail(email)),
    sendToken: (details) => dispatch(handleResendVerificationToken(details)),
    verifyAccount: (details, routing) =>
      dispatch(handleVerifyEmail(details, routing)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyEmailPage));
