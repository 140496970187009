import React from 'react';
import './userList.css';

const UserList = ({
  userList,
  selectUsers,
  selectUserChange,
  isEdit,
  user,
}) => {
  return (
    <div className="user-list-container">
      <div className="user-list-title">Invite users:</div>
      {userList?.map(
        (userItem, index) =>
          userItem._id !== user?._id && (
            <li
              className="assign-user-list-item align-center"
              key={index}
              onClick={() => selectUserChange(userItem)}
            >
              <div className="assign-user-list-container">
                <img
                  className="user-avatar-image assign-user-image"
                  alt="img"
                  src={userItem?.photo}
                  title={userItem?.name}
                />
                <div>
                  <div className="assign-user-list-name">{userItem?.name}</div>
                  <div className="assign-user-list-email">
                    {userItem?.email}
                  </div>
                </div>
              </div>
              <label>
                <input
                  type="checkbox"
                  name={userItem?.name}
                  value={userItem._id}
                  checked={selectUsers.includes(userItem._id) ? true : false}
                  onChange={() => {
                    return;
                  }}
                />
              </label>
            </li>
          )
      )}
    </div>
  );
};

export default UserList;
