import React, { useState, useRef } from 'react';
import { FaTimes, FaPen } from 'react-icons/fa';
import { connect } from 'react-redux';

import {
  closeModal,
  updateUserProfileWithImg,
  updateUserProfile,
  setInfoModal,
  setPricingModal,
} from '../../../redux';

import './userProfile.css';

const UserProfile = ({
  closeModal,
  user,
  updateProfileImg,
  updateProfile,
  loading,
  board,
  showInfo,
  showPricingModal,
}) => {
  const prevNames = [user.firstName, user.lastName];
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const profileImageRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    profileImageRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files[0];

    if (!fileObj) {
      return;
    }

    const formData = new FormData();
    formData.append('profile', fileObj);

    updateProfileImg(formData, user.token, board.id);

    event.target.value = null;
  };

  const save = () => {
    if (prevNames[0] !== firstName || prevNames[1] !== lastName) {
      updateProfile(
        { firstName: firstName, lastName: lastName },
        user.token,
        board.id
      );
    }
  };

  return (
    <div className="modal-user-profile">
      <FaTimes onClick={closeModal} className="close-icon" />
      <h2 className="modal-title">User Profile</h2>
      <div className="user-profile-modal-container">
        <div className="profile-image-container">
          <img className="profile-img" src={user.photo} alt="profile img" />
          <input
            style={{ display: 'none' }}
            ref={profileImageRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
          <FaPen onClick={handleClick} className="edit-user-profile-icon" />
        </div>
        <div className="form-holder">
          <div className="form-group">
            <label className="user-profile-input-label">First Name</label>
            <input
              className="user-profile-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
            />
          </div>
          <div className="form-group">
            <label className="user-profile-input-label">Last Name</label>
            <input
              className="user-profile-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
            />
          </div>
        </div>
        <div className="form-holder">
          <div className="form-group">
            <label className="user-profile-input-label">Email</label>
            <input
              className="user-profile-input"
              type="text"
              value={user.email}
              readOnly={true}
            />
          </div>
          <div className="form-group">
            <label className="user-profile-input-label">Timezone</label>
            <input
              className="user-profile-input"
              type="text"
              value={Intl.DateTimeFormat().resolvedOptions().timeZone}
              readOnly={true}
            />
          </div>
        </div>
        {user?.plan?.title === 'Basic' && (
          <div className="form-group">
            <label className="user-profile-input-label">Subscription</label>
            <input
              className="user-profile-input"
              type="text"
              value={
                user?.plan?.title === 'Basic' ? 'Trial Account' : 'Paying User'
              }
              readOnly={true}
            />
          </div>
        )}

        <div className="user-dashboard-footer">
          <button onClick={save} className="user-profile-save-button">
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    loading: state.user.loading,
    board: state.board.selectBoard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    updateProfileImg: (profileData, token, boardId) =>
      dispatch(updateUserProfileWithImg(profileData, token, boardId)),
    updateProfile: (profileData, token, boardId) =>
      dispatch(updateUserProfile(profileData, token, boardId)),
    showInfo: (msg) => dispatch(setInfoModal(msg)),
    showPricingModal: () => dispatch(setPricingModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
