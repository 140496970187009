import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import { unsubscribeUser } from "../../redux";

import "./emailSubscriptionPage.css";

const UnsubscribePage = ({ loading, handleUnsubscribeUser }) => {
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      handleUnsubscribeUser(userId);
    }
  }, [handleUnsubscribeUser, userId]);

  return (
    <div className="sub-container">
      <h4 className="text-default">We'll miss you</h4>
      {loading ? (
        <p className="text-default">Hang opting you out...</p>
      ) : (
        <>
          <p className="text-default">
            Emailing notifications has been turned off
          </p>
          <p className="text-default">
            To subscribe click <Link to={`/subscribe/${userId}`}>here</Link>
          </p>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.emailSubscription.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleUnsubscribeUser: (userId) => dispatch(unsubscribeUser(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribePage);
