import React from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { connect } from 'react-redux';

import { handleDeletePin, togglePinDropdown } from '../../redux';

import { DeleteIcon } from '../../assets/svg/iconlibrary';

import './pinItem.css';

const PinItem = ({ pin, user, board, deletePin, showPins }) => {
  return (
    <div className="link-container">
      <a href={pin.url} target="_blank" rel="noopener noreferrer">
        <span className="pin-title">{pin.title}</span>
      </a>
      <div className="pin-icons-container">
        <a href={pin.url} target="_blank" rel="noopener noreferrer">
          <HiOutlineExternalLink className="pin-external-link" />
        </a>
        {(user._id === board.owner._id || pin.user._id === user._id) && (
          <div
            className="delete-pin-button"
            onClick={() => {
              deletePin(user.token, board.id, pin._id);
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    board: state.board.selectBoard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deletePin: (token, boardId, pinId) =>
      dispatch(handleDeletePin(token, boardId, pinId)),
    showPins: () => dispatch(togglePinDropdown()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinItem);
