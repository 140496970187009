import { SET_SELECT_PLAN } from "./subscriptionTypes";

const initialState = {
  loading: false,
  selectPlan: {}
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SELECT_PLAN:
      return {
        ...state,
        selectPlan: action.payLoad
      }

    default:
      return state;
  }
};

export default subscriptionReducer;
