import {
  CLEAR_CHAT_STATE,
  CLEAR_USER_CHAT_DATA,
  CLOSE_CHAT_MODAL,
  OPEN_CHAT_MODAL,
  SET_ACTIVE_CHANNEL,
  SET_USER_CHAT_DATA,
  SET_IS_NEW_MSG,
} from "./chatTypes";

const initialState = {
  chatModalOpen: false,
  activeChannel: "",
  userChatData: {},
  isNewMsg: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CHAT_DATA:
      return {
        ...state,
        userChatData: action.payLoad,
      };

    case SET_IS_NEW_MSG: {
      return {
        ...state,
        isNewMsg: action.payLoad,
      };
    }

    case CLEAR_USER_CHAT_DATA:
      return {
        ...state,
        userChatData: {},
      };

    case OPEN_CHAT_MODAL:
      return {
        ...state,
        chatModalOpen: true,
      };

    case CLOSE_CHAT_MODAL:
      return {
        ...state,
        chatModalOpen: false,
        activeChannel: "",
      };
    case SET_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannel: action.payLoad,
      };

    case CLEAR_CHAT_STATE:
      return {
        ...state,
        chatModalOpen: false,
        activeChannel: "",
        userChatData: {},
        isNewMsg: false,
      };

    default:
      return state;
  }
};

export default chatReducer;
