import React from "react";

export const InviteIcon = () => (
  <div style={{ marginLeft: "2px" }}>
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM21.1812 9.31875L12.4875 20.475C12.4187 20.5438 12.3062 20.6938 12.1687 20.6938C12.025 20.6938 11.9313 20.5938 11.85 20.5125C11.7688 20.4313 6.91875 15.7687 6.91875 15.7687L6.825 15.675C6.7875 15.6187 6.75625 15.55 6.75625 15.475C6.75625 15.4 6.7875 15.3312 6.825 15.275C6.85 15.25 6.86875 15.2313 6.89375 15.2C7.375 14.6938 8.35 13.6687 8.4125 13.6062C8.49375 13.525 8.5625 13.4187 8.7125 13.4187C8.86875 13.4187 8.96875 13.55 9.04375 13.625C9.11875 13.7 11.8562 16.3312 11.8562 16.3312L18.8125 7.39375C18.875 7.34375 18.95 7.30625 19.0312 7.30625C19.1125 7.30625 19.1875 7.3375 19.25 7.3875L21.1625 8.89375C21.2125 8.95625 21.2437 9.03125 21.2437 9.1125C21.25 9.19375 21.2188 9.2625 21.1812 9.31875Z"
        fill="#1D9D67"
      />
    </svg>
  </div>
);
