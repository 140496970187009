export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const CLEAR_EVENTS = "CLEAR_EVENTS";

export const REFRESH_EVENT_LIST = "REFRESH_EVENT_LIST";

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const ADD_NEW_EVENT_SERVER_REQUEST = "ADD_NEW_EVENT_SERVER_REQUEST";
export const ADD_NEW_EVENT_SERVER_SUCCESS = "ADD_NEW_EVENT_SERVER_SUCCESS";
export const ADD_NEW_EVENT_SERVER_FAILURE = "ADD_NEW_EVENT_SERVER_FAILURE";

export const EDIT_EVENT_SERVER_REQUEST = "EDIT_EVENT_SERVER_REQUEST";
export const EDIT_EVENT_SERVER_SUCCESS = "EDIT_EVENT_SERVER_SUCCESS";
export const EDIT_EVENT_SERVER_FAILURE = "EDIT_EVENT_SERVER_FAILURE";

export const DELETE_EVENT_SERVER_REQUEST = "DELETE_EVENT_SERVER_REQUEST";
export const DELETE_EVENT_SERVER_SUCCESS = "DELETE_EVENT_SERVER_SUCCESS";
export const DELETE_EVENT_SERVER_FAILURE = "DELETE_EVENT_SERVER_FAILURE";
