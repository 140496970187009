import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import './cardModalSaveButton.css';

const CopyLinkButton = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (isCopied) return;

    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch(() => {
        console.log('could not copy text');
      });
  };

  return (
    <button
      type="button"
      className="drop-down-button-date-picker"
      onClick={handleClick}
    >
      {isCopied ? (
        <>
          Copied <FaCheck />
        </>
      ) : (
        'Copy Card Link'
      )}
    </button>
  );
};

export default CopyLinkButton;
