import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { CiCircleRemove } from 'react-icons/ci';

function BasicCard() {
  return (
    <div className="pricing-card">
      <div className="pricing-plan-name">Basic</div>
      <div className="pricing-cost">Free</div>
      <div className="pricing-features-holder">
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">2 Boards</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">1 Chat Channel</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">2 File Uploads/board</div>
        </div>
        <div className="pricing-feature">
          <CiCircleRemove className="x-red" />
          <div className="pricing-feature-name">No Video Conferencing</div>
        </div>
      </div>
      <div className="greeting-buttons-container"></div>
    </div>
  );
}

export default BasicCard;
