export const GET_USER_TIMEOUT_STATUS_REQUEST =
  'GET_USER_TIMEOUT_STATUS_REQUEST';
export const GET_USER_TIMEOUT_STATUS_SUCCESS =
  'GET_USER_TIMEOUT_STATUS_SUCCESS';
export const GET_USER_TIMEOUT_STATUS_ERROR = 'GET_USER_TIMEOUT_STATUS_ERROR';

export const CREATE_TIMEOUT_REQUEST = 'CREATE_TIMEOUT_REQUEST';
export const CREATE_TIMEOUT_SUCCESS = 'CREATE_TIMEOUT_SUCCESS';
export const CREATE_TIMEOUT_FAILURE = 'CREATE_TIMEOUT_FAILURE';

export const RESET_TIMEOUT = 'RESET_TIMEOUT';
