import { REQUEST, SUCCESS, FAILURE } from "./subscriptionTypes";

const initialState = {
  loading: false,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
