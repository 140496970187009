import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { StreamChat } from 'stream-chat';

import HomePage from './pages/HomePage/homepage';
import BoardsPage from './pages/BoardsPage/BoardsPage';
import ActivityPage from './pages/ActivityPage/ActivityPage';
import ConferenceModal from './components/modal/ConferenceModal/ConferenceModal';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import SignUp from './components/signup/SignUp';
import SignIn from './components/signin/SignIn';
import VerifyEmailPage from './pages/VerifyEmailPage/VerifyEmailPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import RedirectToMainPage from './components/auth/RedirectToMainPage';
import RedirectToLoginPage from './components/auth/RedirectToLoginPage';
import EmailSubscribePage from './pages/EmailSubscriptionPage/EmailSubscribePage';
import EmailUnsubscribePage from './pages/EmailSubscriptionPage/EmailUnsubscribePage';
import Modal from './components/modal/Modal';
import ChatModal from './components/modal/ChatModal/ChatModal';
import SideNavRight from './components/sidenav-right/SideNavRight';

import AdminUsersPage from './pages/Admin/UsersPage';
import AdminBoardsPage from './pages/Admin/BoardsPage';

import { setUserChatData } from './redux';

import { CONFERENCE_MODAL } from './redux/modal/modalTypes';

import './App.css';
import 'stream-chat-react/dist/css/index.css';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPage from './pages/PrivacyPage/PrivacyPage';

const client = StreamChat.getInstance(
  process.env.REACT_APP_STREAM_API_KEY,
  process.env.REACT_APP_STREAM_API_SECRET
);

const App = ({
  modalType,
  showModal,
  isChatModalOpen,
  rightSideNav,
  setChatData,
  user,
}) => {
  const handleConnectUser = async () => {
    const response = await client.connectUser(
      {
        id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        image: user.photo,
        email: user.email,
      },
      user.chatToken
    );

    setChatData(response.me);
  };

  if (!client.user && user?._id) {
    handleConnectUser();
  }

  return (
    <div className="App">
      {modalType === CONFERENCE_MODAL && <ConferenceModal />}
      {showModal && <Modal />}
      {isChatModalOpen && client && <ChatModal client={client} />}
      {rightSideNav && <SideNavRight />}
      <Switch>
        <RedirectToMainPage exact path="/" component={HomePage} />
        <RedirectToMainPage exact path="/signup" component={SignUp} />
        <RedirectToMainPage exact path="/signin" component={SignIn} />
        <RedirectToLoginPage
          exact
          path="/verify-user-email"
          component={VerifyEmailPage}
        />
        <RedirectToMainPage
          exact
          path="/forgot-password"
          component={ForgotPasswordPage}
        />
        <RedirectToMainPage
          exact
          path="/reset-password"
          component={ResetPasswordPage}
        />
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/privacy" component={PrivacyPage} />

        <PrivateRoute
          exact
          path="/board/:boardId/:cardId?"
          component={ActivityPage}
        />
        <PrivateRoute exact path="/boards" component={BoardsPage} />
        <Route exact path="/subscribe/:userId" component={EmailSubscribePage} />
        <Route
          exact
          path="/unsubscribe/:userId"
          component={EmailUnsubscribePage}
        />

        <AdminRoute exact path="/admin/users" component={AdminUsersPage} />
        <AdminRoute
          exact
          path="/admin/boards-page"
          component={AdminBoardsPage}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    modalType: state.modal.conference,
    showModal: state.modal.showModal,
    isChatModalOpen: state.chat.chatModalOpen,
    rightSideNav: state.sideNavRight.rightSideNav,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatData: (chatData) => dispatch(setUserChatData(chatData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
