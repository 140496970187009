import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";

import { closeModal, editColumnServer } from "../../../redux";

const EditColumnModal = ({
  closeModal,
  user,
  boardId,
  teamId,
  currentColumnId,
  currentColumnTitle,
  updateColumnServer,
  isLoading,
}) => {
  const [columnEditTitle, setColumnEditTitle] = useState(currentColumnTitle);

  const handleSubmit = () => {
    updateColumnServer(
      user.token,
      { boardId, teamId },
      currentColumnId,
      {
        title: columnEditTitle,
      },
      "edit-column"
    );

    setColumnEditTitle("");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="modal-board"
    >
      <div className="close-icon-container">
        <FaTimes onClick={closeModal} className="close-icon" />
        <div className="modal-body">
          <h2 className="modal-title">Edit Column</h2>
          <input
            className="modal-board-text"
            type="text"
            placeholder="Edit Column"
            value={columnEditTitle}
            onChange={(e) => setColumnEditTitle(e.target.value)}
          />
          <button className="modal-board-button" type="submit">
            {isLoading ? "Saving" : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    boardId: state.board.selectBoard.id,
    currentColumnId: state.column.selectColumn.id,
    currentColumnTitle: state.column.selectColumn.title,
    isLoading: state.column.loading,
    teamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    updateColumnServer: (token, params, columnId, columnData, flag) =>
      dispatch(editColumnServer(token, params, columnId, columnData, flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditColumnModal);
