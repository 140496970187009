import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { KevvlarLogo } from "../../assets/svg/iconlibrary";

import { KevvlarIcon, LoadingIcon } from "../../assets/svg/iconlibrary";

import { signUserUp, clearErrorMessage } from "../../redux";
import "./signup.css";

const SignUp = ({ signUpUser, history, loading, error, clearError }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [entry, setEntry] = useState("");

  useEffect(() => {
    setEntry(history.location?.state?.entry);
  }, [history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    signUpUser(
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
      },
      history,
      entry
    );
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
  };

  return (
    <div className="signup-container">
      <div className="homepage-nav">
        <div className="homepage-logo">
          <Link to="/" className="greeting-buttons-container">
            <div className="homepage-nav-btns"><KevvlarLogo /></div>
          </Link>
        </div>
        <div className="homepage-nav-holder">
          <Link to="/signin" className="greeting-buttons-container">
            <div className="homepage-nav-btns">Login</div>
          </Link>
        </div>
      </div>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="signup-content">
          <KevvlarIcon />
          <h2 className="signup-white">Sign Up</h2>
          <div className="sign-in-error">{error}</div>
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="signup-form-group">
              <input
                type="text"
                className="signup-form-input"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="text"
                className="signup-form-input"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="email"
                className="signup-form-input"
                name="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="password"
                className="signup-form-input"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="signup-form-group">
              <input
                type="password"
                className="signup-form-input"
                name="passwordConfirm"
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
              />
            </div>
            <div className="termsholder">
              <div className="termscheckbox">
                <input
                  type="checkbox"
                  name="termsPrivacyConfirm"
                  className="termsprivacycheckbox"
                  required
                />
                <p>Checking this box indicates you agree to the <a href="https://kevvlar.com/terms" target="_blank">Terms of Use</a> and <a href="https://kevvlar.com/privacy" target="_blank">Privacy Policy</a></p>
              </div>
            </div>

            <div className="signup-form-group">
              <button type="submit" className="signup-form-submit">
                Sign Up
              </button>
            </div>
          </form>
          <p className="loginhere">
            Already have an account?{" "}
            <Link
              onClick={() => {
                clearError();
              }}
              to="/signin"
              className="signup-form-login-link"
            >
              Log in here
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUpUser: (userData, history, entry) =>
      dispatch(signUserUp(userData, history, entry)),
    clearError: () => dispatch(clearErrorMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
