import React from 'react';

const TableHeader = () => {
  return (
    <div className="admin-users-table-head">
      <div className="table-data-cell table-title">First Name</div>
      <div className="table-data-cell table-title">Last Name</div>
      <div className="table-data-cell table-title">Email</div>
      <div className="table-data-cell table-title">Plan</div>
      <div className="table-data-cell table-title">Verified</div>
      <div className="table-data-cell table-title">Website Entry</div>
      <div className="table-data-cell table-title">Status</div>
      <div className="table-data-cell table-title">Account Created At</div>
      <div className="table-data-cell table-title">Last Active</div>
      <div className="table-data-cell table-title">Actions</div>
    </div>
  );
};

export default TableHeader;
