import {
  OPEN_CHAT_MODAL,
  CLOSE_CHAT_MODAL,
  CLEAR_CHAT_STATE,
  SET_ACTIVE_CHANNEL,
  SET_USER_CHAT_DATA,
  CLEAR_USER_CHAT_DATA,
  SET_IS_NEW_MSG,
} from "./chatTypes";

export const setUserChatData = (chatData) => {
  return {
    type: SET_USER_CHAT_DATA,
    payLoad: chatData,
  };
};

export const setIsNewMsg = (bool) => {
  return {
    type: SET_IS_NEW_MSG,
    payLoad: bool,
  };
};

export const clearUserChatData = () => {
  return {
    type: CLEAR_USER_CHAT_DATA,
  };
};

export const openChatModal = () => {
  return {
    type: OPEN_CHAT_MODAL,
  };
};

export const closeChatModal = () => {
  return {
    type: CLOSE_CHAT_MODAL,
  };
};

export const clearChatState = () => {
  return {
    type: CLEAR_CHAT_STATE,
  };
};

export const setActiveChannel = (channel) => {
  return {
    type: SET_ACTIVE_CHANNEL,
    payLoad: channel,
  };
};
