import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "./subscriptionTypes";

import { setErrorModal, setInfoModal } from "../index";

import SERVER_URL from "../URL";

export const request = () => {
  return {
    type: REQUEST,
  };
};

export const success = () => {
  return {
    type: SUCCESS,
  };
};

export const fail = () => {
  return {
    type: FAILURE,
  };
};

export const subscribeUser = (userId) => {
  return (dispatch) => {
    dispatch(request());
    axios
      .patch(
        `${SERVER_URL}/api/v1/users/subscribe/${userId}`,
        {},
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(success());
        dispatch(setInfoModal("Successfully subscribed"));
      })
      .catch((error) => {
        dispatch(fail());
        dispatch(setErrorModal("Counld not subscribe user"));
      });
  };
};

export const unsubscribeUser = (userId) => {
  return (dispatch) => {
    dispatch(request());
    axios
      .patch(
        `${SERVER_URL}/api/v1/users/unsubscribe/${userId}`,
        {},
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(success());
        dispatch(setInfoModal("Successfully Unsubscribed"));
      })
      .catch((error) => {
        dispatch(fail());
        dispatch(setErrorModal("Could unsubscribe user"));
      });
  };
};
