import React from "react";

import CalendarPage from "../../../pages/CalendarPage/CalendarPage";

import "./calendarModal.css";

const CalendarModal = () => {
  return (
    <div className="calendar-modal-container">
      <CalendarPage />
    </div>
  );
};

export default CalendarModal;
