import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

import "./activityModal.css";

import { toggleActivity, fetchActivities, fetchCard } from "../../redux";

const ActivityModal = ({
  user,
  board,
  activities,
  showActivity,
  getActivities,
  history,
  getCard,
  editCardModal,
  selectTeamId,
}) => {
  useEffect(() => {
    getActivities(user.token, { boardId: board.id, teamId: selectTeamId });
  }, [user.token, board.id, getActivities, selectTeamId]);

  const showCard = (card) => {
    if (card) {
      const { id, boardId } = card;

      getCard(user.token, { boardId, teamId: null }, id);
      showActivity();
      history.push(`/board/${boardId}/${id}`);
    }
  };

  return (
    <div>
      <div
        className="activity-close-wrapper"
        onClick={() => {
          showActivity();
        }}
      ></div>
      <div className="activity-profile-menu">
        <div className="activity-main-title">Activity</div>
        <div className="activity-item-wrapper">
          {activities?.map((activity) => (
            <div className="activity-item-holder" key={activity._id}>
              <div className="activity-info-holder">
                <div className="activity-first-line-holder">
                  <div className="activity-user-name">
                    {activity.user.firstName}&nbsp;
                  </div>
                  <div className="activity-helper-text">
                    {activity.message}&nbsp;
                  </div>
                </div>

                {activity.card !== null ? (
                  <div
                    className="activity-card-title"
                    onClick={() => {
                      if (activity.card !== null) showCard(activity.card);
                    }}
                  >
                    {activity?.card?.title}
                    &nbsp;
                  </div>
                ) : (
                  <div className="activity-card-title-delete">
                    {activity.cardTitle}
                  </div>
                )}
              </div>
              <div className="task-item-board">
                {moment(activity.timeCreated).format("DD MMM, YYYY")} at{" "}
                {moment(activity.timeCreated).format("h:mm a")}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.activity.activities,
    user: state.user.userData,
    board: state.board.selectBoard,
    selectTeamId: state.team.selectTeam._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showActivity: () => dispatch(toggleActivity()),
    getActivities: (token, params) => dispatch(fetchActivities(token, params)),
    getCard: (token, params, cardId) =>
      dispatch(fetchCard(token, params, cardId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityModal));
