import React from "react";

import "./cardTitle.css";

const CardTitle = ({ setCardTitle, setTitleLength, cardTitle }) => {
  return (
    <input
      type="text"
      id="input-big"
      tabIndex={1}
      maxLength={75}
      placeholder="Card title"
      className="modal-body-title"
      required
      value={cardTitle}
      onChange={(e) => {
        setCardTitle(e?.target?.value);
        setTitleLength(e.target.value.length);
      }}
      autoFocus
    />
  );
};

export default CardTitle;
