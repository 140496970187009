import React from 'react';

import { AiFillCheckCircle } from 'react-icons/ai';
import { CiCircleRemove } from 'react-icons/ci';

const returnEndDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

const TeamCard = ({
  showCancelSubscriptionModal,
  createPaymentIntent,
  loadingObj,
  user,
}) => {
  return (
    <div
      className={`pricing-card ${
        user.plan.title === 'Team' && 'current-plan-border'
      }`}
    >
      <div className="pricing-plan-name">Teams</div>
      <div className="pricing-cost">$500 / mo.</div>
      <div className="pricing-features-holder">
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">500 Boards</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">Unlimited Chat Channels</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">100 File Uploads/board</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">Video Conferencing</div>
        </div>
        <div className="pricing-feature">
          <AiFillCheckCircle className="checkmark-green" />
          <div className="pricing-feature-name">Create Teams (25 users)</div>
        </div>
      </div>
      <div className="greeting-buttons-container">
        {user.plan.title === 'Basic' && (
          <button
            className="modal-board-button free-trial"
            disabled={loadingObj.loading}
            onClick={() => {
              createPaymentIntent(
                500,
                'price_1NpjB9BDlwLcpDtHAO1Q0Bfz',
                'KEVVLAR_TEAM',
                'Team'
              );
            }}
          >
            {loadingObj.title === 'Team' && loadingObj.loading
              ? 'Setting Up...'
              : 'Subscribe'}
          </button>
        )}

        {user.plan.title === 'Premium' && (
          <button
            className="modal-board-button free-trial"
            disabled={loadingObj.loading}
            onClick={() => {
              createPaymentIntent(
                500,
                'price_1NpjB9BDlwLcpDtHAO1Q0Bfz',
                'KEVVLAR_TEAM',
                'Team'
              );
            }}
          >
            {loadingObj.title === 'Team' && loadingObj.loading
              ? 'Setting Up...'
              : 'Upgrade'}
          </button>
        )}

        {user.plan.title === 'Team' && (
          <div className="current-plan">Your Current Plan</div>
        )}

        {user.plan.title === 'Team' && user.stripeCancelAt !== null && (
          <div className="sub-ends-text">
            Ends: {returnEndDate(user.stripeCancelAt)}
          </div>
        )}

        {user.plan.title === 'Team' && user.stripeCancelAt === null && (
          <div
            className="cancel-sub-text"
            onClick={() => {
              showCancelSubscriptionModal();
            }}
          >
            Cancel Subscription
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamCard;
