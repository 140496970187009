import React, { useState } from 'react';

const toolBackgroundImages = {
    Trello: 'url(https://seeklogo.com/images/T/trello-logo-CE7B690E34-seeklogo.com.png)', // Replace with your image URL
    Notion: 'url(https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png)', // Replace with your image URL
    ClickUp: 'url(https://play-lh.googleusercontent.com/9U1Zbu7jlryfcgwvWHulHzjlgMVjOy_S4rIATrF0b6Qd_VR_5yftp0dQBfi-Q5Psjg=w480-h960-rw)', // Replace with your image URL
    Asana: 'url(https://assets.materialup.com/uploads/e70f602b-912c-4c4c-b498-9038227eb063/0x0ss-85.jpg)', // Replace with your image URL
    Jira: 'url(https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png)', // Replace with your image URL
    Linear: 'url(https://asset.brandfetch.io/iduDa181eM/idYYbqOlKi.png)', // Replace with your image URL
    Zoom: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR3Q_T1v68pQjHItNtgsDESwTpqyNWjtzZ-wH1aYDFmLhZI5CfebVxlKdC6oLE-QnLmRU&usqp=CAUg)', // Replace with your image URL
    Slack: 'url(https://static-00.iconduck.com/assets.00/slack-icon-512x511-udpsz3x6.png)', // Replace with your image URL
  };

const SavingsCalculator = () => {
  const [selectedTools, setSelectedTools] = useState([]);
  const kevlarCostPerYear = 6000;

  // Define the cost values for each tool
  const toolCosts = {
    Trello: 10,
    Notion: 15,
    ClickUp: 12,
    Asana: 10.99,
    Jira: 15.25,
    Linear: 8,
    Zoom: 149 / 12, // Monthly cost converted to yearly
    Slack: 7.25,
  };

  const teamSize = '25';

  const handleToolSelection = (tool) => {
    if (selectedTools.includes(tool)) {
      setSelectedTools(selectedTools.filter((selectedTool) => selectedTool !== tool));
    } else {
      setSelectedTools([...selectedTools, tool]);
    }
  };

  const calculateCurrentSpend = () => {
    const selectedToolCosts = selectedTools.map((tool) => toolCosts[tool]);
    const maxUserCount = getMaxUserCount(teamSize);
    const yearlySpend = selectedToolCosts.reduce((acc, cost) => acc + cost * maxUserCount * 12, 0);
    return yearlySpend.toFixed(2);
  };

  const calculateSavings = () => {
    const yearlySpend = parseFloat(calculateCurrentSpend());
    const savings = yearlySpend - kevlarCostPerYear;
    return savings >= 0 ? savings.toFixed(2) : '0.00';
  };

  const getMaxUserCount = (size) => {
    switch (size) {
      case '0-5':
        return 5;
      case '6-15':
        return 15;
      case '25':
        return 25;
      case '26+':
        return 26; // Fixed at 26+
      default:
        return 0;
    }
  };

  const savingsResultClass = calculateSavings() > 0 ? 'savings-positive' : '';

  return (
    <div className="calc-section">
      <div className="showcase-title">Savings Calculator</div>
      <div className="calc-holder">
        <div className="input-section">
          <div className="input-title">Select Tools You Currently Use</div>
          <div className="tool-holder">
            {Object.keys(toolCosts).map((tool) => (
              <div
                key={tool}
                className={`tool-selection ${selectedTools.includes(tool) ? 'selected' : ''}`}
                style={{
                    backgroundImage: toolBackgroundImages[tool] || 'none',
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                  }}
                onClick={() => handleToolSelection(tool)}
              >
              </div>
            ))}
          </div>
          <div className="input-title">Team Size</div>
          <div className={`team-selection`} onClick={() => {}}>
            {teamSize} users
          </div>
        </div>
        <div className="results-section">
            <div className='results-card'>
                <div className="input-title">How Much You Currently Spend</div>
                <div className="results">${calculateCurrentSpend()}/year</div>
            </div>
            <div className='results-card'>
                <div className="input-title">How Much You Could Save</div>
                <div className={`results ${savingsResultClass}`}>${calculateSavings()}/year</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsCalculator;
