import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';

import TeamUserItemView from '../../UserList/TeamUserItemView';
import TeamSearchBar from '../../SearchBar/TeamSearchBar';

import {
  closeTeamSettingsModal,
  setSelectTeam,
  setTeamUserModal,
  fetchTeam,
  setTeamModal,
} from '../../../redux';

import { DELETE, EDIT } from '../../../redux/modal/modalTypes';

import './teamSettings.css';

const TeamSettings = ({
  closeTeamSettings,
  setCurrentTeam,
  showAddUserModal,
  team,
  getTeam,
  user,
  showTeamModal,
}) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getTeam(user.token, team._id, { teamId: team._id });
    if (team) {
      setUsers([...team?.admins, ...team?.members]);
    }
  }, []);

  return (
    <div className="team-settings-wrapper">
      <div className="team-settings-overlay-close" />
      <div className="team-settings-container">
        <FaTimes
          onClick={() => {
            setCurrentTeam({});
            closeTeamSettings();
          }}
          className="close-icon"
        />

        <h2 className="team-settings-title">Manage Teams</h2>
        <div className="team-settings-content-section">
          <div className="search-section">
            <TeamSearchBar setSearchKeyword={setSearchKeyword} />
            <button
              className="modal-board-button"
              onClick={() => {
                closeTeamSettings();
                showAddUserModal();
              }}
            >
              Add User
            </button>
          </div>
          <div className="users-section">
            {users
              .filter(
                (user) =>
                  user.firstName.toLowerCase().includes(searchKeyword) ||
                  user.lastName.toLowerCase().includes(searchKeyword) ||
                  user.email.toLowerCase().includes(searchKeyword)
              )
              .map((user, i) => (
                <TeamUserItemView key={i} userItem={user} />
              ))}
          </div>
          <div className="team-settings-footer-section">
            <button
              className="modal-board-button"
              onClick={() => {
                closeTeamSettings();
                showTeamModal(EDIT);
              }}
            >
              Edit Team
            </button>
            <button
              className="delete-button"
              onClick={() => {
                closeTeamSettings();
                showTeamModal(DELETE);
              }}
            >
              Delete Team
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    team: state.team.selectTeam,
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeTeamSettings: () => dispatch(closeTeamSettingsModal()),
    setCurrentTeam: (team) => dispatch(setSelectTeam(team)),
    showAddUserModal: (actionType) => dispatch(setTeamUserModal(actionType)),
    getTeam: (token, teamId, params) =>
      dispatch(fetchTeam(token, teamId, params)),
    showTeamModal: (type) => dispatch(setTeamModal(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings);
