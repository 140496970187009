import React from 'react';
import { connect } from 'react-redux';

import './cardModalSaveButton.css';

const CardModalSaveButton = ({
  titleLength,
  bodyLength,
  isLoading,
  isCardSaveState,
}) => {
  return (
    <button
      className={
        titleLength + bodyLength > 1000000 || !isCardSaveState
          ? 'modal-board-button-disabled'
          : 'modal-board-button'
      }
      type="submit"
      disabled={isLoading || !isCardSaveState}
    >
      {isLoading ? 'Saving' : 'Save'}
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    isCardSaveState: state.modal.isSaveBtn,
  };
};

export default connect(mapStateToProps, null)(CardModalSaveButton);
