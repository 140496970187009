import axios from 'axios';
import moment from 'moment';
import {
  FETCH_COLUMNS_REQUEST,
  FETCH_COLUMNS_SUCCESS,
  FETCH_COLUMNS_FAILURE,
  ADD_NEW_COLUMN_LOCAL,
  ADD_NEW_COLUMN_SERVER_REQUEST,
  ADD_NEW_COLUMN_SERVER_SUCCESS,
  ADD_NEW_COLUMN_SERVER_FAILURE,
  EDIT_COLUMN_LOCAL,
  EDIT_COLUMN_SERVER_REQUEST,
  EDIT_COLUMN_SERVER_SUCCESS,
  EDIT_COLUMN_SERVER_FAILURE,
  REMOVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_SUCCESS,
  REOMVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_FAILURE,
  DELETE_COLUMN_LOCAL,
  DELETE_COLUMN_SERVER_REQUEST,
  DELETE_COLUMN_SERVER_SUCCESS,
  DELETE_COLUMN_SERVER_FAILURE,
  CHANGE_CARD_COLUMN_ID,
  CHANGE_CARD_COLUMN_LOCAL,
  ADD_NEW_CARD_LOCAL,
  ADD_NEW_CARD_SERVER_REQUEST,
  ADD_NEW_CARD_SERVER_SUCCESS,
  ADD_NEW_CARD_SERVER_FAILURE,
  EDIT_CARD_LOCAL,
  EDIT_CARD_SERVER_REQUEST,
  EDIT_CARD_SERVER_SUCCESS,
  EDIT_CARD_SERVER_FAILURE,
  DELETE_CARD_LOCAL,
  DELETE_CARD_SERVER_REQUEST,
  DELETE_CARD_SERVER_SUCCESS,
  DELETE_CARD_SERVER_FAILURE,
  CHANGE_CARDS_ORDER_LOCAL,
  CHANGE_CARDS_ORDER_IO,
  REMOVE_CARD_FROM_SOURCE_COLUMN_LOCAL,
  ENTER_CARD_SEARCH_KEY,
  SET_CURRENT_COLUMN_DATA,
  SET_CURRENT_CARD_DATA,
  CLEAR_COLUMNS,
  REMOVE_CARD_FROM_SOURCE_COLUMN_IO,
  CHANGE_CARD_COLUMN_IO,
  TOGGLE_ASSIGNED_TO_ME,
  RESET_IS_ME,
  CLEAR_CARD_SEARCH_KEY,
  DRAG_CARD_TO_DIFF_COLUMN_REQUEST,
  DRAG_CARD_TO_DIFF_COLUMN_SUCCESS,
  DRAG_CARD_TO_DIFF_COLUMN_FAILURE,
  DRAG_CARD_IN_COLUMN_REQUEST,
  DRAG_CARD_IN_COLUMN_SUCCESS,
  DRAG_CARD_IN_COLUMN_FAILURE,
  REFRESH_COLUMNS_LOCAL,
  ADD_IN_USE_CARD,
  REMOVE_IN_USE_CARD,
  REMOVE_IN_USE_CARD_BY_USER,
} from './columnTypes';

import {
  addColumnToColumnsOrderLocal,
  fetchBoard,
  removeColumnFromColumnsOrderLocal,
  setCardModal,
  setErrorModal,
  clearActivities,
  clearFiles,
  clearPins,
  closeModal,
  createActivity,
  sendNotification,
  turnOffNotify,
} from '../index';

import SERVER_URL from '../URL';

import socket from '../../Socket';
import { handleLogOutUser } from '../user/userActions';

// FETCH COLUMNS
export const fetchColumnsRequest = () => {
  return {
    type: FETCH_COLUMNS_REQUEST,
  };
};

export const fetchColumnsSuccess = (columns) => {
  return {
    type: FETCH_COLUMNS_SUCCESS,
    payLoad: columns,
  };
};

export const fetchColumnsFailure = (error) => {
  return {
    type: FETCH_COLUMNS_FAILURE,
    payLoad: error,
  };
};

export const fetchColumns = (token, params, history) => {
  return (dispatch) => {
    dispatch(fetchColumnsRequest());
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearPins());
    axios
      .get(`${SERVER_URL}/api/v1/columns`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const columns = response.data.data.columns;
        const logoutStatus = response.data.isLogout;
        if (logoutStatus) {
          return dispatch(handleLogOutUser(history));
        }
        dispatch(fetchColumnsSuccess(columns));
      })
      .catch((error) => {
        dispatch(fetchColumnsFailure(error.message));
      });
  };
};

export const getUpdate = (token, params) => {
  return (dispatch) => {
    dispatch(fetchBoard(token, params.boardId, params));
    dispatch(fetchColumns(token, params));
  };
};

// ADD NEW COLUMN
export const addNewColumnLocal = (columnObj) => {
  return {
    type: ADD_NEW_COLUMN_LOCAL,
    payLoad: columnObj,
  };
};

export const handleAddNewColumnLocal = (columnObj) => {
  return (dispatch) => {
    dispatch(addNewColumnLocal(columnObj));
    dispatch(addColumnToColumnsOrderLocal(columnObj.id));
  };
};

export const addNewColumnServerRequest = () => {
  return {
    type: ADD_NEW_COLUMN_SERVER_REQUEST,
  };
};

export const addNewColumnServerSuccess = () => {
  return {
    type: ADD_NEW_COLUMN_SERVER_SUCCESS,
  };
};

export const addNewColumnServerFailure = (error) => {
  return {
    type: ADD_NEW_COLUMN_SERVER_FAILURE,
    payLoad: error,
  };
};

export const createColumnServer = (token, params, columnObj) => {
  return (dispatch) => {
    dispatch(addNewColumnServerRequest());
    axios
      .post(`${SERVER_URL}/api/v1/columns/`, columnObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const column = response.data.data.newColumn;
        dispatch(handleAddNewColumnLocal(column));
        dispatch(addNewColumnServerSuccess());
        dispatch(closeModal());
        socket.emit('create-new-column', columnObj);
      })
      .catch((error) => {
        dispatch(addNewColumnServerFailure(error.message));
      });
  };
};

export const editColumnServerRequest = () => {
  return {
    type: EDIT_COLUMN_SERVER_REQUEST,
  };
};

export const editColumnServerSuccess = () => {
  return {
    type: EDIT_COLUMN_SERVER_SUCCESS,
  };
};

export const editColumnServerFailure = (error) => {
  return {
    type: EDIT_COLUMN_SERVER_FAILURE,
    payLoad: error,
  };
};

export const removeCardFromColumnServerSuccess = () => {
  return {
    type: REMOVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_SUCCESS,
  };
};

export const removeCardFromColumnServerFailure = (error) => {
  return {
    type: REOMVE_CARD_FROM_CARD_FROM_COLUMN_SERVER_FAILURE,
    payLoad: error,
  };
};

export const removeCardFromColumnsOrderServer = (
  token,
  boardId,
  columnId,
  columnObj
) => {
  return (dispatch) => {
    dispatch(removeCardFromColumnServerSuccess());
    axios
      .patch(
        `${SERVER_URL}/api/v1/columns/removecardcolumn/${columnId}`,
        columnObj,
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            boardId,
          },
        }
      )
      .catch((error) => {
        dispatch(removeCardFromColumnServerFailure(error.message));
      });
  };
};

export const editColumnServer = (token, params, columnId, columnData) => {
  return (dispatch) => {
    dispatch(editColumnServerRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/columns/${columnId}`, columnData, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const column = response.data.data.column;
        dispatch(editColumnLocal(column));
        dispatch(editColumnServerSuccess());
        dispatch(closeModal());

        socket.emit('edit-column', column);
      })
      .catch((error) => {
        dispatch(editColumnServerFailure(error.message));
      });
  };
};

export const editColumnLocal = (columnObj) => {
  return {
    type: EDIT_COLUMN_LOCAL,
    payLoad: columnObj,
  };
};

export const deleteColumnServerRequest = () => {
  return {
    type: DELETE_COLUMN_SERVER_REQUEST,
  };
};

export const deleteColumnServerSuccess = () => {
  return {
    type: DELETE_COLUMN_SERVER_SUCCESS,
  };
};

export const deleteColumnServerFailure = (error) => {
  return {
    type: DELETE_COLUMN_SERVER_FAILURE,
    payLoad: error,
  };
};

export const deleteColumnServer = (token, params, columnId) => {
  return (dispatch) => {
    dispatch(deleteColumnServerRequest());
    axios
      .delete(`${SERVER_URL}/api/v1/columns/${columnId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(() => {
        dispatch(deleteColumnServerSuccess());
        dispatch(handleDeleteColumnLocal(columnId));
        dispatch(closeModal());
        socket.emit('delete-column', columnId);
      })
      .catch((error) => {
        dispatch(deleteColumnServerFailure(error.message));
      });
  };
};

export const deleteColumnLocal = (columnId) => {
  return {
    type: DELETE_COLUMN_LOCAL,
    payLoad: columnId,
  };
};

export const handleDeleteColumnLocal = (columnId) => {
  return (dispatch) => {
    dispatch(removeColumnFromColumnsOrderLocal(columnId));
    dispatch(deleteColumnLocal(columnId));
  };
};

export const addNewCardLocal = (cardObj) => {
  return {
    type: ADD_NEW_CARD_LOCAL,
    payLoad: cardObj,
  };
};

export const addNewCardServerRequest = () => {
  return {
    type: ADD_NEW_CARD_SERVER_REQUEST,
  };
};

export const addNewCardServerSuccess = () => {
  return {
    type: ADD_NEW_CARD_SERVER_SUCCESS,
  };
};

export const addNewCardServerFailure = (error) => {
  return {
    type: ADD_NEW_CARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const addNewCardServer = (token, userId, boardId, params, cardObj) => {
  return (dispatch) => {
    dispatch(addNewCardServerRequest());
    axios
      .post(`${SERVER_URL}/api/v1/cards`, cardObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const card = response.data.data.card;
        dispatch(addNewCardLocal(cardObj));
        dispatch(addNewCardServerSuccess());
        dispatch(closeModal());
        dispatch(
          createActivity(token, params, {
            user: userId,
            boardId: boardId,
            teamId: params.teamId ? params.teamId : null,
            columnId: card.columnId,
            cardId: card.id,
            title: 'Create-Card',
            message: `Created card`,
            cardTitle: card.title,
            columnTitle: cardObj.columnTitle,
            timeCreated: moment(),
          })
        );
        if (card.users.length > 0) {
          card.users.forEach((userObj) => {
            dispatch(
              sendNotification(token, params, {
                title: 'Assigned to card',
                message: `Assigned to card`,
                user: userObj._id,
                timeCreated: moment(),
                boardId: boardId,
                teamId: params.teamId ? params.teamId : null,
                columnId: card.columnId,
                cardId: card.id,
              })
            );
            socket.emit('sendNotification', {
              senderId: userId,
              receiverId: userObj._id,
            });

            if (userId === userObj._id) {
              dispatch(turnOffNotify(true));
            }
          });
        }
        socket.emit('add-new-card', card);
      })
      .catch((error) => {
        dispatch(addNewCardServerFailure(error.message));
      });
  };
};

export const editCardLocal = (cardObj) => {
  return {
    type: EDIT_CARD_LOCAL,
    payLoad: cardObj,
  };
};

export const editCardServerRequest = () => {
  return {
    type: EDIT_CARD_SERVER_REQUEST,
  };
};

export const editCardServerSuccess = () => {
  return {
    type: EDIT_CARD_SERVER_SUCCESS,
  };
};

export const editCardServerFailure = (error) => {
  return {
    type: EDIT_CARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const editCardServer = (
  token,
  params,
  cardId,
  cardObj,
  history,
  newUsers = [],
  userId
) => {
  return (dispatch) => {
    dispatch(editCardServerRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/cards/${cardId}`, cardObj, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const card = response.data.data.card;
        dispatch(editCardLocal(card));
        dispatch(editCardServerSuccess());
        dispatch(closeModal());
        dispatch(
          createActivity(token, params, {
            user: userId,
            boardId: card.boardId,
            teamId: params.teamId ? params.teamId : null,
            columnId: card.columnId,
            cardId: card.id,
            title: 'Edit-Card',
            message: `Edited card`,
            cardTitle: card.title,
            columnTitle: cardObj.columnTitle,
            timeCreated: moment(),
          })
        );

        if (newUsers.length > 0) {
          newUsers.forEach((userObj) => {
            dispatch(
              sendNotification(token, params, {
                title: 'Assigned to card',
                message: `Assigned to card`,
                user: userObj._id,
                timeCreated: moment(),
                boardId: card.boardId,
                teamId: params.teamId ? params.teamId : null,
                columnId: card.columnId,
                cardId: card.id,
              })
            );
            socket.emit('sendNotification', {
              senderId: userId,
              receiverId: userObj._id,
            });

            if (userId === userObj._id) {
              dispatch(turnOffNotify(true));
            }
          });
        }

        if (params.teamId) {
          history.push(`/board-team/${params.teamId}/${card.boardId}`);
        } else {
          history.push(`/board/${card.boardId}`);
        }
        socket.emit('edit-card', card);
      })
      .catch((error) => {
        dispatch(editColumnServerFailure(error.message));
      });
  };
};

export const deleteCardLocal = (deleteObj) => {
  return {
    type: DELETE_CARD_LOCAL,
    payLoad: deleteObj,
  };
};

export const deleteCardServerRequest = () => {
  return {
    type: DELETE_CARD_SERVER_REQUEST,
  };
};

export const deleteCardServerSuccess = () => {
  return {
    type: DELETE_CARD_SERVER_SUCCESS,
  };
};

export const deleteCardServerFailure = (error) => {
  return {
    type: DELETE_CARD_SERVER_FAILURE,
    payLoad: error,
  };
};

export const deleteCardServer = (
  token,
  boardId,
  params,
  cardId,
  history,
  deleteObj,
  setDeleting,
  userId
) => {
  return (dispatch) => {
    dispatch(deleteCardServerRequest());
    setDeleting(true);

    axios
      .delete(`${SERVER_URL}/api/v1/cards/${cardId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          columnId: deleteObj.columnId,
        },
        params,
      })
      .then(() => {
        dispatch(deleteCardLocal(deleteObj));
        dispatch(deleteCardServerSuccess());
        setDeleting(false);
        dispatch(closeModal());
        dispatch(
          createActivity(token, params, {
            user: userId,
            boardId: boardId,
            teamId: params.teamId ? params.teamId : null,
            columnId: deleteObj.columnId,
            cardId: deleteObj.cardId,
            title: 'Card-Delete',
            message: 'Deleted card',
            cardTitle: deleteObj.cardTitle,
            columnTitle: deleteObj.columnTitle,
            timeCreated: moment(),
          })
        );

        if (params.teamId) {
          history.push(`/board-team/${params.teamId}/${boardId}`);
        } else {
          history.push(`/board/${boardId}`);
        }
        socket.emit('delete-card', deleteObj);
      })
      .catch((error) => {
        dispatch(editColumnServerFailure(error.message));
      });
  };
};

export const changeCardsOrderLocal = (order) => {
  return {
    type: CHANGE_CARDS_ORDER_LOCAL,
    payLoad: order,
  };
};

export const changeCardsOderIo = (changeObj) => {
  return {
    type: CHANGE_CARDS_ORDER_IO,
    payLoad: changeObj,
  };
};

export const removeCardFromSourceColumnLocal = (sourceColumnId) => {
  return {
    type: REMOVE_CARD_FROM_SOURCE_COLUMN_LOCAL,
    payLoad: sourceColumnId,
  };
};

export const changeCardColumnId = (columnId) => {
  return {
    type: CHANGE_CARD_COLUMN_ID,
    payLoad: columnId,
  };
};

export const changeCardColumnLocal = (changeObj) => {
  return {
    type: CHANGE_CARD_COLUMN_LOCAL,
    payLoad: changeObj,
  };
};

export const handleChangeCardColumnLocal = (sourceColumnId, changeObj) => {
  return (dispatch) => {
    dispatch(changeCardColumnLocal(changeObj));
    dispatch(removeCardFromSourceColumnLocal(sourceColumnId));
    dispatch(changeCardColumnId(changeObj.destinationColumn));
  };
};

export const removeCardFromSourceColumnIO = (removeObj) => {
  return {
    type: REMOVE_CARD_FROM_SOURCE_COLUMN_IO,
    payLoad: removeObj,
  };
};

export const changeCardColumnIO = (changeObj) => {
  return {
    type: CHANGE_CARD_COLUMN_IO,
    payLoad: changeObj,
  };
};

export const handleChangeCardColumnIO = (data) => {
  return (dispatch) => {
    dispatch(
      removeCardFromSourceColumnIO({
        sourceColumnId: data.sourceColumnId,
        cardId: data.cardId,
      })
    );
    dispatch(
      changeCardColumnIO({
        destinationColumnId: data.destinationColumnId,
        card: data.card,
        cardsOrder: data.cardsOrder,
      })
    );
  };
};

export const enterCardSearchKey = (keyWord) => {
  return {
    type: ENTER_CARD_SEARCH_KEY,
    payLoad: keyWord,
  };
};

export const clearCardSearchKey = () => {
  return {
    type: CLEAR_CARD_SEARCH_KEY,
  };
};

export const clearColumns = () => {
  return {
    type: CLEAR_COLUMNS,
  };
};

export const setCurrentColumnData = (columnObj) => {
  return {
    type: SET_CURRENT_COLUMN_DATA,
    payLoad: columnObj,
  };
};

export const setCurrentCardData = (cardObj) => {
  return {
    type: SET_CURRENT_CARD_DATA,
    payLoad: cardObj,
  };
};

export const fetchColumn = (token, params, columnId) => {
  return (dispatch) => {
    dispatch(clearActivities());
    dispatch(clearFiles());
    dispatch(clearPins());
    axios
      .get(`${SERVER_URL}/api/v1/columns/${columnId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const column = response.data.data.column;
        if (!column) {
          dispatch(setErrorModal(`Oops this column no longer exsits.`));
        } else if (column) {
          dispatch(setCurrentColumnData(column));
        }
      })
      .catch((error) => {
        dispatch(fetchColumnsFailure(error.message));
      });
  };
};

export const fetchCard = (token, params, cardId) => {
  return (dispatch) => {
    axios
      .get(`${SERVER_URL}/api/v1/cards/${cardId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response) => {
        const card = response.data.data.card;
        if (!card) {
          dispatch(setErrorModal(`Oops this card no longer exsits.`));
        } else if (card) {
          dispatch(fetchColumn(token, params, card.columnId));
          dispatch(setCurrentCardData(card));
          dispatch(setCardModal('EDIT'));
        }
      })
      .catch((error) => {
        dispatch(fetchColumnsFailure(error.message));
      });
  };
};

export const emitCreateNewColumnIO = (socket, data) => {
  return () => {
    socket.emit('add-new-column', data);
  };
};

export const toggleAssignedMe = () => {
  return {
    type: TOGGLE_ASSIGNED_TO_ME,
  };
};

export const dragCardInColumnRequest = () => {
  return {
    type: DRAG_CARD_IN_COLUMN_REQUEST,
  };
};

export const dragCardInColumnSuccess = () => {
  return {
    type: DRAG_CARD_IN_COLUMN_SUCCESS,
  };
};

export const dragCardInColumnFailure = (error) => {
  return {
    type: DRAG_CARD_IN_COLUMN_FAILURE,
    payLoad: error,
  };
};

export const handleDragCardInColumn = (token, userId, boardId, dragData) => {
  return (dispatch) => {
    dispatch(dragCardInColumnRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/columns/dragincolumn`, dragData, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          boardId,
        },
      })
      .then((response) => {
        const column = response.data.column;
        const columns = response.data.columns;
        // const columns = response.data.columns;
        dispatch(dragCardToDiffColumnSuccess());

        dispatch(
          createActivity(token, boardId, {
            user: userId,
            boardId: boardId,
            columnId: column.id,
            cardId: dragData.cardId,
            title: 'Card-Column',
            message: `Rearranged card within column`,
            timeCreated: moment(),
          })
        );

        socket.emit('change-cards-order', columns);
      })
      .catch((error) => {
        dispatch(dragCardInColumnFailure(error.message));
      });
  };
};

export const dragCardToDiffColumnRequest = () => {
  return {
    type: DRAG_CARD_TO_DIFF_COLUMN_REQUEST,
  };
};

export const dragCardToDiffColumnSuccess = () => {
  return {
    type: DRAG_CARD_TO_DIFF_COLUMN_SUCCESS,
  };
};

export const dragCardToDiffColumnFailure = (error) => {
  return {
    type: DRAG_CARD_TO_DIFF_COLUMN_FAILURE,
    payLoad: error,
  };
};

export const handleDragCardToDiffColumnServer = (
  token,
  boardId,
  dragData,
  userId
) => {
  return (dispatch) => {
    dispatch(dragCardToDiffColumnRequest());
    axios
      .patch(`${SERVER_URL}/api/v1/columns/dragoutcolumn`, dragData, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          boardId,
        },
      })
      .then((response) => {
        // const sourceColumn = response.data.sourceColumn;
        const destinationColumn = response.data.destColumn;
        const card = response.data.card;
        const columns = response.data.columns;
        // const columns = response.data.columns;
        dispatch(dragCardToDiffColumnSuccess());

        dispatch(
          createActivity(token, boardId, {
            user: userId,
            boardId: boardId,
            columnId: destinationColumn.id,
            cardId: card.id,
            title: 'Card-Column-Card',
            message: `Moved card to new column`,
            timeCreated: moment(),
          })
        );
        socket.emit('change-card-column', columns);
      })
      .catch((error) => {
        dispatch(dragCardToDiffColumnFailure(error.message));
      });
  };
};

export const resetIsMe = () => {
  return {
    type: RESET_IS_ME,
  };
};

export const refreshColumnsLocal = (columns) => {
  return {
    type: REFRESH_COLUMNS_LOCAL,
    payLoad: columns,
  };
};

export const addInUseCard = (cardData) => {
  return {
    type: ADD_IN_USE_CARD,
    payLoad: cardData,
  };
};

export const removeInUseCard = (cardData) => {
  return {
    type: REMOVE_IN_USE_CARD,
    payLoad: cardData,
  };
};

export const removeInUseCardByUser = (userData) => {
  return {
    type: REMOVE_IN_USE_CARD_BY_USER,
    payLoad: userData,
  };
};
